import {addIntent, createIntentMap, forEachIntent} from '../intent';
import {$revision, $subview} from './constants';
import {dynamic} from './dynamic';
import {isViewConfigIntent} from './ConfigIntent';
import {getPriority} from './priority';
import type {View} from './types';

export function assignObjectProps<Props>(target: Props, source: Props): Props {
  const keys = Object.keys(source as object) as (keyof Props)[];
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    switch (key) {
      case 'css':
      case 'inherits':
      case 'subviews':
      case 'uses':
        // eslint-disable-next-line no-continue
        continue;
      default:
        target[key] = source[key];
    }
  }
  return target;
}

export function createViewRevision<Props>(
  instance: View.ViewInstance<Props>,
  Component: string | React.ComponentType<Props>,
  props: Props,
  forwardedRef: View.InferRef<Props>,
  isElement: boolean,
): View.ViewRevision<Props> {
  const revision = createIntentMap({
    Component,
    initialProps: props,
    instance,
    props: {},
  }) as View.ViewRevision<Props>;

  (revision.props as {ref?: View.InferRef<Props>}).ref = forwardedRef;

  assignObjectProps(revision.props, props);

  if (!isElement) {
    (revision.props as unknown as {inherits?: View.Inherits}).inherits = {
      [$revision]: revision,
    };
  }

  return revision;
}

function getInherits<Props>(props: Props) {
  return (props as {inherits?: View.Inherits | View.Subview<Props>})?.inherits;
}

export function getViewRevisionFromProps<Props>(
  props: Props,
): View.ViewRevision<Props> | void {
  return (getInherits(props) as View.Inherits)?.[$revision];
}
export function getSubviewIntentsFromProps<Props>(
  props: Props,
): View.SubviewIntents<Props> | void {
  return (getInherits(props) as View.Subview<Props>)?.[$subview];
}

export function addIntentsToRevision<Props>(
  revision: View.ViewRevision<Props>,
  intents: void | (View.Intent<Props> | null | undefined | false)[],
  priority: View.Priority,
  throwWhenPriorityMismatch = false,
): void {
  if (!intents) {
    return;
  }
  forEachIntent(intents, (intent) => {
    const intentPriority = getPriority(intent);
    if (intentPriority !== priority) {
      if (throwWhenPriorityMismatch && intentPriority > priority) {
        if (
          process.env.NODE_ENV !== 'production' ||
          !dynamic.isIntent(intent)
        ) {
          throw new Error(
            'Attempted to run an intent at a lower priority than expected.',
          );
        } else {
          // assignProps is now run at a higher priority, but to avoid breaking
          // in production, we'll run at normal priority for backwards compat.
          // We'll continue to throw in dev mode to highlight paths that need
          // to be updated.
          addIntentsToRevision(revision, intent(revision.props), priority);
          // eslint-disable-next-line no-console
          console.warn('An intent was run at a lower priority than expected.');
        }
      }
      return;
    }

    if (dynamic.isIntent(intent)) {
      addIntentsToRevision(revision, intent(revision.props), priority, true);
    } else if (isViewConfigIntent(revision, intent)) {
      addIntentsToRevision(revision, intent, priority, true);
    } else {
      addIntent(revision, intent);
    }
  });
}
