import * as React from 'react';
import {toIntent, type Intent} from '../intent';

import type {View} from '../view';
import {dynamic, assignProps} from '../view';
import {useIsomorphicLayoutEffect} from '../util';
import {useCurrentTimeline} from './timeline';
import {NodeAnimation} from './animate';

import type {
  KeyframeDescription,
  KeyframeStyles,
  KeyframeOptions,
  AnimationTask,
} from './animate';

const {useRef} = React;

// the `Intent |` union enables the `noopIntent` return.
type AnimationIntent<T extends Element> =
  | Intent
  | View.Intent<{ref?: React.Ref<T>}>;

function isTestEnv(): boolean {
  if (process.env.NODE_ENV === 'test') {
    return true;
  }

  return false;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noopIntent = dynamic(() => {}) as Intent;

export function normalizeKeyframe(keyframe: KeyframeDescription): {
  styles: KeyframeStyles;
  options: KeyframeOptions;
} {
  const {delay, duration, easing, disabled, ...styles} = keyframe;
  return {
    styles: styles as KeyframeStyles,
    options: {delay, duration, easing, disabled},
  };
}

function refAnimation<T extends Element>(
  animationRef: React.MutableRefObject<NodeAnimation>,
  styles: KeyframeStyles,
  options: KeyframeOptions,
) {
  if (isTestEnv()) {
    return noopIntent;
  }

  return assignProps(() => {
    const ref = useRef<T>(null);
    const timeline = useCurrentTimeline();

    useIsomorphicLayoutEffect(() => {
      if (animationRef.current === null) {
        animationRef.current = new NodeAnimation(
          timeline,
          ref,
          styles,
          options,
        );
      }
    }, [timeline]);
    return {ref};
  });
}

function conditionalAnimation<T extends Element>(
  condition: boolean,
  styles: KeyframeStyles,
  options: KeyframeOptions,
) {
  if (isTestEnv()) {
    return noopIntent;
  }

  return assignProps(() => {
    const ref = useRef<T>(null);

    const pushedTask = useRef<AnimationTask | null>(null);
    const timeline = useCurrentTimeline();

    const animationRef = useRef<NodeAnimation | null>(null);

    useIsomorphicLayoutEffect(() => {
      if (animationRef.current === null) {
        animationRef.current = new NodeAnimation(
          timeline,
          ref,
          styles,
          options,
        );
      }

      const animation = animationRef.current;

      if (condition && !options.disabled) {
        if (pushedTask.current == null) {
          pushedTask.current = animation.play({push: true});
        }
      } else if (ref.current && pushedTask.current != null) {
        pushedTask.current.removeAndPlay();
        pushedTask.current = null;
      }
    });
    return {ref: ref as React.Ref<T>};
  });
}

function presenceAnimation<T extends Element>(
  keyframe: KeyframeDescription,
  type: 'in' | 'out',
  reverse?: 'keyframes' | 'effects',
) {
  if (isTestEnv()) {
    return noopIntent;
  }

  const {styles, options} = normalizeKeyframe(keyframe);
  if (reverse) {
    options.reverse = reverse;
  }

  return assignProps(() => {
    const ref = useRef<T>(null);
    const timeline = useCurrentTimeline();
    const {disabled} = options;

    useIsomorphicLayoutEffect(() => {
      let animation: NodeAnimation | null = null;
      if (!disabled) {
        animation = new NodeAnimation(timeline, ref, styles, options);
        return timeline.register(type, animation);
      }
    }, [timeline, disabled]);

    return {ref: ref as React.Ref<T>};
  });
}

export function transition<T extends Element>(
  keyframe: KeyframeDescription,
): {
  when: (condition: boolean) => AnimationIntent<T>;
  ref: (ref: React.MutableRefObject<NodeAnimation>) => AnimationIntent<T>;
} {
  const {styles, options} = normalizeKeyframe(keyframe);

  return {
    when: (condition) => conditionalAnimation<T>(condition, styles, options),
    ref: (ref) => refAnimation<T>(ref, styles, options),
  };
}

// animate.in
export function transitionIn<T extends Element>(
  keyframe: KeyframeDescription,
): AnimationIntent<T> {
  return presenceAnimation<T>(keyframe, 'in', 'keyframes');
}

// animate.out
export function transitionOut<T extends Element>(
  keyframe: KeyframeDescription,
): AnimationIntent<T> {
  return presenceAnimation<T>(keyframe, 'out');
}

export function transitionInOut<T extends Element>(
  keyframe: KeyframeDescription,
): AnimationIntent<T> {
  return toIntent([
    presenceAnimation<T>(keyframe, 'in', 'keyframes'),
    presenceAnimation<T>(keyframe, 'out', 'effects'),
  ]);
}
