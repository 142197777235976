const $condition = Symbol('condition');

export type ConditionalObject<T> = Record<string, T | Record<string, T>>;

export function isConditionalObject<T>(
  value: unknown,
): value is Record<string, T> {
  return typeof value === 'object' && !!value && $condition in value;
}

export function markConditionalObject<T>(
  value: Record<string, T>,
): Record<string, T> {
  (value as Record<string, T> & {[$condition]: boolean})[$condition] = true;
  return value;
}

export function assignConditionalProperty<T>(
  target: ConditionalObject<T>,
  property: string,
  value: T,
  condition: string,
  isConditional: <T>(
    value: unknown,
  ) => value is Record<string, T> = isConditionalObject,
): void {
  if (condition) {
    // The source value is a condition query; merge it with the target value.
    const targetValue = target[property] as T | Record<string, T>;

    if (isConditional(targetValue)) {
      // Preserve key order
      delete targetValue[condition];
      // nosemgrep: prototype-pollution-assignment
      targetValue[condition] = value;
    } else if (targetValue == null) {
      target[property] = {[condition]: value};
      markConditionalObject(target[property] as Record<string, T>);
    } else {
      target[property] = {default: targetValue, [condition]: value};
      markConditionalObject(target[property] as Record<string, T>);
    }
  } else {
    // The source value is a string or undefined; overwrite the target value.
    target[property] = value;
  }
}

function assignWithKeyOrder<T>(
  target: Record<string, T>,
  source: Record<string, T>,
): void {
  const keys = Object.keys(source);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (key in target) {
      delete target[key];
    }
    target[key] = source[key];
  }
}

export function mergeConditionalProperty<T>(
  target: ConditionalObject<T>,
  property: string,
  value: T | Record<string, T>,
  isConditional: <T>(
    value: unknown,
  ) => value is Record<string, T> = isConditionalObject,
): void {
  if (isConditional(value)) {
    // The source value is an object; merge it with the target value.
    const targetValue = target[property];
    if (isConditional(targetValue)) {
      assignWithKeyOrder(targetValue, value);
    } else {
      target[property] = targetValue == null ? {} : {default: targetValue};
      Object.assign(target[property] as Record<string, T>, value);
    }
  } else {
    // The source value is a string or undefined; overwrite the target value.
    target[property] = value;
  }
}
