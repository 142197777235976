import type {Transport} from 'src/internal/transport/types';

export default function fromIframe(
  target: HTMLIFrameElement,
  {targetOrigin = '*'} = {},
): Transport {
  if (typeof window === 'undefined') {
    throw new Error(
      `You can only run fromIframe() in a browser context, but no window was found.`,
    );
  }

  const listenerMap = new WeakMap<
    (event: MessageEvent) => void,
    (event: MessageEvent) => void
  >();

  return {
    postMessage(message, transfer) {
      target.contentWindow?.postMessage(message, targetOrigin, transfer);
    },
    addEventListener(event, listener) {
      const wrappedListener = (event: MessageEvent) => {
        if (event.source !== target.contentWindow) {
          return;
        }
        listener(event);
      };

      listenerMap.set(listener, wrappedListener);
      self.addEventListener(event, wrappedListener);
    },
    removeEventListener(event, listener) {
      const wrappedListener = listenerMap.get(listener);
      if (wrappedListener == null) {
        return;
      }

      listenerMap.delete(listener);
      self.removeEventListener(event, wrappedListener);
    },
  };
}
