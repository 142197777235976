import type {Transport} from 'src/internal/transport/types';

export default function fromInsideIframe({targetOrigin = '*'} = {}): Transport {
  if (typeof self === 'undefined' || self.parent == null) {
    throw new Error(
      `This does not appear to be a child iframe, because there is no parent window.`,
    );
  }

  const {parent} = self;
  const listenerMap = new WeakMap<
    (event: MessageEvent) => void,
    (event: MessageEvent) => void
  >();

  return {
    postMessage(message, transfer) {
      parent.postMessage(message, targetOrigin, transfer);
    },
    addEventListener(event, listener) {
      const wrappedListener = (event: MessageEvent) => {
        if (event.source !== parent) {
          return;
        }
        listener(event);
      };

      listenerMap.set(listener, wrappedListener);
      self.addEventListener(event, wrappedListener);
    },
    removeEventListener(event, listener) {
      const wrappedListener = listenerMap.get(listener);
      if (wrappedListener == null) {
        return;
      }

      listenerMap.delete(listener);
      self.removeEventListener(event, wrappedListener);
    },
  };
}
