import type {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';

const earningsChartParams = ['chart-aspect-ratio'] as const;

type EarningsChartParams = (typeof earningsChartParams)[number];
export type EarningsChartHtmlParams = Partial<
  Record<EarningsChartParams, string>
>;

export const EarningsChartConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "earningsChartConfig" */ '../../../components/Earnings/Earnings'
    ),
  enabledKey: 'internal',
  releaseState: 'internal',
  sentryTeam: 'accounts_ui',
  teamOwner: 'connected-account-experiences-eng',
  htmlAttributes: {
    'chart-aspect-ratio': {
      isInternal: true,
    },
  },
  applyMobileUIOverrides: true,
  description: 'A chart that displays the earnings for the given account.',
};
