import {useRef} from 'react';

// https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
export function useConstant<T>(callback: () => T): T {
  const ref = useRef<[T]>();
  if (!ref.current) {
    // Store the result of the callback in an array to allow the callback to
    // return falsy values including null and undefined.
    ref.current = [callback()];
  }
  return ref.current[0];
}
