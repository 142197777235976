import {dynamic} from './dynamic';
import {omitProps} from './omitProps';
import {isIntent} from '../intent';
import type {Intent} from '../intent';
import type {View} from './types';

type VariantsShape = Record<
  string,
  Record<string, Intent | Intent[]> | ((value: string) => Intent | Intent[])
>;

export function createVariants<Props>(
  variants: View.Variants<Props>,
  forward: {[key: string]: true | undefined} = {},
  createViewOptionsIntent: (
    options: View.ViewOptions<Props>,
  ) => View.Intent<Props>[],
): Intent<Props> {
  function addIntents(
    intents: View.Intent<Props>[],
    value: View.Intent<Props> | View.Intent<Props>[] | View.ViewOptions<Props>,
  ) {
    // TODO: This duplicates logic from `normalizeIntents`
    if (isIntent(value)) {
      intents.push(value);
    } else if (Array.isArray(value)) {
      intents.push(...(value as View.Intent<Props>[]));
    } else {
      intents.push(
        ...createViewOptionsIntent(value as View.ViewOptions<Props>),
      );
    }
  }

  const variantKeys = Object.keys(variants);
  const omitKeys = forward
    ? variantKeys.filter((key) => !forward[key])
    : variantKeys;

  const sharedIntents = [] as View.Intent<Props>[];
  if (omitKeys.length) {
    const omitObj = {} as Record<string, boolean>;
    omitKeys.forEach((key) => {
      omitObj[key] = true;
    });
    sharedIntents.push(omitProps(omitObj));
  }

  return dynamic<Props>((props) => {
    const intents = sharedIntents.slice();

    for (let i = 0; i < variantKeys.length; i++) {
      const key = variantKeys[i];
      const value = props[key as keyof Props] as unknown as string;

      const variantKeyObj = (variants as unknown as VariantsShape)[key];
      const variantIntents =
        typeof variantKeyObj === 'function'
          ? variantKeyObj(value)
          : variantKeyObj[value];
      if (variantIntents != null) {
        addIntents(intents, variantIntents);
      }
    }
    return intents;
  });
}
