import type {PrefillOptions, ArkSectionName} from '@stripe-internal/ark';
import type {OnboardingStep} from '@stripe-internal/ark-lib';
import type {
  CollectBankAccountTokenResult,
  FinancialConnectionsSessionResult,
} from '@stripe/stripe-js';
import {ConnectElementObservableValues} from './ConnectElementObservableValues';
import type {TYPE_TO_FILTER_MESSAGES} from '../components/Transactions/filter/transactionTypes';
import type {AccountManagementSectionType} from '../components/Account/AccountManagement/AccountManagementForm/types';
import type {
  intervalEnd,
  intervalStart,
} from './ConnectJSInterface/ConnectElementDefinitions/reporting-chart';
import type {PaymentMethod, Status} from './utils/paymentFilterTypes';

export interface PaymentsListDefaultFilters {
  amount?:
    | {equals: number}
    | {greaterThan: number}
    | {lessThan: number}
    | {between: {lowerBound: number; upperBound: number}};
  date?: {before: Date} | {after: Date} | {between: {start: Date; end: Date}};
  status?: Array<Status>;
  paymentMethod?: PaymentMethod;
}

export interface TransactionsListDefaultFilters {
  type?: keyof typeof TYPE_TO_FILTER_MESSAGES;
}

// https://docs.google.com/document/d/1KohI_dTXnSYk2jTGj2sa7yaS7BT6bs18Ih_sR2tw3IU/edit#heading=h.z6qtovk62wrs
export interface CollectionOptions {
  fields: 'currently_due' | 'eventually_due';
  futureRequirements?: 'omit' | 'include';
}

export interface MobileBannerOptions {
  minimized?: boolean;
  renderInNav?: boolean;
}

// Note: Supplemental objects should not contain any non-serializable fields (i.e. functions), since these fields get
// serialized to the UI layer directly via JSON.stringify in a window message
export interface IConnectElementSupplementalObject {
  // Internal
  debugUtilObjectInternalDoNotUse?: {
    prop1: string;
    prop2: {
      subprop1: number;
      subprop2: boolean;
    };
    prop3: string[];
  };
  transactionsListDefaultFilters?: TransactionsListDefaultFilters;
  arkV2DisabledSections?: ArkSectionName[];
  arkV2HiddenSections?: ArkSectionName[];
  disableBankConnections?: boolean;
  isDirectSettings?: boolean;

  mobileFinancialConnectionsResult?: (
    | FinancialConnectionsSessionResult
    | CollectBankAccountTokenResult
  ) & {id: string};
  // This is used to turn on the ORR M5 UX in the account management component
  // Goal is to remove this once ARKv2 direct settings is GA
  isOrrM5?: boolean;
  prefillOptions?: PrefillOptions;
  // Used in AccountOnboarding to control which composition and/or
  // section to render, allowing consumers to implement navigation.
  step?: OnboardingStep;
  sectionView?: AccountManagementSectionType;

  // Used in HostedDashboardNotificationBanner to control the display of the mobile banner
  mobileBannerOptions?: MobileBannerOptions;

  // Used in Payments to set default filters
  paymentsListDefaultFilters?: PaymentsListDefaultFilters;

  // Stripe Tax embedded component customization RFC
  displayCountries?: string[];
  displayHeadOfficeCountries?: string[];
  hideProductTaxCodeSelector?: boolean;
  hideDescription?: boolean;
  disabled?: boolean;
  initialTaxCode?: string;

  // API reviewed
  // Preview
  appData?: Record<string, string>;

  // Private preview API reviewed for ReportingChart
  [intervalStart]?: Date;
  [intervalEnd]?: Date;

  // GA
  collectionOptions?: CollectionOptions;
}

export type SupplementalObjectKey = keyof IConnectElementSupplementalObject;

export type SupplementalObjectValue =
  IConnectElementSupplementalObject[SupplementalObjectKey];

export class ConnectElementSupplementalObjects extends ConnectElementObservableValues<IConnectElementSupplementalObject> {}
