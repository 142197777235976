/* eslint-disable no-restricted-syntax, guard-for-in */
import {$address, $token, $tokens} from './constants';
import {hash, isConditionalObject} from '../util';
import {VERSION} from '../version';
import type {Token} from './types';

export const DEFAULT_KEY = '$default';

type AnyTokenOrTokens = (Token.Value | Token.AbstractMap) & {
  [key: string]: AnyTokenOrTokens;
};

/**
 * Returns true if it is a token, false if it's not a token
 */
export function isToken(t?: unknown): t is Token.Value {
  if (t && (t as Token.Value)?.[$token]) {
    return true;
  }
  return false;
}

/**
 * Returns true if it is a token map, false if it's not a token map
 */
export function isTokens(t?: unknown): t is Token.AbstractMap {
  if (t && (t as Token.AbstractMap)?.[$tokens]) {
    return true;
  }
  return false;
}

/** @internal */
export function getTokenId(token: Token.Value | Token.AbstractMap): string {
  const id = token[$address]?.id;
  if (id == null) {
    throw new Error('Missing token id');
  }
  return id;
}

export function getTokenData(
  token: Token.Value | Token.AbstractMap,
): Token.Data {
  return token[$address];
}

export function getTokenPath(token: Token.Value | Token.AbstractMap): string {
  const address = token[$address]?.path;
  if (address == null) {
    throw new Error('Missing token address');
  }
  return address;
}

export function getTokenMode(
  token: Token.Value | Token.AbstractMap,
): Token.Mode {
  const mode = token[$address]?.mode;
  if (mode == null) {
    throw new Error('Missing token mode');
  }
  return mode;
}

const STARTS_WITH_DIGIT = /^\d/;

export function serializeCustomProperty(id: string): string {
  const prefix = id.match(STARTS_WITH_DIGIT) ? 's-' : '';
  return `--${prefix}${id.replace(/[.]/g, '-')}`;
}

export function getTokenBasename(
  token: Token.Value | Token.AbstractMap,
  count = 1,
): string {
  const path = getTokenPath(token);

  let remaining = count;
  let index;
  while (remaining && index !== -1) {
    index = path.lastIndexOf('.', index == null ? undefined : index - 1);
    remaining--;
  }
  return index === -1 || index == null ? path : path.slice(index + 1);
}

/**
 * Returns an array of enumerable keys of the token. Works
 * much like Object.keys, but also lists keys that are inherited
 * from $default
 */
export function tokenKeys(obj: Token.AbstractMap): string[] {
  const result = [] as string[];
  for (const key in obj) {
    result.push(key);
  }
  return result;
}

export function getTokenDefaultValue<V>(token: Token.Value<V>): V {
  return token[$token].defaultValue;
}

const tokenMethods = Object.create(null);
Object.defineProperties(tokenMethods, {
  toJSON: {
    value() {
      return getTokenId(this as unknown as Token.Value);
    },
    enumerable: false,
  },
  toString: {
    value() {
      if (!isToken(this)) {
        return '';
      }
      return `${this.valueOf()}`;
    },
    enumerable: false,
  },
  valueOf: {
    value() {
      if (!isToken(this)) {
        return undefined;
      }
      return `var(${serializeCustomProperty(getTokenId(this))})`;
    },
    enumerable: false,
  },
});

function createTokenObject(): Token.Value | Token.AbstractMap {
  const token = Object.create(tokenMethods) as Token.Value | Token.AbstractMap;
  token[$address] = {};
  return token;
}

/**
 * Creates a new token.
 * Useful for creating tokens with object values.
 */
export function createToken<V>(defaultValue: V): Token.Value<V> {
  const token = createTokenObject() as Token.Value<V>;
  token[$token] = {defaultValue};
  return token;
}

const tokenByHash = new Map<string, Token.Value>();
/**
 * Resets the cached map of tokens. Useful when testing.
 */
export function resetTokenCache(): void {
  tokenByHash.clear();
}

type RequiredMapOptions = Required<Token.MapOptions<never>>;

/**
 * Creates new map of tokens. Keeps path separate from options to avoid creating
 * unnecessary objects.
 */
function createTokensImplementation<T, P extends string>(
  path: P,
  options: RequiredMapOptions,
  input: T,
): T extends Token.MapShape ? Token.Map<T, P> : Token.Value<T> {
  type InputObject = Record<string, unknown>;
  type ReturnType = T extends Token.MapShape ? Token.Map<T, P> : Token.Value<T>;

  const isInputToken = isToken(input);

  if ((isInputToken || isTokens(input)) && input[$address].path === path) {
    return input as ReturnType;
  }

  let tokens: ReturnType;
  if (typeof input !== 'object' || !input) {
    tokens = createToken(input) as ReturnType;
  } else if (DEFAULT_KEY in input) {
    // If we have a $default property we recursively create that object as
    // tokens first, and then use it as the inherited prototype for the
    // non-default tokens
    const defaultTokens = createTokensImplementation(
      path,
      options,
      (input as InputObject)[DEFAULT_KEY],
    ) as ReturnType;

    tokens = Object.create(defaultTokens);

    Object.defineProperty(tokens, DEFAULT_KEY, {
      value: defaultTokens,
      enumerable: false,
    });
  } else if (isInputToken) {
    tokens = createToken(input as T) as ReturnType;
  } else {
    tokens = createTokenObject() as ReturnType;
  }

  // Address the token
  const tokenHash = hash(VERSION + path);
  const id =
    (process.env.NODE_ENV === 'production' ? '' : `${path}.`) + tokenHash;

  if (isToken(tokens)) {
    // TODO(koop): Consider throwing if the id already exists
    tokenByHash.set(tokenHash, tokens);
  }

  const address = tokens[$address];
  address.id = id;
  address.path = path;
  address.mode = options.mode;

  // Recurse through the tokens input
  if (typeof input === 'object' && !isInputToken) {
    // Mark the tokens as a branch
    (tokens as Token.Map<Token.MapShape>)[$tokens] = true;

    const inputObj = input as InputObject;
    const keys = isTokens(inputObj)
      ? tokenKeys(inputObj)
      : Object.keys(inputObj);
    keys.forEach((key) => {
      if (key === DEFAULT_KEY) {
        return;
      }

      (tokens as Token.Map<Token.MapShape>)[key] = createTokensImplementation(
        path ? `${path}.${key}` : key,
        options,
        (input as Record<string, unknown>)[key],
      );
    });
  }

  return tokens;
}

const defaultOptions = {mode: 'dynamic'} as RequiredMapOptions;

/**
 * Creates new map of tokens.
 */
export function createTokens<P extends string, Schema, T extends Schema>(
  options: P | Token.MapOptions<P, Schema>,
  input: T,
): T extends Token.MapShape ? Token.Map<T, P> : Token.Value<T> {
  return typeof options === 'string'
    ? createTokensImplementation(options, defaultOptions, input)
    : createTokensImplementation(
        options.path,
        {
          mode: options?.mode ?? defaultOptions.mode,
        } as RequiredMapOptions,
        input,
      );
}

function resolveTokenCondition<T>(
  result: Record<string, T>,
  isConditionTrue: ((condition: string) => boolean) | void,
): T {
  if (isConditionTrue) {
    const conditions = Object.keys(result);
    for (let i = conditions.length - 1; i >= 0; i--) {
      const condition = conditions[i];
      if (isConditionTrue(condition)) {
        return result[condition];
      }
    }
  }
  return result.default;
}

export function resolveTokenShallow<T extends Token.Value>(
  token: T,
  overrideMap?: Token.OverrideMap,
  isConditionTrue?: (condition: string) => boolean,
  recordId?: (id: string) => void,
): string | number | unknown | T {
  if (!isToken(token)) {
    throw new Error('Token cannot be resolved');
  }

  const defaultValue = token[$token].defaultValue;
  let result = defaultValue;
  if (overrideMap) {
    const id = getTokenId(token);

    result = overrideMap[id] ?? defaultValue;

    if (recordId) {
      recordId(id);
    }
  }

  if (isConditionalObject(result)) {
    result = resolveTokenCondition(result, isConditionTrue) ?? defaultValue;
  }
  return result;
}

/**
 * Returns the value assigned to the input token
 */
export function resolveToken<T extends Token.Value>(
  token: T,
  overrideMap?: Token.OverrideMap,
  isConditionTrue?: (condition: string) => boolean,
  recordId?: (id: string) => void,
): string | number | unknown {
  const result = resolveTokenShallow(
    token,
    overrideMap,
    isConditionTrue,
    recordId,
  );

  return isToken(result)
    ? resolveToken(result, overrideMap, isConditionTrue, recordId)
    : result;
}

export function walkTokens(
  tokens: Token.AbstractMap,
  callback: (value: Token.AbstractMap | Token.Value, path: string) => void,
  path = '',
): void {
  callback(tokens, path);

  for (const key in tokens) {
    const value = (tokens as AnyTokenOrTokens)[key] as Token.AbstractMap;
    walkTokens(value, callback, path === '' ? key : `${path}.${key}`);
  }
}

const digits = /^\d+$/;

function isDigits(str: string): boolean {
  return !!str.match(digits);
}

export function forEachToken(
  tokens: Token.AbstractMap,
  callback: (value: Token.Value, path: string) => void,
): void {
  walkTokens(tokens, (value, path) => {
    if (isToken(value)) {
      let key = path;
      if (!path) {
        key = getTokenBasename(value);
      } else if (isDigits(path)) {
        key = getTokenBasename(value, 2);
      }
      callback(value, key);
    }
  });
}

/**
 * Returns a single level key/val object with every token in a set
 * and its provided default value
 */
export function enumerateTokens(
  tokens: Token.AbstractMap,
): Record<string, unknown> {
  const result = {} as Record<string, unknown>;
  walkTokens(tokens, (value, path) => {
    if (isToken(value)) {
      result[path] = resolveToken(value);
    }
  });
  return result;
}

const addressedTokenMaps = [] as Token.AbstractMap[];

export function addressTokens(tokens: Token.AbstractMap): void {
  if (!addressedTokenMaps.includes(tokens)) {
    addressedTokenMaps.push(tokens);
  }
}

function tokenIdToHash(id: string): string {
  return id.slice(id.lastIndexOf('.') + 1);
}

export function getTokenByHash(tokenHash: string): Token.Value | void {
  return tokenByHash.get(tokenHash);
}

export function getTokenById(id: string): Token.Value | void {
  return getTokenByHash(tokenIdToHash(id));
}

export function getAddressedTokenMaps(): ReadonlyArray<Token.AbstractMap> {
  return addressedTokenMaps;
}

const TOKEN_VAR_HASH_PATTERN = /var\(--(?:[\w-]*-)?(\w+)\)/g;
const FROZEN_TOKENS = new Set<Token.Value>();

export function setStaticToken<V>(token: Token.Value<V>, value: V): void {
  if (getTokenMode(token) !== 'static') {
    throw new Error(`Expected token to be static: "${getTokenPath(token)}"`);
  }
  if (FROZEN_TOKENS.has(token)) {
    throw new Error(
      `A static token cannot be set after its value is frozen. Token "${getTokenPath(
        token,
      )}" has already been referenced in another value.`,
    );
  }
  token[$token].defaultValue = value;
}

/**
 * Replaces references to static tokens within a string with their values.
 * When a static token is replaced for the first time, its value is frozen.
 */
export function replaceStaticTokens(value: string): string {
  return value.replace(TOKEN_VAR_HASH_PATTERN, (match, tokenHash) => {
    const token = getTokenByHash(tokenHash);
    if (token && getTokenMode(token) === 'static') {
      FROZEN_TOKENS.add(token);
      return `${resolveToken(token) || ''}`;
    }
    return match;
  });
}
