import {useCallback, useEffect, useMemo, useReducer} from 'react';
import {getIntents, provider} from '../intent';
import {addClassName, scheduleStyleIntent} from '../style';
import {createContextTree, isConditionalObject} from '../util';
import {getActiveViewRevision} from '../view/util';
import {assignTokensIntent} from './assignTokens';
import {
  TOKEN_PROVIDER_CLASS_NAME,
  createTokenDefaultStyles,
  createTokenOverrideStyles,
} from './css';
import {
  getAddressedTokenMaps,
  getTokenPath,
  getTokenId,
  resolveToken,
} from './token';
import type {Token} from './types';
import type {Style} from '../style';
import type {View} from '../view';

// This is only exported to use in `beta/token/useTokenValues.ts` (it's not otherwise a public API):
export const $accessed = Symbol('accessed');
const atMedia = '@media ';

type ContextType = Token.OverrideMap;

const {Context, useContextValue, useSelector} = createContextTree<ContextType>({
  create(source) {
    return Object.create(source ?? null);
  },
  assign(target, source, keys) {
    keys.forEach((key) => {
      if (key in source) {
        target[key] = source[key];
      } else {
        delete target[key];
      }
    });
  },
  broadcast(target, source, keys) {
    return keys.filter((key) => !Object.is(target[key], source[key]));
  },
  useValue(overrides) {
    const [version, update] = useReducer((x) => x + 1, 0);

    const [resolved, activeMediaQueries] = useMemo(() => {
      // Reference the version to ensure this recalculates when media queries
      // are updated.
      // eslint-disable-next-line no-unused-expressions
      version;

      const resolved = {} as ContextType;
      const activeMediaQueries = new Set<string>();
      Object.keys(overrides).forEach((key) => {
        const value = overrides[key];
        if (!isConditionalObject(value)) {
          resolved[key] = value;
          return;
        }
        if (typeof window === 'undefined') {
          // When we're on the server, use the default value
          if ('default' in value) {
            resolved[key] = value.default;
          }
        } else {
          // When we're on the client, check media queries
          // TODO(koop): Update this to make sure that hydration is ok
          const conditions = Object.keys(value);
          for (let i = conditions.length - 1; i >= 0; i--) {
            const condition = conditions[i];
            if (condition.startsWith(atMedia)) {
              const mediaQuery = condition.slice(atMedia.length);
              activeMediaQueries.add(mediaQuery);

              const mediaQueryList = window.matchMedia(mediaQuery);
              if (mediaQueryList.matches) {
                resolved[key] = value[condition];
                break;
              }
            }
          }
        }
      });
      return [resolved, activeMediaQueries];
    }, [overrides, version]);

    useEffect(() => {
      const mediaQueryLists = Array.from(activeMediaQueries).map(
        (mediaQuery) => {
          const mediaQueryList = window.matchMedia(mediaQuery);
          mediaQueryList.addEventListener('change', update);
          return mediaQueryList;
        },
      );
      return () => {
        mediaQueryLists.forEach((mediaQueryList) =>
          mediaQueryList.removeEventListener('change', update),
        );
      };
    }, [activeMediaQueries, update]);

    return resolved;
  },
});

// This is only exported to use in `beta/token/useTokenValues.ts` (it's not otherwise a public API):
export const useTokenContextSelector = useSelector;

const TokenContext = Context;

let insertedIndex = 0;

export function runTokenIntents<Props>(
  revision: View.ViewRevision<Props>,
): View.Intent<Props>[] | void {
  // Add any new addressed token defaults
  const addressedTokenMaps = getAddressedTokenMaps();
  for (let i = insertedIndex; i < addressedTokenMaps.length; i++) {
    const styles = createTokenDefaultStyles(addressedTokenMaps[i]);
    // Directly insert global styles. We do this because tokens can be addressed
    // at any time, and therefore are not deterministically associated with
    // rendering the current component. This is safe to do because they are
    // fully global and don't produce class names to be rendered on the current
    // component's element.
    scheduleStyleIntent(styles);
  }
  insertedIndex = addressedTokenMaps.length;

  // Check if we're adding any token overrides
  const overrides = getIntents(assignTokensIntent, revision);
  if (!overrides) {
    return;
  }
  // Check whether we're reading and writing a token on the same element.
  // TODO(koop): Make sure this accounts for composed views
  const accessed = getActiveViewRevision()?.[$accessed] as Map<
    string,
    string
  > | void;
  accessed?.forEach((path, id) => {
    if (id in overrides) {
      throw new Error(
        `Cannot access and provide a token for "${path}" on the same component.`,
      );
    }
  });

  // Mark the current element as a token provider
  addClassName(revision.props as object, TOKEN_PROVIDER_CLASS_NAME);

  return [
    // TODO(koop): Consider not creating styles on tests bc custom properties
    // aren't supported in jsdom
    createTokenOverrideStyles(overrides),
    provider(TokenContext, overrides),
  ];
}

export function useTokenValue<T>(token: Token.Value<T>): T {
  const id = getTokenId(token);
  const revision = getActiveViewRevision();
  if (revision) {
    revision[$accessed] ??= new Map();
    (revision[$accessed] as Map<string, string>).set(id, getTokenPath(token));
  }

  return useSelector(
    useCallback(
      (overrides, subscribe) => {
        return resolveToken(token, overrides, undefined, subscribe);
      },
      [token],
    ),
  ) as T;
}

/**
 * Reapplies CSS classes for all tokens in the tree. Used for ensuring React
 * portals have the correct overrides for token CSS custom properties.
 */
export function usePortalTokenStyles(): Style.Intent {
  const overrideMap = useContextValue();

  const revision = getActiveViewRevision();

  if (revision) {
    // Mark the current element as a token provider
    addClassName(
      revision.props as {className: string},
      TOKEN_PROVIDER_CLASS_NAME,
    );
  }

  // TODO(koop): This method currently generates new styles every time a token
  // in the tree changes. We can instead generate one class per token property,
  // which would allow us to reuse the original override classes here.
  return createTokenOverrideStyles(overrideMap);
}
