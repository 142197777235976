import type {Matcher} from 'src/internal/encoder/types';

type GenericObject = Record<string | number, unknown>;

export default function traverse(
  data: unknown,
  matchers: ReadonlyArray<Matcher> = [],
): unknown {
  return traverseInternal(data, matchers, new Map());
}

function traverseInternal(
  value: unknown,
  matchers: ReadonlyArray<Matcher>,
  visited = new Map<unknown, unknown>(),
): unknown {
  if (visited.has(value)) {
    return visited.get(value);
  }

  visited.set(value, undefined);

  const continueTraverse = (value: unknown) => {
    return traverseInternal(value, matchers, visited);
  };

  let matched = false;
  let result = value;

  for (let i = 0; i < matchers.length; i++) {
    const [matches, setter] = matchers[i];

    if (matches(value) && setter) {
      matched = true;
      result = setter(value, continueTraverse);
      break;
    }
  }

  if (matched) {
    visited.set(value, result);

    return result;
  }

  switch (true) {
    case Array.isArray(value):
      result = value.map(continueTraverse);
      break;

    case value && Object.getPrototypeOf(value) === Object.prototype:
      const copy: GenericObject = {};

      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          copy[key] = continueTraverse((value as GenericObject)[key]);
        }
      }

      result = copy;
      break;
  }

  visited.set(value, result);

  return result;
}
