import type * as React from 'react';
import type {DynamicIntent} from './dynamic';
import {dynamic} from './dynamic';
import type {View} from './types';
import {setPriority} from './priority';
import {Priority} from './constants';
import {isEventProperty} from '../util';
import {getActiveViewRevision} from './util';
import type {NarrowValue} from '../util';

const propertySymbols = new Map<string, symbol>();
function getPropertySymbol(property: string): symbol {
  const existing = propertySymbols.get(property);
  if (existing) {
    return existing;
  }
  const symbol = Symbol(property);
  propertySymbols.set(property, symbol);
  return symbol;
}

export interface ViewPropertyProcessor<Property extends string, Value> {
  (
    value: Value,
    revision: View.ViewRevision<{
      [K in Property]: Value;
    }>,
    property: Property,
  ): void;
}

function createListProcessor<Property extends string, Value>(options: {
  flatten(list: Value[], length: number): Value;
}): ViewPropertyProcessor<Property, Value>;

function createListProcessor<Property extends string, Value>(options: {
  merge(value: Value, previousValue: Value): Value;
}): ViewPropertyProcessor<Property, Value>;

function createListProcessor<Property extends string, Value>({
  flatten,
  merge,
}: {
  flatten?: (list: Value[], length: number) => Value;
  merge?: (value: Value, previousValue: Value) => Value;
}): ViewPropertyProcessor<Property, Value> {
  type ListPropertyState = {list: Value[]; merged: Value[]};

  return function listPropertyProcessor(value, revision, property) {
    const symbol = getPropertySymbol(property);

    // Initialize list state for current revision
    if (!(symbol in revision)) {
      revision[symbol] = {list: [], merged: []} as ListPropertyState;
    }

    const committedRevision = revision.instance.committedRevision;
    const committed = committedRevision?.[symbol] as ListPropertyState | void;
    const current = revision[symbol] as ListPropertyState;

    // Ensure the current prop value matches the latest merged value; otherwise
    // treat the current prop value as the first value to merge.
    const propValue = revision.props[property];
    if (propValue !== current.merged[current.merged.length - 1]) {
      // TODO(koop): Probably need to create a new array here bc `flatten`
      // recieves a length that may become inaccurate
      current.list.length = 0;
      current.merged.length = 0;
      current.list.push(propValue);
      current.merged.push(propValue);
    }

    const index = current.list.length;
    // Add the value to the current state
    current.list.push(value);

    let mergedValue = value;
    if (index === 0) {
      // nothing to merge; use the existing value
    } else if (
      // Check if we can reuse the committed merged value
      committed &&
      // Is the value we're adding the same?
      committed.list[index] === value &&
      // Is the merged output up to this value the same?
      committed.merged[index - 1] === current.merged[index - 1]
    ) {
      mergedValue = committed.merged[index];
    } else if (flatten) {
      mergedValue = flatten(current.list, current.list.length);
    } else if (merge) {
      mergedValue = merge(value, current.merged[index - 1]);
    }

    current.merged.push(mergedValue);
    revision.props[property] = current.merged[index];
  };
}

export const setEvent = createListProcessor({
  flatten(listeners: (EventListener | undefined)[], length: number) {
    return function mergedEvent(event: Event) {
      for (let i = 0; i < length; i++) {
        listeners[i]?.(event);
      }
    };
  },
});

export const setRef = createListProcessor({
  flatten<T>(refs: React.Ref<T>[], length: number): (value: T) => void {
    let currentValue: T | null = null;

    const ref = (value: T) => {
      currentValue = value;

      for (let i = 0; i < length; i++) {
        const ref = refs[i];
        if (ref) {
          if (typeof ref === 'function') {
            ref(value);
          } else {
            (ref as {current: T}).current = value;
          }
        }
      }
    };

    // Define `ref.current` to support `props.ref.current`
    // TODO(koop): Remove this once we've fully migrated to `props.inherits`
    Object.defineProperty(ref, 'current', {
      get() {
        return currentValue;
      },
    });

    return ref;
  },
});

export const setStyle = createListProcessor({
  merge(
    value: React.CSSProperties,
    previousValue: React.CSSProperties,
  ): React.CSSProperties {
    return Object.assign({}, previousValue, value);
  },
});

const processors = {
  default(value, revision, property) {
    if (isEventProperty(property)) {
      setEvent(
        value as EventListener,
        revision as View.ViewRevision<{
          [key: string]: EventListener | undefined;
        }>,
        property,
      );
    } else {
      revision.props[property] = value;
    }
  },
  className(value, revision) {
    revision.props.className = revision.props.className
      ? `${revision.props.className} ${value}`
      : value;
  },
  ref: setRef,
  style: setStyle,
} as Record<string, ViewPropertyProcessor<string, unknown>>;

type RestrictedKeys<T, Keys> =
  | {
      [K in keyof T]: K extends Keys ? never : NarrowValue<T[K]>;
    }
  | {
      [K in keyof T]: K extends Keys ? never : T[K];
    };

type Assignment<Props> = RestrictedKeys<
  Partial<Props>,
  'uses' | 'css' | 'subviews'
>;
type AssignmentFunction<Props> = (props: Props) => Assignment<Props>;

/**
 * Safely merge values, refs and event handlers into a component’s props.
 *
 * @see https://sail.stripe.me/apis/assignprops
 */
export function assignProps<Props>(
  source: Assignment<Props>,
): DynamicIntent<Partial<Props>>;
export function assignProps<Props>(
  source: AssignmentFunction<Props>,
): DynamicIntent<Partial<Props>>;
export function assignProps<Props>(
  source: Assignment<Props> | AssignmentFunction<Props>,
): // The result is of type Partial<Props> because at the time assignProps is called
// we may be targeting an interface that is a subset of the component props.
DynamicIntent<Partial<Props>> {
  return setPriority(
    Priority.High,
    dynamic((props) => {
      const revision = getActiveViewRevision();

      const properties =
        typeof source === 'function'
          ? (source as AssignmentFunction<Props>)(props as Props)
          : source;

      const propertyNames = Object.keys(
        properties as object,
      ) as (keyof Props)[];

      for (let i = 0; i < propertyNames.length; i++) {
        const property = propertyNames[i];
        const processor = processors[property as string] || processors.default;
        processor(
          properties[property],
          revision as unknown as View.ViewRevision<{[key: string]: unknown}>,
          property as string,
        );
      }
    }),
  );
}
