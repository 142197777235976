import type {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';

export const paymentPaymentDisputesParameter = 'payment' as const;

const paymentDisputesParams = [paymentPaymentDisputesParameter] as const;
type PaymentDisputesParams = (typeof paymentDisputesParams)[number];
export type PaymentDisputesHtmlParams = Partial<
  Record<PaymentDisputesParams, string>
>;

export const PaymentDisputesConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "paymentDisputes" */ '../../../components/Disputes/PaymentDisputes'
    ),
  htmlAttributes: {
    [paymentPaymentDisputesParameter]: {
      isInternal: false,
    },
  },
  htmlEvents: ['ondisputesloaded'],
  customHtmlElementMethods: {
    setPayment: {
      type: 'AttributeSettingMethod',
      attribute: paymentPaymentDisputesParameter,
      isInternal: false,
      description: 'Sets the payment id to display details for.',
    },
    setOnDisputesLoaded: {
      type: 'EventListenerMethod',
      event: 'ondisputesloaded',
      isInternal: false,
      description:
        'Sets the event listener that allows a user to pass in a callback function that provides the total number of disputes on a payment.',
    },
  },
  enabledKey: 'payment_disputes',
  // enabledOverrideFlag: ComponentOverrideFlag.payment_disputes,
  releaseState: 'internal',
  sentryTeam: 'accounts_ui',
  teamOwner: 'connected-account-experiences-eng',
  description: 'Shows disputes for a given payment.',
  // docSitePath: TODO,
  // syntheticTestInfo: {
  //   testUrl: TODO,
  //   rcTestUrl: TODO,
  // },
};
