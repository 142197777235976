import {message} from '@sail/intl';
import type {ConnectElementConfig} from '../ConnectElementList';
import {ComponentOverrideFlag} from '../../../utils/flags/flags';
import {RECENT_TRANSACTIONS_HTML_ATTRIBUTE} from '../HtmlAttributes';

const messages = {
  errorText: message({
    id: 'connectEmbed.transactions.errorBoundary.text',
    defaultMessage:
      'Your money is secure, but your transactions cannot be loaded here.',
    description: 'Message shown when an unhandled payments error is caught',
  }),
};

const transactionsComponentParams = [
  RECENT_TRANSACTIONS_HTML_ATTRIBUTE,
] as const;
type TransactionsComponentParams = (typeof transactionsComponentParams)[number];
export type TransactionsComponentHtmlParams = Partial<
  Record<TransactionsComponentParams, string>
>;

export const TransactionsListConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "transactions" */ '../../../components/Transactions/Transactions'
    ),
  messageOverrides: messages,
  releaseState: 'internal',
  enabledKey: 'transactions_list',
  enabledOverrideFlag: ComponentOverrideFlag.transactions_list,
  htmlAttributes: {
    // Internal - to enable these for platforms, we'll need API review
    [RECENT_TRANSACTIONS_HTML_ATTRIBUTE]: {
      isInternal: true,
    },
  },
  customHtmlElementMethods: {
    setDefaultFilters: {
      type: 'SupplementalObject',
      objectToSet: 'transactionsListDefaultFilters',
      isInternal: true,
      description:
        'Sets the default filters for the transactions list. Includes type only.',
    },
  },
  sentryTeam: 'accounts_ui',
  teamOwner: 'connected-account-experiences-eng',
  description:
    'Displays the lists of balance transactions on the given Connected account.',
  syntheticTestInfo: {
    testUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/transactions-list',
    rcTestUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/transactions-list-rc',
  },
};
