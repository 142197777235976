import type {View} from './types';

let cssRegistry = {} as Record<View.CssSerializerKey, View.CssSerializer>;

export function registerCssSerializer<Serializer extends View.CssSerializer>(
  id: View.CssSerializerKey,
  css: Serializer,
): Serializer {
  cssRegistry[id] = css;
  return css;
}

export function getRegisteredCssSerializer<Key extends View.CssSerializerKey>(
  id: Key = 'default' as Key,
): View.CssSerializerFor<Key> | void {
  return cssRegistry[id] as View.CssSerializerFor<Key>;
}

/**
 * Used to test CSS serializer registry
 */
export function resetCssSerializerRegistry(): void {
  cssRegistry = {} as Record<View.CssSerializerKey, View.CssSerializer>;
}
