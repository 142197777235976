import {message} from '@sail/intl';
import {ComponentOverrideFlag} from '../../../utils/flags/flags';
import type {ConnectElementConfig} from '../ConnectElementList';

const messages = {
  errorText: message({
    id: 'connectEmbed.accountManagement.errorBoundary.text',
    defaultMessage:
      'Your money is secure, but your account information cannot be loaded here.',
    description: 'Message shown when an unhandled account error is caught',
  }),
};

// private attributes
export const enableAddressAutocompleteParameter =
  'enable-address-autocomplete' as const;
export const showExpressSettingsParameter = 'show-express-settings' as const;

export const AccountManagementConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "accountManagementExperience" */ '../../../components/Account/AccountManagement/AccountManagement'
    ),
  messageOverrides: messages,
  enabledKey: 'account_management',
  enabledOverrideFlag: ComponentOverrideFlag.account_management,
  releaseState: 'ga',
  requiresOnboarding: true,
  requiresAuthentication: true,
  sentryTeam: 'risk_compliance_experiences',
  teamOwner: 'risk-compliance-experiences',
  htmlAttributes: {
    [enableAddressAutocompleteParameter]: {
      isInternal: true,
    },
    [showExpressSettingsParameter]: {
      isInternal: true,
    },
  },
  customHtmlElementMethods: {
    // This is used to disable editing of specific ARKv2 sections.
    // Currently, only the company, person, support, and external account sections can be disabled.
    setDisabledSections: {
      type: 'SupplementalObject',
      objectToSet: 'arkV2DisabledSections',
      isInternal: true,
      description:
        'Sets the list of sections in account management that are disabled. Currently, only the company, person, support, and external account sections can be disabled.',
    },
    setHiddenSections: {
      type: 'SupplementalObject',
      objectToSet: 'arkV2HiddenSections',
      isInternal: true,
      description:
        'Sets the list of sections in account management that are hidden.',
    },
    setCollectionOptions: {
      type: 'SupplementalObject',
      objectToSet: 'collectionOptions',
      isInternal: false,
      description:
        'Sets the collection options for the account management form. Includes future_requirements and eventually_due.',
    },
    setDisableBankConnections: {
      type: 'SupplementalObject',
      objectToSet: 'disableBankConnections',
      isInternal: true,
      description:
        'Disables the ability to add linked accounts in the external account form.',
    },
    setIsDirectSettings: {
      type: 'SupplementalObject',
      objectToSet: 'isDirectSettings',
      isInternal: true,
      description:
        'Indicates whether or not the account management component is being used in direct settings.',
    },
    setIsOrrM5: {
      type: 'SupplementalObject',
      objectToSet: 'isOrrM5',
      isInternal: true,
      description:
        'Indicates whether or not the account management component is being used for account that is in ORR M5.',
    },
  },
  description:
    'Displays the account information that was collected during onboarding and allows editing. Also allows for managing user credentials and linked external accounts.',
  docSitePath: '/connect/supported-embedded-components/account-management',
  syntheticTestInfo: {
    testUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/sus-acct-mgmt-iframe',
    rcTestUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/susacctmgmt-iframe-rc',
  },
};
