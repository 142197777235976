import {toIntent} from '../intent';
import {getRegisteredCssSerializer} from './css';
import {createSubviewIntents} from './createSubviewIntents';
import {createVariants} from './createVariants';
// import {dynamic} from './dynamic';
import {setDefaults} from './setDefaults';
import type {InternalSubviews} from './createSubviewIntents';
import type {View} from './types';
import {normalizeIntents, normalizeStyleIntents} from './normalizeIntents';

export function createViewOptionsIntents<
  Props,
  Options extends View.ConfigOptions = View.ConfigOptions<Props>,
>(
  config: View.Config<Props, Options>,
  viewOptions: View.ViewOptions<Props, Options>,
  isSubview = false,
  intents: View.Intent<Props>[] = [],
): View.Intent<Props>[] {
  if (viewOptions.uses) {
    normalizeIntents(viewOptions.uses, intents);
  }

  const options = config.options;
  if (viewOptions.css) {
    const css = getRegisteredCssSerializer(options.css) as View.CssSerializer;
    if (css) {
      normalizeStyleIntents(css, viewOptions.css, intents);
    }
  }

  if (!isSubview) {
    if (viewOptions.defaults) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      intents.push(setDefaults(viewOptions.defaults as any));
    }

    if (viewOptions.subviews) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      const subviewIntents = createSubviews(config, viewOptions.subviews);
      if (subviewIntents) {
        intents.push(subviewIntents);
      }
    }

    if (viewOptions.variants) {
      intents.push(
        createVariants<Props>(
          viewOptions.variants as View.Variants<Props>,
          viewOptions.forward,
          (variantOption) => createViewOptionsIntents(config, variantOption),
        ),
      );
    }
  }

  return intents;
}

export function createSubviews<
  Props,
  Options extends View.ConfigOptions = never,
>(
  config: View.Config<Props, Options>,
  subviews: View.SubviewProps<Props>,
): View.SubviewMapIntent<Props> | void {
  let subviewIntents: InternalSubviews | undefined;
  Object.keys(subviews).forEach((subview) => {
    if (subview in subviews) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const subviewValue = (subviews as any)[subview];
      subviewIntents ??= createSubviewIntents({});
      subviewIntents[subview] = toIntent(
        normalizeIntents<Props>(subviewValue) ||
          createViewOptionsIntents(config, subviewValue, true),
      );
    }
  });
  return subviewIntents as View.SubviewMapIntent<Props> | void;
}
