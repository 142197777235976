import {getViewRevisionFromProps} from './ViewRevision';
import type {View} from './types';

export function extractRefFromProps<Props>(
  props: Props,
): [View.InferRef<Props> | undefined, View.OmitRef<Props>] {
  let ref;
  const revision = getViewRevisionFromProps(props);
  if (revision && 'ref' in revision) {
    ref = revision.ref;
    delete revision.ref;
  }
  return [ref, props as View.OmitRef<Props>];
}
