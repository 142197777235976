import {createStyleIntent} from './StyleIntent';
import {makeBatchedStyles} from './util';
import {serializeKey} from './serialize';

function serializeResetKey(
  styles: Record<string, string>,
  selector: string,
  media: string,
) {
  return serializeKey(JSON.stringify(styles), media + selector);
}

export const createResetStyles = createStyleIntent(
  'reset',
  serializeResetKey,
  (styles: Record<string, string>, selector: string, media: string) => () => {
    let reset = styles as Record<string, unknown>;
    reset = {
      [selector || '&']: reset,
    };

    if (media) {
      reset = {
        [media]: reset,
      };
    }
    return makeBatchedStyles(reset);
  },
);
