import {createObjectIntentType} from '../intent';
import {assignConditionalProperty, mergeConditionalProperty} from '../util';
import {
  DEFAULT_KEY,
  getTokenId,
  getTokenMode,
  getTokenPath,
  isToken,
  isTokens,
} from './token';
import type {Token} from './types';

function assignTokensInternal<T>(
  input: Token.AbstractMap | Token.Value,
  assignment: Partial<T>,
  overrides: Token.OverrideMap,
  condition: string,
  isDefault = false,
): Token.OverrideMap {
  const isObject = assignment && typeof assignment === 'object';
  if (isToken(input)) {
    if (getTokenMode(input) === 'static') {
      throw new Error(
        `Cannot assign value to static token "${getTokenPath(input)}"`,
      );
    }
    // Assign in the following cases:
    // - The assignment is not an object
    // - The assignment is a token
    // - The current input is a leaf node (and does not contain nested tokens)
    // - The object was passed to a $default property
    if (!isObject || isToken(assignment) || !isTokens(input) || isDefault) {
      assignConditionalProperty(
        overrides,
        getTokenId(input),
        assignment as unknown,
        condition,
      );
    }
  }
  if (isObject) {
    Object.keys(assignment).forEach((key) => {
      if (key in input) {
        assignTokensInternal(
          key === DEFAULT_KEY
            ? input
            : (input as unknown as Record<string, Token.Value>)[key],
          assignment[key as keyof T] as Token.OverrideMap,
          overrides,
          condition,
          key === DEFAULT_KEY,
        );
      }
    });
  }
  return overrides;
}

function assignOverrideMap(
  target: Token.OverrideMap,
  source: Token.OverrideMap,
): void {
  const keys = Object.keys(source);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    mergeConditionalProperty(target, key, source[key]);
  }
}

export const assignTokensIntent = createObjectIntentType(
  'assignTokens',
  (decorate) =>
    (obj: Token.OverrideMap): Token.OverrideIntent =>
      decorate(obj),
  (target, source) => {
    assignOverrideMap(target, source);
  },
);

/**
 * Merges the provided override intents into a single intent.
 */
export function mergeOverrideIntents(
  overrideIntents: Token.OverrideIntent[],
): Token.OverrideIntent {
  const result: Token.OverrideMap = {};
  for (let i = 0; i < overrideIntents.length; i++) {
    assignOverrideMap(result, overrideIntents[i]);
  }
  return assignTokensIntent(result);
}

/**
 * Creates an intent to assign a token or tokens to new values when a provided
 * condition is true.
 */
export function assignConditionalTokens<T>(
  condition: string,
  input: T,
  assignment: Token.Assignment<T>,
): Token.OverrideIntent {
  return assignTokensIntent(
    assignTokensInternal(
      input as unknown as Token.Value,
      assignment as Partial<T>,
      {},
      condition,
    ),
  );
}

/**
 * Creates an intent to assign a token or tokens to new values.
 *
 * @see https://sail.stripe.me/apis/assigntokens
 */
export function assignTokens<T>(
  input: T,
  assignment: Token.Assignment<T>,
): Token.OverrideIntent {
  return assignConditionalTokens('', input, assignment);
}
