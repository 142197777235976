import {shallowEqualArray} from '../util';

export function compareProps<T extends object>(a: T, b: T): boolean {
  if (Object.is(a, b)) {
    return true;
  }
  const aKeys = Object.keys(a) as (keyof T)[];
  const bKeys = Object.keys(b) as (keyof T)[];
  if (aKeys.length !== bKeys.length) {
    return false;
  }
  for (let i = 0; i < aKeys.length; i++) {
    const key = aKeys[i];

    if (!(key in b)) {
      return false;
    }

    const aValue = a[key];
    const bValue = b[key];

    switch (key) {
      // TODO(koop): Our comparison heuristic can be improved:
      // - Handle `css` prop
      // - Handle `subviews` prop
      // - Handle instances of `toIntent` within `uses` prop
      case 'uses':
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (!shallowEqualArray(aValue as any, bValue as any)) {
          return false;
        }
        break;
      default:
        if (!Object.is(aValue, bValue)) {
          return false;
        }
    }
  }
  return true;
}
