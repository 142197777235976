/* eslint-disable @stripe-internal/embedded/no-restricted-globals */
import React from 'react';
import ReactDOM from 'react-dom';
import {debounce} from 'lodash-es';
import {uiRpc} from '@sail/ui';
import {Analytics, Metrics, Reports} from '@sail/observability';
import {getEffectiveConnectionTypeFromNavigator} from '@stripe-internal/connect-embedded-lib';
import type {BaseConnectElementParams} from './buildAnalyticsSender';
import type {
  ConnectElementImportKeys,
  ConnectElementConfig,
} from './ConnectJSInterface/ConnectElementList';
import {CONNECT_ELEMENT_IMPORTS} from './ConnectJSInterface/ConnectElementList';
import {ConnectElementAttributes} from './ConnectElementAttributes';
import {transformNodeMapIntoRecord} from '../utils/transformNodeMapIntoRecord';
import {ConnectElementEventEmitter} from './ConnectElementEventEmitter';
import {ConnectElementRenderedState} from './ConnectElementRenderedState';
import {getCommsCenterQueryParams} from './utils/getCommsCenterQueryParams';
import {getDefaultConnector} from './connector';
import {getTeamForComponent} from '../utils/getTeamForComponent';
import type {Connect} from './Connect';
import type {AccountSessionClaim} from '../data-layer-frame/types';
import {DeferredPromise} from '../utils/DeferredPromise';
import {buildUILayerFrame} from '../ui-layer-frame/buildUILayerFrame';
import ConnectElementBaseMessageChannel from '../ui-layer-frame/ConnectElementBaseMessageChannel';
import {PlatformInteractions} from '../ui-layer-frame/utils/ExternalInteractions';
import type {SupplementalFunctionKey} from './ConnectElementSupplementalFunctions';
import {ConnectElementSupplementalFunctions} from './ConnectElementSupplementalFunctions';
import type {ConnectElementEventType} from './ConnectJSInterface/HtmlEventTypes';
import {uuid} from '../utils/uuid';
import {ConnectElementSupplementalObjects} from './ConnectElementSupplementalObjects';
import {getStronglyTypedEntries} from '../utils/getStronglyTypedEntries';
import {getObservabilityConfig} from './getObservabilityConfig';
import {getConnectJsLoadStartTime} from '../utils/telemetry/performanceMetrics';
import {
  isCustomEvent,
  mapAuthErrorToEmbeddedError,
} from './utils/embeddedLoadErrors';
import {getCurrentScriptUrlContext} from '../utils/getCurrentScriptUrlContext';
import {processMobileFinancialConnectionsResult} from './utils/processMobileFinancialConnectionsResult';

/**
 *
 * The base custom element that each specific element
 * can extend from. This will wrap UPS context and
 * watch for attributes that change and require a rerender.
 */
export default class ConnectElementBase extends HTMLElement {
  private connector: Connect;

  private connectorIsSet = new DeferredPromise<Connect>();

  public connectElementAttributes: ConnectElementAttributes;

  public connectElementSupplementalFunction: ConnectElementSupplementalFunctions;

  public connectElementSupplementalObjects: ConnectElementSupplementalObjects;

  public eventListeners: Partial<
    Record<ConnectElementEventType, (ev: CustomEvent) => void>
  > = {};

  private connectElementConfig: ConnectElementConfig;

  private connectElementRenderedState: ConnectElementRenderedState;

  private rendered = false;

  /** Awaits the `onload` event from the UI layer frame after it has been created. */
  private createUILayerPromise: DeferredPromise<HTMLIFrameElement> =
    new DeferredPromise();

  /** The object encapsulating the message channel logic between the platform frame and the UI layer frame */
  private connectElementBaseMessageChannel!: ConnectElementBaseMessageChannel;

  /** The name attribute of the UI layer's window. */
  private frameName: string;

  /** Parent div of the UI layer frame HTML element */
  private frameParentDiv: HTMLDivElement = document.createElement('div');

  /** Whether the custom element is removed from the DOM */
  private isDisconnected = false;

  /** The object that emits connect element events */
  private eventEmitter: ConnectElementEventEmitter;

  /** Resize observer for the platform frame viewport */
  private resizeObserver: ResizeObserver | null = null;

  constructor(private connectElement: ConnectElementImportKeys) {
    super();
    this.connector = getDefaultConnector();
    this.connectElementAttributes = new ConnectElementAttributes(
      transformNodeMapIntoRecord(this.attributes),
    );

    this.connectElementSupplementalFunction =
      new ConnectElementSupplementalFunctions({});

    this.connectElementSupplementalObjects =
      new ConnectElementSupplementalObjects({});

    this.connectElementRenderedState = new ConnectElementRenderedState();

    this.connectElementConfig = CONNECT_ELEMENT_IMPORTS[this.connectElement];

    this.frameName = `stripe-connect-ui-layer-${uuid()}`;
    this.eventEmitter = new ConnectElementEventEmitter(
      this.emitEvent,
      this.connectElement,
    );

    this.handleResize = debounce(this.handleResize.bind(this), 250);

    // Report any load errors that occur during authentication
    this.connector.deferredAuthPromise.promise.catch((error: any) => {
      const embeddedError = mapAuthErrorToEmbeddedError(error);
      this.eventEmitter.emitEvent('_internal_loaderror', {
        error,
        type: embeddedError.type,
      });
      this.eventEmitter.emitEvent('loaderror', {
        error: embeddedError,
      });
    });

    this.addEventListener('loaderror', (event: Event) => {
      if (isCustomEvent(event)) {
        const teamName = getTeamForComponent(connectElement);

        const {analytics} = this.getObservability();

        analytics.track('submerchant_surfaces_connect_element_load_error', {
          connectElement: this.connectElement,
          hostApp:
            this.connector.connectJsOptions.getValues().metaOptions?.hostApp ??
            'platform',
          teamName,
          connectJsPath: getCurrentScriptUrlContext().absoluteFolderPath,
          platformHostName: window.location.hostname,
          connectionEffectiveType:
            getEffectiveConnectionTypeFromNavigator(navigator),
          reactSdkVersion:
            this.getAttribute('reactSdkAnalytics') ||
            this.connector.connectJsOptions.getValues().metaOptions.sdkOptions
              ?.reactSdkVersion ||
            null,
          mobileSdk:
            this.connector.connectJsOptions.getValues().metaOptions.mobileSdk,
          mobileSdkVersion:
            this.connector.connectJsOptions.getValues().metaOptions
              .mobileSdkVersion,
          // We log the initial values for the HTML attributes
          htmlAttributes: JSON.stringify(this.connectElementAttributes.values),
          error_message: event.detail.error.message,
          api_result: event.detail.error.type,
        });
      }
    });
  }

  handleResize() {
    if (!this.isDisconnected) {
      this.connectElementBaseMessageChannel.notifyPlatformViewportResize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
  }

  setupResizeObserver() {
    if (typeof ResizeObserver !== 'undefined') {
      this.resizeObserver = new ResizeObserver(this.handleResize);
      this.resizeObserver.observe(document.body);
    }
  }

  getObservability() {
    const observabilityConfig = getObservabilityConfig({
      frameMessenger: this.connector.frameMessenger,
      connectElement: this.connectElement,
      metaOptions: this.connector.connectJsOptions.getValues().metaOptions,
    });

    const analytics = new Analytics(observabilityConfig);
    const metrics = new Metrics(observabilityConfig);
    const reports = new Reports(observabilityConfig);

    return {metrics, analytics, reports};
  }

  ensureConnectorIsSet() {
    return this.connectorIsSet;
  }

  setConnector = (_connector: Connect) => {
    this.connector = _connector;
    this.connectorIsSet.resolve(_connector);
  };

  createUILayerFrame(): Promise<HTMLIFrameElement> {
    return new Promise((resolve, reject) => {
      try {
        // Setup iframe root
        const iframe = buildUILayerFrame(this.frameName, this.connectElement);

        // Initialize the Sail UI rpc, used to run media queries on the platform frame
        uiRpc?.initServerFromIframe(iframe);

        iframe.onload = () => {
          resolve(iframe);
        };

        this.frameParentDiv.appendChild(iframe);
        this.appendChild(this.frameParentDiv);
      } catch (error: any) {
        reject(error);
      }
    });
  }

  async updateAuthToIframe(
    deferredAuthPromise: DeferredPromise<AccountSessionClaim>,
  ) {
    try {
      const claimValues = await deferredAuthPromise.promise;
      this.connectElementBaseMessageChannel.updateAccountSession(claimValues);
    } catch (error: any) {
      this.connectElementBaseMessageChannel.updateAccountSession('error');
    }
  }

  private async notifyDataLayerLoaded() {
    try {
      await this.connector.frameMessenger.deferredFrame.promise;
      this.connectElementBaseMessageChannel.dataLayerLoaded();
    } catch (error: any) {
      const errorType = 'api_error';
      this.eventEmitter.emitEvent('_internal_loaderror', {
        error,
        type: errorType,
      });
      this.eventEmitter.emitEvent('loaderror', {
        error: {type: errorType, message: error.message},
      });
    }
  }

  async renderInIframe(initialRenderTime: number) {
    const [iframe, loadedFonts] = await Promise.all([
      this.createUILayerPromise.promise,
      this.connector.fontLoader.loadedFontsPromise,
    ]);
    // If running in optimized loading mode, we must wait for the connector to be set before running this code
    if (window.StripeConnect?.optimizedLoading) {
      this.connector = await this.ensureConnectorIsSet().promise;
    }

    const {onboardingState, connectJsOptions, loggedOutState} = this.connector;

    // Register observer so we can update the UI layer when the onboarding state changes (see Connect.ts)
    onboardingState.registerObserver(() => {
      this.connectElementBaseMessageChannel.notifyOnboardingStateChanged(
        onboardingState.values,
      );
    });

    // Register observer so we can update the UI layer when the logged out state changes (see Connect.ts)
    loggedOutState.registerObserver(() => {
      this.connectElementBaseMessageChannel.notifyLoggedOutStateChanged(
        loggedOutState.values,
      );
    });

    // Register observer so we can update the ConnectJSOptions on the UI layer when the platform changes it
    connectJsOptions.registerObserver(() => {
      this.connectElementBaseMessageChannel.notifyConnectJsOptionsChanged(
        connectJsOptions.getPlatformSpecifiedValues(),
      );
      this.logLoadedFont();
    });

    // Register observer so we can inform the UI layer when the component is disconnected
    this.connectElementRenderedState.registerObserver((isRendered) => {
      this.connectElementBaseMessageChannel.updateRenderedState(isRendered);
    });

    // If rendered in an iframe, we need to pipe the HTML attribute value changes to the UI layer
    this.connectElementAttributes.registerObserver((values) => {
      // Do not send `style` attribute as it is not used in the UI layer. This is only applicable to the payment-details component
      delete values.style;
      this.connectElementBaseMessageChannel.updateHTMLAttribute(values);
    });

    // If rendered in an iframe, we need to pipe the supplemental object changes to the UI layer
    this.connectElementSupplementalObjects.registerObserver((values) => {
      this.connectElementBaseMessageChannel.updateConnectElementSupplementalObjects(
        values,
      );
    });

    // When financial connections are collected on Mobile, we need to pipe the result to the UI layer
    // mobileFinancialConnectionsResult resolves as the response of the StripeJS collect financial connections accounts API, so that the deferred promise in UILayerWrapper.collectFinancialConnectionsAccountsOverride can be resolved.
    this.connectElementSupplementalObjects.registerObserver((values) => {
      if (values.mobileFinancialConnectionsResult) {
        this.connectElementBaseMessageChannel.collectMobileFinancialConnectionsResult(
          {
            id: values.mobileFinancialConnectionsResult.id,
            result: processMobileFinancialConnectionsResult(
              values.mobileFinancialConnectionsResult,
            ),
          },
        );
      }
    });

    // Register observer for knowing if the supplemental functions are defined or not
    this.connectElementSupplementalFunction.registerObserver((values) => {
      this.connectElementBaseMessageChannel.updateSupplementalFunctionValues(
        values,
      );
    });

    const {analytics, reports} = this.getObservability();

    this.connectElementBaseMessageChannel =
      new ConnectElementBaseMessageChannel(
        iframe,
        this.eventEmitter.emitEvent,
        onboardingState,
        connectJsOptions,
        this.connectElement,
        this.connector.frameMessenger,
        this.connectElementSupplementalFunction,
        this.isDisconnected,
        analytics,
        reports,
      );

    const supplementalFunctionValues: Partial<
      Record<SupplementalFunctionKey, boolean>
    > = {};
    getStronglyTypedEntries(
      this.connectElementSupplementalFunction.values,
    ).forEach(([key, value]) => {
      supplementalFunctionValues[key] = !!value;
    });

    const connectJsLoadStartTime = getConnectJsLoadStartTime();
    // Initialize the UI layer's message channel
    this.connectElementBaseMessageChannel.initUILayer({
      fontFamily: getComputedStyle(this).fontFamily,
      htmlAttributes: this.connectElementAttributes.values,
      initOptions: this.connector.connectJsOptions.getPlatformSpecifiedValues(),
      publishableKey: this.connector.publishableKey,
      timeToInitializeUILayer: window.performance.now() - initialRenderTime,
      connectInstanceId: this.connector.connectInstanceId,
      loadedFonts,
      supplementalObjects: this.connectElementSupplementalObjects.values,
      supplementalFunctionValues,
      onboardingStateValues: onboardingState.values,
      platformViewportValues: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      loggedOutStateValues: loggedOutState.values,
      jsLoadToInitUILayer: connectJsLoadStartTime
        ? window.performance.now() - connectJsLoadStartTime
        : undefined,
      browserSecureContext: this.connector.browserSecureContext,
    });

    // Notify the UI layer that the data layer is loaded
    this.notifyDataLayerLoaded();

    this.logLoadedFont();

    // This action (piping auth to the iframe) is done in the background
    this.updateAuthToIframe(this.connector.deferredAuthPromise);

    // We need to render a component to be able to use hooks from PlatformInteractions
    const div = document.createElement('div');
    this.appendChild(div);
    ReactDOM.render(
      <PlatformInteractions
        messageChannel={this.connectElementBaseMessageChannel}
      />,
      div,
    );

    return this.connector;
  }

  logLoadedFont() {
    const values = this.connector.connectJsOptions.getValues();
    const calculatedFontFamily =
      values.appearance.variables?.fontFamily ||
      getComputedStyle(this)?.fontFamily;
    return this.connector.frameMessenger.logIframeLoadedFont(
      calculatedFontFamily,
    );
  }

  async render() {
    if (this.rendered) {
      return;
    }

    const initialRenderTime = window.performance?.now();
    const {connectElement} = this;

    // Empty the container out if there's anything in it
    // eslint-disable-next-line no-restricted-syntax
    for (const child of this.childNodes) {
      // We do not remove the UI layer iframe as it may be used
      if (!(child === this.frameParentDiv)) {
        this.removeChild(child);
      }
    }

    // We want don't want components that normally render as an overlay to take up any space in the DOM
    // unless they're rendering inline. We need to use display: none because otherwise things like flexbox
    // and grid may implicitly add gaps around the element.
    const updateDisplay = () => {
      if (
        this.connectElementConfig.optionalInlineOverlay &&
        this.connectElementAttributes.values.inline !== 'true'
      ) {
        this.frameParentDiv.style.display = 'none';
      } else {
        this.frameParentDiv.style.display = 'block';
      }
    };
    updateDisplay();
    this.connectElementAttributes.registerObserver(updateDisplay);

    await this.renderInIframe(initialRenderTime);

    const teamName = getTeamForComponent(connectElement);

    const baseParams: BaseConnectElementParams = {
      connectElement: this.connectElement,
      hostApp:
        this.connector.connectJsOptions.getValues().metaOptions?.hostApp ??
        'platform',
      teamName,
    };

    const {analytics} = this.getObservability();

    analytics.viewed('submerchant_surfaces_connect_element_rendered', {
      ...baseParams,
      ...getCommsCenterQueryParams(),
      reactSdkVersion:
        this.getAttribute('reactSdkAnalytics') ||
        this.connector.connectJsOptions.getValues().metaOptions.sdkOptions
          ?.reactSdkVersion ||
        null,
      // We log the initial values for the HTML attributes
      htmlAttributes: JSON.stringify(this.connectElementAttributes.values),
      inheritedFontFamily: getComputedStyle(this).fontFamily,
    });

    const debounceLogHtmlAttributes = debounce(() => {
      analytics.track(
        'submerchant_surfaces_connect_element_html_attribute_changed',
        {
          ...baseParams,
          // We log the updated values for the HTML attributes
          htmlAttributes: JSON.stringify(this.connectElementAttributes.values),
        },
      );
    }, 1000);
    // Log the changes to HTML attributes
    this.connectElementAttributes.registerObserver(() => {
      debounceLogHtmlAttributes();
    });
    this.setupResizeObserver();

    this.rendered = true;
    this.connectElementRenderedState.update(true);
  }

  attributeChangedCallback(
    _name: string,
    _oldValue: string | null,
    _newValue: string | null,
  ) {
    const values = transformNodeMapIntoRecord(this.attributes);
    this.connectElementAttributes.updateValues(values);
  }

  disconnectedCallback() {
    this.isDisconnected = true;
    this.connectElementRenderedState.update(false);
    if (this.connectElementBaseMessageChannel) {
      this.connectElementBaseMessageChannel.destroy(this.frameName);
    }
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  }

  connectedCallback() {
    this.createUILayerPromise.resolve(this.createUILayerFrame());
    // Catch any load errors that occur during the initial render process
    this.render().catch((error: any) => {
      const errorType = 'api_error';
      this.eventEmitter.emitEvent('_internal_loaderror', {
        error,
        type: errorType,
      });
      this.eventEmitter.emitEvent('loaderror', {
        error: {type: errorType, message: error.message},
      });
    });
  }

  /**
   * Wraps the dispatchEvent call to preserve the connect element instance context
   */
  emitEvent = (event: CustomEvent): void => {
    this.dispatchEvent(event);
  };
}
