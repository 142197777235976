import {createObjectIntentType, getIntents} from '../intent';

import type {View} from './types';

type OmitProps = Record<string, boolean>;

export const omitProps = createObjectIntentType(
  'omitProps',
  (decorate) => (obj: OmitProps) => decorate(obj),
);

export function applyOmitProps<Props>(
  revision: View.ViewRevision<Props>,
): void {
  const propsToOmit = getIntents(omitProps, revision);
  if (!propsToOmit) {
    return;
  }
  const propNames = Object.keys(propsToOmit) as (keyof Props)[];
  for (let i = 0; i < propNames.length; i++) {
    const propName = propNames[i];
    delete revision.props[propName];
  }
}
