import {getDefaultRpcInstance} from 'src/internal/rpc/defaultRpc';
import {v4 as uuid} from 'uuid';

import type {ImperativeMetrics, ObservabilityConfig} from 'src/types';

const rpcMethodMap = {
  increment: 'metricsIncrement',
  gauge: 'metricsGauge',
  set: 'metricsSet',
} as const;

const method = <TMethod extends keyof ImperativeMetrics>(
  name: TMethod,
): ImperativeMetrics[TMethod] => {
  return (config: ObservabilityConfig | null, ...params: [...any]): void => {
    if (!config || config.metrics?.disabled) {
      return;
    }

    if (config._tmp_dualReporting) {
      const index = name === 'increment' ? 1 : 2;

      params[index] = {
        ...params[index],
        _tdr_id: uuid(),
      };
    }

    const injectedMethod = config._internal_observability?.metrics?.[name];

    if (injectedMethod) {
      // @ts-expect-error Passed in `params` is a union of tuples.
      injectedMethod(config, ...params);

      if (!config._tmp_dualReporting) {
        return;
      }
    }

    const rpcClient = config.rpc ?? getDefaultRpcInstance().client;
    const rpcMethod = rpcMethodMap[name];

    const paramsWithPotentialDualReporting = config._tmp_dualReporting
      ? [`_tdr.${params[0]}`, ...params.slice(1)]
      : params;

    rpcClient.call<any>(rpcMethod, config, ...paramsWithPotentialDualReporting);
  };
};

/**
 * Use `increment` for counting events, such as the number of page views.
 *
 * Count metrics can be consulted in SignalFx as:
 * ```
 * metric name:
 *   analytics_event_logger.event_meter.client_metrics.count
 * tag:
 *   event:<metric>
 * ```
 *
 * **Note:**
 * Metrics are sent to AEL only if explicitly allowlisted in the client config ([go/client_config.yaml](https://go/client_config.yaml)).
 * For more information on AEL and metrics please consult the [AEL documentation](https://go/ael#signalfx-metrics).
 *
 * @example Basic {{include "./examples/metrics.increment.tsx"}}
 */
export const increment = method('increment');

/**
 * Use `gauge` for recording values of data, such as performance metrics.
 *
 * Gauge metrics can be consulted in SignalFx as:
 *
 * ```
 * metric name:
 *   analytics_event_logger.event_meter.client_metrics.gauge
 * tag:
 *   event:<event_name>.<gauge_name>
 * ```
 *
 * **Note:**
 * Metrics are sent to AEL only if explicitly allowlisted in the client config ([go/client_config.yaml](https://go/client_config.yaml)).
 * For more information on AEL and metrics please consult the [AEL documentation](https://go/ael#signalfx-metrics).
 *
 * @example Basic {{include "./examples/metrics.gauge.tsx"}}
 */
export const gauge = method('gauge');

/**
 * Use `set` to count unique occurrences of an event between 10 second flushes.
 *
 * Set metrics can be consulted in SignalFx as:
 *
 * ```
 * metric name:
 *   analytics_event_logger.event_meter.client_metrics.set
 * tag:
 *   event:<event_name>.<set_name>
 * ```
 *
 * **Note:**
 * Metrics are sent to AEL only if explicitly allowlisted in the client config ([go/client_config.yaml](https://go/client_config.yaml)).
 * For more information on AEL and metrics please consult the [AEL documentation](https://go/ael#signalfx-metrics).
 *
 * @example Basic {{include "./examples/metrics.set.tsx"}}
 */
export const set = method('set');
