import {isServer} from '@sail/engine';

import type {RpcClient} from '@sail/rpc';
import {createRpc, fromIframe, fromInsideIframe} from '@sail/rpc';
import {mediaQueryManager} from '../../components/hooks/useMatchMedia/MediaQueryManager';

type RpcSubscription<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends (...args: any[]) => {
    unsubscribe(): void;
  },
> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: Parameters<T> extends [any, any] ? Parameters<T>[0] : undefined;
  result: Parameters<Parameters<T>[1]>[0];
};

const subscriptions = {
  matchMedia(
    {query}: {query: string},
    callback: (result: {matches: boolean}) => void,
  ) {
    callback({matches: mediaQueryManager.matchMedia(query).matches});

    const unsubscribe = mediaQueryManager.addQueryListener(query, ({matches}) => {
      callback({matches});
    });

    return {
      unsubscribe,
    };
  },
};

export type UiRpcSubscriptions = {
  matchMedia: RpcSubscription<typeof subscriptions.matchMedia>;
};

export const rpc = createRpc<Record<string, never>, UiRpcSubscriptions>({
  namespace: '@sail/ui',
  subscriptions,
  methods: {},
});

class UiRpc {
  public client: RpcClient<Record<string, never>, UiRpcSubscriptions>;

  constructor() {
    this.client = rpc.initClient({
      transport: fromInsideIframe(),
    });

    // Only initialize the rpc server if we are not inside an iframe.
    // If inside an iframe, the rpc server must be initialized from the parent window.
    const notInsideIframe = !window.parent || window.parent === window;

    if (notInsideIframe) {
      rpc.initServer({transport: fromInsideIframe()});
    }
  }

  public initServerFromIframe(iframe: HTMLIFrameElement) {
    return rpc.initServer({transport: fromIframe(iframe)});
  }
}

export const uiRpc = !isServer ? new UiRpc() : null;
