import {useCallback} from 'react';
import {unstable_batchedUpdates} from 'react-dom';

/**
 * Batches all state updates in the provided handler.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useBatchedHandler<T extends (...args: any[]) => any>(
  callback?: T,
) {
  const batchedCallback = useCallback(
    (...args) => unstable_batchedUpdates(() => callback?.(...args)),
    [callback],
  );
  if (callback) {
    return batchedCallback as T;
  }
}
