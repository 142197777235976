import {hash} from '../util';
import {createStyleIntent} from './StyleIntent';
import {serializeKeyframe} from './serialize';
import type {Style} from './types';

const KEYFRAME_PREFIX = 'sn-animation-';
const KEYFRAME_PATTERN = new RegExp(`${KEYFRAME_PREFIX}[-_\\w]+`, 'g');

function serializeAnimationName(keyframes: Style.Keyframes): string {
  const name = hash(JSON.stringify(keyframes));
  return `${KEYFRAME_PREFIX}${name}`;
}

export function getAnimationNames(value: string): string[] {
  return value.match(KEYFRAME_PATTERN) || [];
}

/**
 * Creates a set of keyframes that can be used in CSS animations.
 *
 * @see https://sail.stripe.me/apis/keyframes/
 */
export const createKeyframes = createStyleIntent(
  'global',
  serializeAnimationName,
  (keyframes: Style.Keyframes) => {
    const name = serializeAnimationName(keyframes);
    const lazy = () => {
      let keyframesStr = `@keyframes ${name} {`;
      (Object.keys(keyframes) as Style.KeyframeSelector[]).forEach((key) => {
        const keyframe = keyframes[key];
        if (keyframe) {
          keyframesStr += serializeKeyframe(key, keyframe);
        }
      });
      keyframesStr += '}';
      return [keyframesStr];
    };
    lazy.toString = () => name;
    return lazy;
  },
);
