export const $context = Symbol('context');
export const $hasView = Symbol('hasView');
export const $instance = Symbol('instance');
export const $revision = Symbol('revision');
export const $shared = Symbol('shared');
export const $subview = Symbol('subview');

export enum Priority {
  Normal = 1,
  High = 2,
}
