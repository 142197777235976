import {createArrayIntentType, getIntents} from '../intent';
import {StyleSheets} from '../stylesheets';
import {VIEW_CLASS} from './constants';
import {StyleCache} from './StyleCache';
import {addClassName} from './util';
import type {Style} from './types';
import type {View} from '../view';

export const decorateStyleIntent = createArrayIntentType(
  'StyleIntent',
  (decorate) =>
    (styles: Style.LazyStyles): Style.Intent =>
      decorate(styles),
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createStyleIntent<Args extends any[]>(
  layer: Style.Layer,
  createKey: (...args: Args) => string,
  createLazyStyles: (...args: Args) => Style.LazyStylesFunction,
): (...args: Args) => Style.Intent {
  return (...args: Args) => {
    const key = createKey(...args);
    const cached = StyleCache.get(key);
    if (cached) {
      return cached;
    }

    const lazy = createLazyStyles(...args) as Style.LazyStyles;
    lazy.key = key;
    lazy.layer = layer;
    const intent = decorateStyleIntent(lazy);
    StyleCache.register(intent);
    return intent;
  };
}

const scheduledIntents = [] as Style.Intent[];
let lastInsertedIndex = 0;

StyleSheets.onFlush(() => {
  lastInsertedIndex = 0;
});

export function scheduleStyleIntent(intent: Style.Intent): void {
  if (intent.layer !== 'global') {
    throw new Error('Only styles on the global layer can be scheduled.');
  }
  scheduledIntents.push(intent);
}

export function runStyleIntents<Props>(
  revision: View.ViewRevision<Props>,
): void {
  // Directly insert any scheduled global style intents.
  // We do this because these intents can be scheduled at any time, and
  // therefore are not deterministically associated with rendering the current
  // component. This is safe to do because they are fully global and don't
  // produce class names to be rendered on the current component's element.
  for (let i = lastInsertedIndex; i < scheduledIntents.length; i++) {
    // Directly insert global styles.
    StyleCache.insert([scheduledIntents[i].key]);
  }
  lastInsertedIndex = scheduledIntents.length;

  // Gather and insert any style intents for the current component.
  const intents = getIntents(decorateStyleIntent, revision);

  const className = intents
    ? StyleCache.insert(intents.map(({key}) => key))
    : VIEW_CLASS;

  addClassName(revision.props as object, className);
}
