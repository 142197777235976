/* eslint-disable @stripe-internal/embedded/no-restricted-globals */
import {useInteractAnywhere} from '@sail/ui';
import React from 'react';
import type ConnectElementBaseMessageChannel from '../ConnectElementBaseMessageChannel';

/**
 * This component is used in ConnectElementBase, i.e. on the platform frame.
 * It contains all the interactions that require communication with the UI layer,
 * i.e. closing tooltips on scroll and closing popovers/tooltips on clicking anywhere
 * on the platform frame.
 */
export const PlatformInteractions = ({
  children,
  messageChannel,
}: {
  children?: React.ReactNode;
  messageChannel: ConnectElementBaseMessageChannel;
}) => {
  React.useEffect(() => {
    window.addEventListener('scroll', () => messageChannel.scrollOutside(), {
      capture: true,
    });
    return () => {
      window.removeEventListener(
        'scroll',
        () => messageChannel.scrollOutside(),
        {
          capture: true,
        },
      );
    };
  }, [messageChannel]);

  useInteractAnywhere(() => messageChannel.interactOutside(), window.document);

  return <>{children}</>;
};
