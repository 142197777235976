import type {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';

export const DebugUtilsConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "debugUtils" */ '../../../components/DebugUtils/DebugUtils'
    ),
  enabledKey: 'internal',
  releaseState: 'internal',
  ignoreThemingValues: true,
  sentryTeam: 'accounts_ui',
  teamOwner: 'connected-account-experiences-eng',
  htmlAttributes: {
    'debug-utils-test-attribute': {
      isInternal: true,
    },
  },
  customHtmlElementMethods: {
    setDebugUtilsTestAttribute: {
      type: 'AttributeSettingMethod',
      attribute: 'debug-utils-test-attribute',
      isInternal: true,
      description: 'For testing only. Serves no real purpose.',
    },
    setFetchAdditionalPaymentPropsInternalDoNotUse: {
      type: 'SupplementalFunction',
      objectToSet: 'fetchAdditionalPaymentPropsInternalDoNotUse',
      isInternal: true,
      description: 'For testing only. Serves no real purpose.',
    },
    setFetchAdditionalPaymentPropsInternalDoNotUse2: {
      type: 'SupplementalFunction',
      objectToSet: 'fetchAdditionalPaymentPropsInternalDoNotUse2',
      isInternal: true,
      description: 'For testing only. Serves no real purpose.',
    },
    setOnDebugUtilsTest: {
      type: 'EventListenerMethod',
      event: 'debugUtilsTestEvent',
      isInternal: true,
      description: 'For testing only. Serves no real purpose.',
    },
    setDebugUtilObjectInternalDoNotUse: {
      type: 'SupplementalObject',
      objectToSet: 'debugUtilObjectInternalDoNotUse',
      isInternal: true,
      description: 'For testing only. Serves no real purpose.',
    },
  },
  errorConfiguration: {
    expectedErrors: ['debug-expected-error-internal-only'],
    mutedErrors: ['debug-muted-error-internal-only'],
  },
  description:
    'Debug utils for Connect embedded components useful for testing. Allows for tweaking flags, theming, among many other things.',
};
