export function assign<T extends {[key: string]: unknown}>(
  target: T,
  source: T,
): void {
  const keys = Object.keys(source);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    target[key as keyof T] = source[key] as T[keyof T];
  }
}
