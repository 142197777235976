import {createIntentType} from './intent';
import type {
  CreateIntent,
  ExtractReturnType,
  Intent,
  IntentFn,
  IntentType,
} from './types';

export function createMapIntentType<F extends IntentFn, K = string>(
  label: string,
  create: CreateIntent<F>,
  getKey: (intent: ExtractReturnType<F> & Intent) => K,
  merge?: (
    target: ExtractReturnType<F> & Intent,
    source: ExtractReturnType<F> & Intent,
  ) => ExtractReturnType<F> | void,
): F & IntentType<ExtractReturnType<F>, Map<K, ExtractReturnType<F>>> {
  function add(
    intent: ExtractReturnType<F>,
    state: Map<K, ExtractReturnType<F>> = new Map(),
  ) {
    const key = getKey(intent);

    if (merge) {
      const existing = state.get(key);
      if (typeof existing !== 'undefined') {
        const result = merge(existing, intent);
        if (typeof result !== 'undefined') {
          state.set(key, result);
        }
        return state;
      }
    }

    state.set(key, intent);
    return state;
  }

  return createIntentType<
    ExtractReturnType<F>,
    Map<K, ExtractReturnType<F>>,
    F
  >(label, {
    add,
    create,
    merge(target, source) {
      if (target) {
        source.forEach((intent) => add(intent, target));
        return target;
      } else {
        return new Map(source);
      }
    },
  });
}
