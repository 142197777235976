import {createSetIntentType, getIntents} from '../intent';
import type {View} from './types';

type ElementTransform = <Props>(
  revision: View.MutableViewRevision<Props>,
) => void;

export const createElementTransform = createSetIntentType(
  'createElementTransform',
  (decorate) => (transform: ElementTransform) => decorate(transform),
);

export function runElementTransforms<Props>(
  revision: View.ViewRevision<Props>,
): void {
  getIntents(createElementTransform, revision)?.forEach((transform) =>
    transform(revision),
  );
}
