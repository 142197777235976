import {HTML_ID} from './constants';
import {
  createStyleSheetLayer,
  flushStyleSheetLayer,
  getStyleSheetLayer,
  walkStyleSheetLayer,
} from './StyleSheetLayer';
import {
  ElementStyleSheet,
  InjectableConstructableStyleSheet,
} from './StyleSheet';
import {queryServerStyleTag, isServer} from '../util';
import type {StyleSheetLayer} from './StyleSheetLayer';
import type {StyleSheet, StyleSheetConstructor} from './StyleSheet';
import {VERSION} from '../version';

export type StyleSheetsConfig = {
  /** Where should style rules be written? */
  styleTarget: 'element' | 'constructable-sheet';
  /** When using a constructable sheet, the constructor needs to be polyfilled. Use this option to inject a value. */
  cssStyleSheetConstructor?: (sheetName?: string) => CSSStyleSheet;
  styleTargetConstructor: StyleSheetConstructor;
  /** Should the id of the <html> element be set to the specificity selector? */
  shouldSetHtmlElementId: boolean;
};

export function generateDefaultConfig(): StyleSheetsConfig {
  return {
    shouldSetHtmlElementId: true,
    styleTarget: 'element',
    styleTargetConstructor: ElementStyleSheet,
  };
}

export function getStyleSheetConstructor(
  styleTarget: StyleSheetsConfig['styleTarget'],
  cssStyleSheetConstructor?: StyleSheetsConfig['cssStyleSheetConstructor'],
): StyleSheetConstructor {
  if (styleTarget === 'constructable-sheet') {
    return InjectableConstructableStyleSheet(cssStyleSheetConstructor);
  } else {
    return ElementStyleSheet;
  }
}

type FreezeObserverCallback = (sheets: StyleSheet[]) => void;
type FlushObserverCallback = () => void;

type StyleSheetsMetrics = {
  rulesets: number;
  commits: number;
};
function createStyleSheetsMetrics(): StyleSheetsMetrics {
  return {
    rulesets: 0,
    commits: 0,
  };
}

export const StyleSheets = {
  classMap: new Map<string, string>(),

  frozen: false,

  metrics: createStyleSheetsMetrics(),

  observers: {
    beforeFlush: new Set<FlushObserverCallback>(),
    freeze: new Set<FreezeObserverCallback>(),
    flush: new Set<FlushObserverCallback>(),
  },

  scheduled: [] as Array<() => void>,
  sheets: createStyleSheetLayer('', getStyleSheetConstructor('element')),
  styleConfig: generateDefaultConfig(),

  updateStyleSheetType(): void {
    const newRootLayer = createStyleSheetLayer(
      '',
      this.styleConfig.styleTargetConstructor,
    );
    const sheets = this.flattenSheets();
    this.sheets = newRootLayer;

    sheets.forEach((sheet) => {
      this.insert([], sheet.name);
      sheet.flush();
    });
  },

  config(config: Partial<StyleSheetsConfig>): void {
    if (this.frozen) {
      throw new Error("Can't re-configure after layers have been frozen.");
    }

    if (
      config.styleTarget &&
      config.styleTarget !== this.styleConfig.styleTarget
    ) {
      this.styleConfig.styleTarget = config.styleTarget;
      this.styleConfig.styleTargetConstructor = getStyleSheetConstructor(
        config.styleTarget,
        config.cssStyleSheetConstructor,
      );
      this.updateStyleSheetType();
    }

    if (typeof config.shouldSetHtmlElementId !== 'undefined') {
      this.styleConfig.shouldSetHtmlElementId = config.shouldSetHtmlElementId;
    }
  },

  setClasses(key: string, classes: string): void {
    this.classMap.set(key, classes);
  },

  getClasses(key: string): string | void {
    return this.classMap.get(key);
  },

  schedule(callback: () => void) {
    if (isServer) {
      callback();
    } else {
      this.scheduled.push(callback);
    }
  },

  commit() {
    if (!this.scheduled.length) {
      return;
    }
    // Store the new commit count. Scheduled callbacks will likely call
    // `insert`, which increases the number of commits when called outside
    // of the `commit` method.
    const commits = this.metrics.commits + 1;

    // Run scheduled callbacks.
    const scheduled = this.scheduled;
    this.scheduled = [];
    for (let i = 0; i < scheduled.length; i++) {
      scheduled[i]();
    }

    // Update the commit count.
    this.metrics.commits = commits;
  },

  insert(rulesets: string[], layer = '', index = -1): number[] | void {
    this.commit();

    if (rulesets.length > 0) {
      this.freeze();
      // Increase the number of commits. If `insert` is called within `commit`,
      // this change will be discarded.
      this.metrics.commits++;
    }

    // Break the layer into segments.
    const segments = layer.split('.');

    // For each segment in the layer, check if we have a corresponding sheet.
    // If not, create one in the correct location.
    let sheets = this.sheets;
    for (let i = 0; i < segments.length; i++) {
      const segment = segments[i];
      if (segment) {
        sheets = getStyleSheetLayer(
          sheets,
          segment,
          this.styleConfig.styleTargetConstructor,
          this.frozen,
        );
      }
    }

    let failures;
    for (let i = 0; i < rulesets.length; i++) {
      if (!sheets.sheet.insertRule(rulesets[i], index)) {
        failures ??= [];
        failures.push(i);
      }
      // Increase the number of rulesets inserted
      this.metrics.rulesets++;
    }
    return failures;
  },

  /**
   * Run before flushing stylesheets on the server.
   * Used to insert any final rulesets.
   */
  beforeFlush(): void {
    this.observers.beforeFlush.forEach((callback) => callback());
  },

  /**
   * Returns and resets the metrics that track the number of commits and
   * rulesets inserted.
   */
  flushMetrics(): StyleSheetsMetrics {
    const metrics = this.metrics;
    this.metrics = createStyleSheetsMetrics();
    return metrics;
  },

  /**
   * Remove existing styles and clear the cache. Run at the end of each request
   * when rendering styles on a server.
   *
   * We persist layer order because it is statically declared. If we shift to
   * dynamic layer declarations in the future, `sheets` and `frozen` should be
   * fully reset.
   */
  flush(): void {
    this.commit();
    this.observers.flush.forEach((callback) => callback());

    flushStyleSheetLayer(this.sheets);

    this.classMap.clear();
    this.flushMetrics();
  },

  /**
   * Flush stylesheets and reset all properties.
   */
  reset(): void {
    this.flush();

    Object.assign(this, {
      frozen: false,
      styleConfig: generateDefaultConfig(),
      sheets: createStyleSheetLayer('', getStyleSheetConstructor('element')),
    });
  },

  freeze(): void {
    if (this.frozen) {
      return;
    }

    this.insert([], 'reset');
    this.insert([], 'global');
    this.insert([], 'dynamic');
    // TODO(mattsacks): Delete once Link is fully upgraded
    this.insert([], 'deprecatedOverride');
    this.insert([], 'atomic');
    this.frozen = true;
    let index = 1;
    this.walk((layer) => {
      layer.sheet.specificity = Array(index++)
        .fill(true)
        .map(() => `#${HTML_ID}`)
        .join('');
    });

    if (!isServer && this.styleConfig.shouldSetHtmlElementId) {
      const html = document.querySelector('html');
      if (html?.hasAttribute('id')) {
        if (html?.getAttribute('id') !== HTML_ID) {
          throw new Error(`Unexpected id on <html> element.`);
        }
      } else {
        html?.setAttribute('id', HTML_ID);
      }

      // Hydrate the classMap for any existing styles
      const style = queryServerStyleTag('', ['data-classes']);
      const classMapStr = style?.getAttribute('data-classes');
      if (classMapStr) {
        this.classMap = new Map(
          classMapStr.split(':').map((s) => s.split('/') as [string, string]),
        );
      }
    }

    const sheets = this.flattenSheets();

    // if we're on the client...
    if (!isServer) {
      // ...and document.head already contains one of our style tags...
      if (document.head.querySelector(`style[data-server="${VERSION}"]`)) {
        // ... then we're hydrating, and we should check that order matches
        const sheetNames = sheets.map((sheet) => sheet.name);
        const styleTagNames = Array.from(
          document.head.querySelectorAll(`style[data-server="${VERSION}"]`),
        ).map((sheet) => sheet.getAttribute('data-layer'));

        if (sheetNames.toString() !== styleTagNames.toString()) {
          throw new Error(
            'Error hydrating stylesheets: <style> elements rendered in an unexpected order.',
          );
        }
      }
    }

    this.observers.freeze.forEach((callback) => {
      callback(sheets);
    });
  },

  walk(callback: (layer: StyleSheetLayer) => void): void {
    walkStyleSheetLayer(this.sheets, callback);
  },

  flattenSheets(): StyleSheet[] {
    const sheets: StyleSheet[] = [];

    this.walk((layer) => sheets.push(layer.sheet));

    return sheets;
  },

  onBeforeFlush(callback: FlushObserverCallback): void {
    this.observers.beforeFlush.add(callback);
  },

  onFlush(callback: FlushObserverCallback): void {
    this.observers.flush.add(callback);
  },

  onFreeze(callback: FreezeObserverCallback): void {
    if (this.frozen) {
      callback(this.flattenSheets());
    }

    this.observers.freeze.add(callback);
  },
};
