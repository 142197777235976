export class HashMap {
  static defaultToString(id: number): string {
    return id.toString(36);
  }

  count = 0;

  map: Record<string, string>;

  toString: (id: number) => string;

  writable: boolean;

  constructor({
    source,
    toString = HashMap.defaultToString,
    writable = true,
  }: {
    source?: Record<string, string>;
    toString?: (id: number) => string;
    writable?: boolean;
  } = {}) {
    this.map = source ?? {};
    this.toString = toString;
    this.writable = writable;
  }

  has(value: string): boolean {
    return value in this.map;
  }

  get(value: string): string {
    const mapStr = this.map[value];
    if (mapStr) {
      return mapStr;
    }

    if (this.writable) {
      const mapStr = this.toString(this.count);
      this.map[value] = mapStr;
      this.count++;
      return mapStr;
    }

    return value;
  }
}
