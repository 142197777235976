import {VERSION} from '../version';

export const queryServerStyleTag = (
  name: string,
  additionalAttributes: string[] = [],
): HTMLStyleElement | undefined =>
  (document.querySelector(
    `style[data-layer="${name}"][data-server="${VERSION}"]${additionalAttributes.map(
      (attr) => `[${attr}]`,
    )}`,
  ) as HTMLStyleElement) ?? undefined;
