import type {ReportingChartHtmlParams} from './ConnectElementDefinitions/reporting-chart';

export const RECENT_PAYMENTS_HTML_ATTRIBUTE = 'recent-payments' as const;
export const RECENT_PAYOUTS_HTML_ATTRIBUTE = 'recent-payouts' as const;
export const RECENT_TRANSACTIONS_HTML_ATTRIBUTE =
  'recent-transactions' as const;

/**
 * This list contains the entirety of the HTML attributes specified by connect embedded components.
 * Adding an attribute to a component that a platform can use requires API review.
 */

export type ConnectHtmlAttributeType =
  /** GA API reviewed  */
  // Connect onboarding
  | 'full-terms-of-service-url'
  | 'recipient-terms-of-service-url'
  | 'privacy-policy-url'
  | 'skip-terms-of-service-collection'

  // Payment details
  | 'payment'

  // Issuing
  | 'default-card'
  | 'card-switching'
  | 'show-spend-controls'
  | 'issuing-program'

  /** Preview API reviewed  */

  // Reporting
  | keyof ReportingChartHtmlParams

  // Issuing
  | 'cardholder'
  | 'transaction'

  // Capital
  | 'default-financing-offer'
  | 'show-financing-selector'
  | 'faq-url'
  | 'support-url'
  | 'layout'
  | 'eligibility-criteria-url'
  | 'how-capital-works-url'

  // Payment method settings
  | 'payment-method-configuration'

  /** Internal / not API reviewed  */

  // Stripe apps
  | 'app'
  | 'test'
  | 'user-id'

  // Connect onboarding
  | 'align'
  | 'collect'
  | 'intent'
  | 'analytics-flow'
  | 'analytics-prefix'
  | 'show-icons'
  | 'show-save-for-later'
  | 'hide-footer-links'
  | 'enable-address-autocomplete'
  | 'preselected-legal-entity-token'
  | 'debug'
  | 'use-platform-branding-settings'

  // Account management
  | 'show-express-settings'

  // Issuing
  | 'issuing-element-config'

  // Treasury
  | 'financial-account'

  // Recipients
  | 'data-source'

  // Payments
  | typeof RECENT_PAYMENTS_HTML_ATTRIBUTE

  // Payouts
  | 'instant-payouts'
  | typeof RECENT_PAYOUTS_HTML_ATTRIBUTE

  // Payment details
  | 'charge-id'
  | 'visible'
  | 'inline'

  // Debug utils
  | 'debug-utils-test-attribute'

  // Debug UI config
  | 'simplified'
  | 'from-internal-site'

  // Earnings chart
  | 'chart-aspect-ratio'

  // Transactions list
  | typeof RECENT_TRANSACTIONS_HTML_ATTRIBUTE

  // Debug hosted dashboard preview
  | 'icon'
  | 'display-name'

  // Capital
  | 'application-presentation';
