import * as React from 'react';
import {useContext, useMemo, useRef} from 'react';
import {useIsomorphicLayoutEffect} from '../util';
import {toAnimationTask, emptyAnimationTask} from './animate';

import type {AnimationTask, NodeAnimation} from './animate';

type ListenHandlers = {
  in?: PresenceEventHandler;
  out?: PresenceEventHandler;
};

type PresenceEventHandler = () => void;

export interface Timeline {
  register(
    type: 'in' | 'out',
    anim: NodeAnimation | (() => AnimationTask | undefined),
  ): void;
  start(type: 'in' | 'out'): AnimationTask;
  prepare(type: 'in' | 'out'): void;
  has(type: 'in' | 'out'): boolean;
  listen(handlers: ListenHandlers): () => void;
  fire(type: 'in' | 'out'): void;
}

function noopTimeline(): Timeline {
  return {
    register: (_type, _anim) => {
      // Do nothing
    },
    start: (_type) => emptyAnimationTask(),
    prepare: (_type) => undefined,
    has: (_type) => false,
    listen: (handlers) => {
      handlers.in && handlers.in();
      return () => handlers.out && handlers.out();
    },

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    fire(_) {},
  };
}

class AnimationTimeline {
  animations: Map<string, (NodeAnimation | (() => AnimationTask))[]>;

  eventHandlers: {in: PresenceEventHandler[]; out: PresenceEventHandler[]};

  _lastEventType: 'in' | 'out' | null;

  constructor() {
    this.animations = new Map();
    this.eventHandlers = {in: [], out: []};
    this._lastEventType = null;
  }

  fire(type: 'in' | 'out') {
    this.eventHandlers[type].forEach((handler: () => void) => handler());
  }

  register(type: 'in' | 'out', anim: NodeAnimation | (() => AnimationTask)) {
    let anims = this.animations.get(type);
    if (anims == null) {
      anims = [];
      this.animations.set(type, anims);
    }
    anims.push(anim);

    return () => {
      if (anims) {
        const idx = anims.indexOf(anim);
        if (idx !== -1) {
          anims.splice(idx, 1);
        }
      }
    };
  }

  start(type: 'in' | 'out'): AnimationTask {
    this.prepare(type);
    const anims = this.animations.get(type) || [];

    // Note that only "push" `out` animations. `in` animations are special and
    // we always consider them one-shot, but `out` animations are reversable
    // and need to track previous properties just like any other transition.
    // This means that going out will *always* execute the out animation,
    // instead of reversing the in animation (if you switch to going out
    // in the middle of going in)

    return toAnimationTask(
      anims
        .map((anim: NodeAnimation | (() => AnimationTask | undefined)) => {
          if (typeof anim === 'function') {
            // Note that we don't have the locking behavior here; we can't
            // because we don't know what the preemptively unlock. Users must
            // manually do locking if they want
            return anim();
          }

          const task = anim.play({push: type === 'out'});

          // If it's an out animation, lock the node which will
          // prevent any other animations from running and
          // interrupting it
          if (type === 'out') {
            anim.lockNode();
          }
          return task;
        })
        .filter(Boolean) as AnimationTask[],
    );
  }

  prepare(type: 'in' | 'out'): void {
    // Never fire thet same event twice
    if (this._lastEventType !== type) {
      this.fire(type);
      this._lastEventType = type;
    }

    // Unlock all the nodes that out animations locks. If we are animating in,
    // we might be reversing an out animation and need to ensure all these nodes
    // are unlocked and can be animated again
    if (type === 'in') {
      (this.animations.get('out') || []).forEach((anim) => {
        if (typeof anim !== 'function') {
          anim.unlockNode();
        }
      });
    }
  }

  has(type: 'in' | 'out'): boolean {
    const anims = this.animations.get(type);
    return anims ? anims.length > 0 : false;
  }

  listen(handlers: ListenHandlers) {
    if (handlers.in) {
      this.eventHandlers.in.push(handlers.in);
    }
    if (handlers.out) {
      this.eventHandlers.out.push(handlers.out);
    }

    return () => {
      if (handlers.in) {
        this.eventHandlers.in = this.eventHandlers.in.filter(
          (h) => h !== handlers.in,
        );
      }
      if (handlers.out) {
        this.eventHandlers.out = this.eventHandlers.out.filter(
          (h) => h !== handlers.out,
        );
      }
    };
  }
}

export function makeTimeline(): Timeline {
  return new AnimationTimeline();
}

const noop = noopTimeline();
export const TimelineContext = React.createContext<Timeline>(noop);

export function useTimeline(timeline?: Timeline): Timeline {
  return useMemo(() => timeline || makeTimeline(), [timeline]);
}

export function useCurrentTimeline(): Timeline {
  return useContext(TimelineContext);
}

// This hook provides a declarative way to listen to in/out animation events,
// similar to the imperative `timeline.listen`. The difference is this ensures
// the events are always fired regardless of the lifecycle status
export function useTimelineListeners(events: ListenHandlers) {
  const timeline = useCurrentTimeline();

  // We want to always make sure the `in` and `out` listeners are fired. The
  // technique for ensuring this differs between them becauase we are relying on
  // subtle semantics of how effects are timed.
  //
  // For `in` listeners: We need to check if the timline has already fired the
  // `in` event. If that was the last event, we know it's already been run so we
  // need to manually invoke it.
  //
  // For `out` listeners: If we never receive an `out` event from the timeline,
  // we need to manually invoke it, and to do so we need to keep track of this
  // state. If our effect cleanup is invoked and the out handler hasn't been
  // called yet, that means we are unmounting forcibly and should call it
  // (`AnimatePresence` should have fired it by then)
  const inEventCalled = useRef(false);
  const latestOutEvent = useRef(events.out);
  const outEventCalled = useRef(false);

  useIsomorphicLayoutEffect(() => {
    latestOutEvent.current = events.out;

    if (
      '_lastEventType' in timeline &&
      (timeline as AnimationTimeline)._lastEventType === 'in' &&
      !inEventCalled.current
    ) {
      events.in?.();
      inEventCalled.current = true;
    }

    return timeline.listen({
      in: () => {
        outEventCalled.current = false;
        if (inEventCalled.current === false) {
          inEventCalled.current = true;
          events.in?.();
        }
      },
      out: () => {
        outEventCalled.current = true;
        inEventCalled.current = false;
        events.out?.();
      },
    });
  }, [events]);

  useIsomorphicLayoutEffect(() => {
    outEventCalled.current = false;

    return () => {
      if (!outEventCalled.current) {
        latestOutEvent.current?.();
      }
    };
  }, []);
}

type Props = {
  timeline: Timeline;
  children?: React.ReactNode;
};

export function TimelineProvider({timeline, children}: Props): JSX.Element {
  return (
    <TimelineContext.Provider value={timeline}>
      {children}
    </TimelineContext.Provider>
  );
}
