import type {StyleSheet, StyleSheetConstructor} from './StyleSheet';

export interface StyleSheetLayer {
  sheet: StyleSheet;
  order: string[];
  layers: Record<string, StyleSheetLayer>;
  name: string;
}

export function createStyleSheetLayer(
  name = '',
  sheetConstructor: StyleSheetConstructor,
  getPreviousSheet?: () => StyleSheet,
): StyleSheetLayer {
  return {
    sheet: new sheetConstructor(getPreviousSheet, name),
    order: [],
    layers: {},
    name,
  };
}

export function flushStyleSheetLayer(sheets: StyleSheetLayer): void {
  sheets.sheet.flush();
  sheets.order.forEach((segment) =>
    flushStyleSheetLayer(sheets.layers[segment]),
  );
}

/**
 * Recursively finds the last sheet for a specific layer.
 */
function getLastStyleSheetLayer(
  parentLayer: StyleSheetLayer,
  segment: string,
): StyleSheetLayer {
  const sheetLayer = segment && parentLayer.layers[segment];
  if (!sheetLayer) {
    return parentLayer;
  }

  const lastSegment = sheetLayer.order[sheetLayer.order.length - 1];
  return getLastStyleSheetLayer(sheetLayer, lastSegment);
}

export function getStyleSheetLayer(
  parentLayer: StyleSheetLayer,
  segment: string,
  sheetConstructor: StyleSheetConstructor,
  frozen: boolean,
): StyleSheetLayer {
  if (parentLayer.layers[segment]) {
    return parentLayer.layers[segment];
  }
  const name = parentLayer.name ? `${parentLayer.name}.${segment}` : segment;
  if (frozen) {
    throw new Error(
      `Cannot create a stylesheet "${name}" after stylesheets have been frozen.`,
    );
  }

  const lastSegment = parentLayer.order[parentLayer.order.length - 1];
  const getPreviousSheet = () =>
    getLastStyleSheetLayer(parentLayer, lastSegment).sheet;

  const segmentSheets = createStyleSheetLayer(
    name,
    sheetConstructor,
    getPreviousSheet,
  );
  parentLayer.layers[segment] = segmentSheets;
  parentLayer.order.push(segment);
  return segmentSheets;
}

export function walkStyleSheetLayer(
  layer: StyleSheetLayer,
  callback: (layer: StyleSheetLayer) => void,
): void {
  // NOTE: To align with the cascade layer spec, we should shift this to occur
  // after the loop (and update the insertion order of the <style> elements to
  // match as well).
  callback(layer);
  for (let i = 0; i < layer.order.length; i++) {
    const segment = layer.order[i];
    walkStyleSheetLayer(layer.layers[segment], callback);
  }
}
