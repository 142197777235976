import {configureStyle} from '../style';
import {isConditionalObject, HashMap} from '../util';
import {
  forEachToken,
  getTokenDefaultValue,
  getTokenId,
  serializeCustomProperty,
} from './token';
import type {Token} from './types';
import type {Style} from '../style';
import type {ConditionalObject} from '../util';

// TODO(koop): Update this?
export const TOKEN_PROVIDER_CLASS_NAME = 'sn-token-provider';
const TOKEN_DEFAULT_GLOBAL_SELECTOR = `:root, :host, .${TOKEN_PROVIDER_CLASS_NAME}`;

function setToken(
  property: string,
  value: string | Record<string, string>,
  set: Style.PluginAPI,
): void {
  if (typeof value === 'object') {
    Object.keys(value).forEach((condition) => {
      set.media(condition, () => {
        set.property(property, value[condition]);
      });
    });
  } else {
    set.property(property, value);
  }
}

// TODO(koop): Reset with stylesheets and hydrate on SSR
const hashMap = new HashMap({
  toString(id) {
    return `t${id.toString(36)}`;
  },
});

const providerClass = TOKEN_PROVIDER_CLASS_NAME;
function getTokenOverrideSelector(tokenClass: string) {
  return `&&&&&.${providerClass}, & .${providerClass}:not(& .${tokenClass} .${providerClass})`;
}

function setTokens(
  declarations: ConditionalObject<string>,
  set: Style.PluginAPI,
  isOverride: boolean,
): void {
  // Ensure that token styles are batched, even when they're inserted on the
  // atomic layer.
  set.property('tokens', () => {
    const properties = Object.keys(declarations);
    for (let i = 0; i < properties.length; i++) {
      const property = properties[i];
      const value = declarations[property];
      if (isOverride) {
        const id = property.slice(property.lastIndexOf('-') + 1);
        const tokenClass = hashMap.get(id);
        set.addClass(tokenClass);

        const selector = getTokenOverrideSelector(tokenClass);
        set.selector(selector, () => {
          setToken(property, value, set);
        });
      } else {
        setToken(property, value, set);
      }
    }
  });
}

const tokenCss = configureStyle({
  tokens: (declarations: ConditionalObject<string>, set: Style.PluginAPI) => {
    setTokens(declarations, set, false);
  },
  tokenOverrides: (
    declarations: ConditionalObject<string>,
    set: Style.PluginAPI,
  ) => {
    setTokens(declarations, set, true);
  },
});

function createTokenDeclarations(
  map: Token.OverrideMap,
): ConditionalObject<string> {
  const declarations = {} as ConditionalObject<string>;
  // We use for-in to include properties in the OverrideMap's prototype chain
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in map) {
    const value = map[key];
    const customProperty = serializeCustomProperty(key);
    if (isConditionalObject(value)) {
      const conditions = Object.keys(value);
      declarations[customProperty] = {};
      const declaration = declarations[customProperty] as Record<
        string,
        string
      >;
      conditions.forEach((condition) => {
        // as mentioned above, we want to copy over the entire object
        // nosemgrep: prototype-pollution-assignment
        declaration[condition] = `${value[condition]}`;
      });
    } else {
      declarations[customProperty] = `${value}`;
    }
  }
  return declarations;
}

/**
 * Token override styles are always inserted into the atomic layer. This ensures
 * that they will always override the token default styles, which are inserted
 * on the global layer.
 */
export function createTokenOverrideStyles(
  map: Token.OverrideMap,
): Style.Intent {
  return tokenCss({tokenOverrides: createTokenDeclarations(map)});
}

export function createTokenDefaultStyles(
  tokens: Token.AbstractMap,
): Style.Intent {
  const map = {} as Token.OverrideMap;
  forEachToken(tokens, (value) => {
    map[getTokenId(value)] = getTokenDefaultValue(value);
  });
  return tokenCss.global({
    [TOKEN_DEFAULT_GLOBAL_SELECTOR]: {
      tokens: createTokenDeclarations(map),
    },
  });
}
