/**
 * B+ tree
 * https://en.wikipedia.org/wiki/B%2B_tree
 */
export enum NodeType {
  Branch,
  Leaf,
}

type BranchNode<T, S> = {
  type: NodeType.Branch;
  values: Node<T, S>[];
  summary: S;
};

type LeafNode<T, S> = {
  type: NodeType.Leaf;
  values: T[];
  summary: S;
};

type Node<T, S> = BranchNode<T, S> | LeafNode<T, S>;

type Compare<A, B = A> = (a: A, b: B) => number;

type MapReduce<I, O> = {
  map: (input: I) => O;
  reduce: (outputs: O[]) => O;
};

type TreeOptions<T, S = void> = {
  branchingFactor: number;
  compare: Compare<T>;
  summarize?: MapReduce<T, S>;
};

function binarySearch<V, K = V>(
  values: V[],
  value: K,
  compare: Compare<V, K>,
  findLast = false,
) {
  let low = 0;
  let high = values.length;

  while (low < high) {
    // eslint-disable-next-line no-bitwise
    const mid = (low + high) >>> 1;
    const current = values[mid];
    const result = compare(current, value);

    if (findLast ? result <= 0 : result < 0) {
      low = mid + 1;
    } else {
      high = mid;
    }
  }
  return high;
}

function last<T>(array: T[]): T | void {
  return array[array.length - 1];
}

export function isBranchNode<T, S>(node: Node<T, S>): node is BranchNode<T, S> {
  return node.type === NodeType.Branch;
}

export function isLeafNode<T, S>(node: Node<T, S>): node is LeafNode<T, S> {
  return node.type === NodeType.Leaf;
}

function maxValue<T, S>(node: Node<T, S>): T {
  switch (node.type) {
    case NodeType.Branch:
      return maxValue(last(node.values) as Node<T, S>);
    case NodeType.Leaf:
      return last(node.values) as T;
    default:
      throw new Error('Unrecognized node type');
  }
}

function binarySearchNode<T, S>(
  node: Node<T, S>,
  tree: Tree<T, S>,
  data: T,
  inclusive = false,
): number {
  if (isBranchNode(node)) {
    return binarySearch(node.values, data, tree.compareNode, inclusive);
  } else if (isLeafNode(node)) {
    return binarySearch(node.values, data, tree.compare, inclusive);
  }
  throw new Error('Unrecognized node type');
}

function summarize<T, S>(
  node: Node<T, S>,
  tree: Tree<T, S>,
  values = node.values,
): S {
  if (!tree.summarize) {
    return undefined as unknown as S;
  }
  const toSummary = isBranchNode(node)
    ? ({summary}: BranchNode<T, S>) => summary
    : tree.summarize.map;
  return tree.summarize.reduce(
    values.map(toSummary as (input: Node<T, S> | T) => S),
  );
}

function updateSummary<T, S>(node: Node<T, S>, tree: Tree<T, S>) {
  if (tree.summarize) {
    node.summary = summarize(node, tree);
  }
}

function updateSummaryRecursive<T, S>(
  data: T,
  node: Node<T, S>,
  tree: Tree<T, S>,
) {
  if (!tree.summarize) {
    return;
  }
  if (isBranchNode(node)) {
    const index = binarySearchNode(node, tree, data);
    updateSummaryRecursive(data, node.values[index], tree);
  }
  updateSummary(node, tree);
}

function splitNode<T, S>(
  node: Node<T, S>,
  childNode: Node<T, S>,
  tree: Tree<T, S>,
  index: number,
): void {
  if (childNode.values.length > tree.maxNodeLength) {
    // Create a new node, splitting at the min node length
    const splitNode = {
      ...childNode,
      values: childNode.values.slice(tree.minNodeLength),
    } as Node<T, S>;
    node.values.splice(index + 1, 0, splitNode);

    // Remove all values after the split point from the original node
    childNode.values.length = tree.minNodeLength;

    updateSummary(childNode, tree);
    updateSummary(splitNode, tree);
  }
}

function insertNode<T, S>(data: T, node: Node<T, S>, tree: Tree<T, S>): void {
  const index = binarySearchNode(node, tree, data, true);
  if (isLeafNode(node)) {
    node.values.splice(index, 0, data);
    updateSummary(node, tree);
    return;
  } else if (!isBranchNode(node)) {
    throw new Error('Unrecognized node type');
  }

  const isLastIndex = index === node.values.length;
  const childNode = node.values[isLastIndex ? index - 1 : index];
  insertNode(data, childNode, tree);
  splitNode(node, childNode, tree, index);
  updateSummary(node, tree);
}

function removeNode<T, S>(data: T, node: Node<T, S>, tree: Tree<T, S>): void {
  const index = binarySearchNode(node, tree, data);
  if (isLeafNode(node)) {
    if (tree.compare(data, node.values[index]) === 0) {
      node.values.splice(index, 1);
      updateSummary(node, tree);
    }
    return;
  } else if (!isBranchNode(node)) {
    throw new Error('Unrecognized node type');
  }

  const childNode = node.values[index];
  removeNode(data, childNode, tree);

  const currentLength = childNode.values.length;
  if (currentLength < tree.minNodeLength) {
    const nextNode = node.values[index + 1] as Node<T, S>;
    const prevNode = node.values[index - 1] as Node<T, S>;
    const nextLength = nextNode?.values.length;
    const prevLength = prevNode?.values.length;

    let borrowed = false;
    if (currentLength === tree.minNodeLength - 1) {
      // First, try to borrow from an adjacent node
      if (nextNode && nextLength > tree.minNodeLength) {
        childNode.values.push(nextNode.values.shift() as Node<T, S>);
        updateSummary(childNode, tree);
        updateSummary(nextNode, tree);
        borrowed = true;
      } else if (prevNode && prevLength > tree.minNodeLength) {
        childNode.values.unshift(prevNode.values.pop() as Node<T, S>);
        updateSummary(prevNode, tree);
        updateSummary(childNode, tree);
        borrowed = true;
      }
    }

    if (!borrowed) {
      // Otherwise, merge nodes if necessary
      if (nextNode && nextLength + currentLength <= tree.maxNodeLength) {
        childNode.values.push(...(nextNode.values as (Node<T, S> & T)[]));
        node.values.splice(index + 1, 1);
        updateSummary(childNode, tree);
      } else if (prevNode && prevLength + currentLength <= tree.maxNodeLength) {
        prevNode.values.push(...(childNode.values as (Node<T, S> & T)[]));
        node.values.splice(index, 1);
        updateSummary(prevNode, tree);
      }
    }
  }

  updateSummary(node, tree);
}

export class Tree<T, S = undefined> {
  minNodeLength: number;

  maxNodeLength: number;

  compare: Compare<T>;

  compareNode: Compare<Node<T, S>, T>;

  root: Node<T, S>;

  summarize: MapReduce<T, S> | void;

  constructor({branchingFactor, compare, summarize}: TreeOptions<T, S>) {
    this.compare = compare;
    this.compareNode = (a, b) => compare(maxValue(a), b);
    this.summarize = summarize;
    this.maxNodeLength = branchingFactor;
    this.minNodeLength = Math.ceil(branchingFactor / 2);
    this.root = {
      type: NodeType.Leaf,
      values: [],
      summary: undefined as unknown as S,
    };
    updateSummary(this.root, this);
  }

  add(data: T): void {
    insertNode(data, this.root, this);

    // When the root is at the max node length, increase the height of the tree.
    // The generic insert method will then handle splitting the node.
    if (this.root.values.length > this.maxNodeLength) {
      this.root = {
        type: NodeType.Branch,
        values: [this.root],
        summary: undefined as unknown as S,
      };
      splitNode(this.root, this.root.values[0], this, 0);
      updateSummary(this.root, this);
    }
  }

  remove(data: T): void {
    removeNode(data, this.root, this);

    // If there's only one node remaining in a root branch node, we can decrease
    // the height of the tree.
    if (this.root.values.length === 1 && isBranchNode(this.root)) {
      this.root = this.root.values[0];
      updateSummary(this.root, this);
    }
  }

  has(data: T): boolean {
    let node = this.root;
    while (node) {
      const index = binarySearchNode(node, this, data);
      if (isBranchNode(node)) {
        node = node.values[index];
      } else {
        return this.compare(data, node.values[index]) === 0;
      }
    }
    return false;
  }

  update(data: T): void {
    updateSummaryRecursive(data, this.root, this);
  }

  summary(data: T, inclusive = false): S {
    if (!this.summarize) {
      return undefined as unknown as S;
    }
    let node = this.root;
    const summaries = [] as S[];

    while (node) {
      const index = binarySearchNode(node, this, data, inclusive);
      summaries.push(
        index === node.values.length
          ? node.summary
          : summarize(node, this, node.values.slice(0, index)),
      );
      if (isBranchNode(node)) {
        node = node.values[index];
      } else {
        break;
      }
    }
    return this.summarize.reduce(summaries);
  }
}

type NestedArray<T> = T[] | NestedArray<T>[];

function nodeToArray<T, S>(node: Node<T, S>): NestedArray<T> {
  if (isBranchNode(node)) {
    return node.values.map(nodeToArray);
  } else if (isLeafNode(node)) {
    return node.values;
  }
  throw new Error('Invalid node');
}

export function treeToArray<T, S>(tree: Tree<T, S>): NestedArray<T> {
  return nodeToArray(tree.root);
}
