import {riskFormAnalyticsAllowList} from '@stripe-internal/risk-form';

const riskTelemetryAnalyticsParams: Record<string, true> = {};

riskFormAnalyticsAllowList.forEach((key) => {
  riskTelemetryAnalyticsParams[key] = true;
});

const arkAnalyticsParams = {
  ark_version: true,

  // IDs
  id: true,
  file_id: true,
  person_id: true,
  entity_token: true,
  object_id: true,

  // Errors
  request_id: true,
  error: true,
  error_codes: true,
  validation_errors: true,
  previous_validation_errors: true,
  error_expected: true,
  error_path: true,
  error_muted: true,

  // Network
  network_action_id: true,
  network_duration: true,
  network_request_id: true,
  network_status: true,
  network_trace_id: true,

  // Operation
  operation_deduplicate: true,
  operation_prefetch: true,
  operation_prefetch_hit: true,

  // Account information
  country: true,
  capabilities: true,
  business_type: true,
  business_structure: true,
  company_structure: true,
  industry: true,
  tos: true,
  requirements: true,
  outstanding_requirements: true,
  section_outstanding_requirements: true,
  connected_merchant: true,
  client_application_id: true,
  terms_of_service: true,
  unsupportedRequirements: true,

  // Account traits
  hasStandardDashboardAccess: true,
  hasExpressDashboardAccess: true,
  stripeOwnsLossLiability: true,
  stripeOwnsPricing: true,
  stripeOwnsOnboarding: true,

  // Flow
  previous_composition_name: true,
  composition_name: true,
  composition_path: true,
  section_name: true,
  section_view: true,
  flow: true,
  step: true,
  externalStepName: true,
  can_proceed: true,
  is_mobile: true,
  flow_intent: true,
  flow_satisfaction_criteria: true,
  collect_future_requirements: true,
  api: true,
  frontend: true,
  ark_fields: true,
  current_requirements: true,
  current_future_requirements: true,
  form_id: true,
  field: true,

  // Document uploads
  field_name: true,
  selected_document_type: true,
  upload_source: true,
  blur_score: true,
  darkness_score: true,
  blur_time_millis: true,
  darkness_time_millis: true,

  // Flags
  is_auto_request_afterpay_capab_enabled: true,
  is_auto_request_affirm_capab_enabled: true,
  is_auto_request_link_capab_enabled: true,
  is_auto_request_us_bank_account_capab_enabled: true,
  is_auto_request_cashapp_capab_enabled: true,
  is_auto_request_apple_pay_later_capab_enabled: true,

  // Experiments
  experiment_retrieved: true,
  experiment_treatment_group: true,
  is_in_preloaded: true,
  experiment_checked_by: true,
  dashboard_type: true, // used by the statement_descriptor_llm_suggestions and statement_descriptor_llm_suggestions_aa experiments

  // External account form
  bank_account_form_initial_country: true,
  bank_account_form_type: true,
  bank_account_form_use_case: true,
  external_account_form_use_case: true,
  external_account_currency: true,
  external_account_country: true,
  merchant_default_currency: true,

  // Capital application parameters
  verification_status: true,
  verification_status_count: true,
  verification_previously_failed: true,
  synchronous_verification_enabled: true,

  // Shadow Test
  original: true,
  new: true,
  difference: true,
};

export const submerchantAnalyticsParams = {
  // This list controls the dimensions that are sent through analytics. If the parameter is not on this list, it will be [Redacted]
  // https://trailhead.corp.stripe.com/docs/ark/analytics#adding-properties
  // Note that this allows the parameter to go to AEL, however to pipe it through for realtime monitoring (detectors, grafana) it needs
  // to be added to https://go/ael-config
  connectElement: true,
  api_result: true,
  queryKey: true,
  livemode: true,
  duration: true,
  platform_id: true,
  connected_account_id: true,
  stripe_email_id: true,
  stripe_target_component: true,
  connectionEffectiveType: true,
  platformHostName: true,
  connectJsPath: true,
  type: true,
  htmlAttributes: true,
  loadedFontFamilies: true,
  browserSecureContext: true,
  platformOrigin: true,
  specifiedFontFamiliesCount: true,
  release: true,
  api_response_code: true,
  api_request_url: true,
  api_request_method: true,
  component: true,
  networkDuration: true,
  operationDeduplicated: true,
  page: true,
  runtime_overhead: true,
  operation_name: true,

  // Locale metadata
  attemptedLocale: true,
  matchedLocale: true,
  localeSource: true,
  localeMatch: true,

  // connectJsOptions metadata
  connectJsOptions: true,
  hostApp: true,

  // component attributes
  url: true,
  serviceAgreement: true,

  // Error/debugging fields
  stripeRequestId: true,
  stripeErrorRawType: true,
  stripeStatusCode: true,
  stripeStatusCodeRange: true,
  stripeErrorCode: true,
  stripeErrorMessage: true,
  teamName: true,
  error_message: true,
  error_stack: true,
  component_stack: true,
  service: true,
  project: true,
  errorCode: true,
  errorMessage: true,
  errorPath: true,
  errorExpected: true,
  errorMuted: true,
  errorSource: true,
  operationDeduplicate: true,
  operationName: true,
  operationPrefetch: true,
  networkActionId: true,
  networkRequestId: true,
  networkTraceId: true,
  networkStatus: true,
  version: true,
  setter: true,
  typeOfValue: true,
  jsonStringifiedValue: true,

  // Temporal, for testing purposes only
  _tdr_id: true,

  // connectjs sdk params
  sdk: true,
  sdkVersion: true,
  reactSdkVersion: true,

  // Mobile sdk params
  mobileSdk: true,
  mobileSdkVersion: true,
  mobileSdkBundleAppId: true,

  // Inherited font family
  inheritedFontFamily: true,

  // Custom fonts
  fontCount: true,
  fontLoadTime: true,
  fontCssSrc: true,
  fontSrc: true,
  platformLoadedFont: true,
  iframeLoadedFont: true,
  availableInIframe: true,

  // Payout Schedule Form
  account_id: true,
  payout_setting_schedule_preference: true,
  payout_setting_schedule_interval: true,

  // Payouts
  payoutMethod: true,

  // Capital embedded components
  layoutMode: true,
  linkingScenario: true,

  // app-ui-extension-runtime
  sdk_version: true,
  app_id: true,
  app_version: true,
  platform: true,
  app_channel: true,
  component_name: true,
  tracked_props: true,

  ...arkAnalyticsParams,
  ...riskTelemetryAnalyticsParams,
};
