import {message} from '@sail/intl';
import type {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';
import {ComponentOverrideFlag} from '../../../utils/flags/flags';

const messages = {
  errorText: message({
    id: 'connectEmbed.instantPayouts.errorBoundary.text',
    defaultMessage:
      'Your money is secure, but your payments information cannot be loaded here.',
    description:
      'Message shown when an unhandled instant payouts error is caught',
  }),
};

export const InstantPayoutsConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "instantPayoutsExperience" */ '../../../components/InstantPayouts/InstantPayoutsContainer'
    ),
  messageOverrides: messages,
  releaseState: 'preview',
  htmlEvents: ['instantpayoutcreated'],
  enabledKey: 'instant_payouts',
  enabledOverrideFlag: ComponentOverrideFlag.instant_payouts,
  componentControlledBackground: true,
  sentryTeam: 'connect_marketplaces',
  teamOwner: 'connect-integrations',
  description: 'Allows performing instant payouts for an account.',
  docSitePath: '/connect/supported-embedded-components/instant-payouts',
  syntheticTestInfo: {
    testUrl: 'https://amp.corp.stripe.com/synthetics/us-west-2/instant-payouts',
    rcTestUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/sus-ip-iframe-rc',
  },
  deprecated: {
    deprecatedMessage:
      'The instant payouts component is deprecated. Please use the balances https://docs.corp.stripe.com/connect/supported-embedded-components/balances component instead.',
  },
};
