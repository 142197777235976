import {$hasClassName, $type} from './constants';
import type {Style} from './types';

export function makeBatchedStyles(
  value: Record<string, unknown>,
  hasClassName = true,
): Style.BatchedStyles {
  const styles = value as Style.BatchedStyles;
  styles[$type] = 'batched';
  styles[$hasClassName] = hasClassName;
  return styles;
}

export function isBatchedStyles(value: unknown): value is Style.BatchedStyles {
  return (value as Style.BatchedStyles)?.[$type] === 'batched';
}

export function isMedia(key: string): boolean {
  return (
    key.startsWith('@media') ||
    key.startsWith('@supports') ||
    key.startsWith('@container')
  );
}

const selectorPattern = /^[:[.]/;

export function isSelector(key: string): boolean {
  return selectorPattern.test(key) || key.indexOf('&') !== -1;
}

export function addClassName(
  props: {className?: string},
  className: string,
): void {
  props.className = props.className
    ? `${props.className} ${className}`
    : className;
}
