import {assignStyleProperty} from './assignStyle';
import {$classes, $deps} from './constants';
import {createResetStyles} from './createResetStyles';
import {serializeKey, nestSelector} from './serialize';
import {createStyleIntent} from './StyleIntent';
import {isBatchedStyles, isMedia, isSelector, makeBatchedStyles} from './util';
import type {Style} from './types';
import {hash} from '../util';

const PROPERTY_FALLBACK = '*';

export function configureCreateStyle<
  Properties extends Style.Properties,
  Input extends object,
>(
  id: string,
  properties: Properties,
  plugins: Style.Plugins,
  mode: 'atomic' | 'batched' | 'global',
): (input: Input) => Style.Intent {
  const isOutputBatched = mode === 'batched' || mode === 'global';
  const salt = id + mode;

  return createStyleIntent(
    mode === 'global' ? 'global' : 'atomic',
    (input: Input) => serializeKey(input, salt),
    (input: Input) => {
      let processed = false;
      const styles = {} as Style.AtomicStyles;
      if (isOutputBatched) {
        makeBatchedStyles(styles, mode !== 'global');
      }

      return () => {
        if (processed) {
          return styles;
        }
        processed = true;

        const activePlugins = {} as Record<string, boolean>;
        let activeStyleRoot = styles;
        let activeStyles = styles;
        let activeMedia = '';
        let activeSelector = '&';

        function setMediaAndSelector(
          media: string,
          selector: string,
          callback: () => void,
        ) {
          const initialMedia = activeMedia;
          const initialStyles = activeStyles;
          const initialSelector = activeSelector;

          activeSelector = selector;
          activeMedia = media;

          if (isBatchedStyles(activeStyleRoot)) {
            if (activeMedia) {
              activeStyleRoot[activeMedia] ??= {};
              activeStyles = activeStyleRoot[activeMedia] as Style.AtomicStyles;
            } else {
              activeStyles = activeStyleRoot;
            }
          }
          activeStyles[activeSelector] ??= {};
          activeStyles = activeStyles[activeSelector] as Style.AtomicStyles;

          callback();

          activeMedia = initialMedia;
          activeStyles = initialStyles;
          activeSelector = initialSelector;
        }

        const set = {
          addClass(className: string) {
            styles[$classes] ??= [];
            const classes = styles[$classes];
            if (classes?.indexOf(className) === -1) {
              classes.push(className);
            }
          },
          dependency(intent: Style.Intent) {
            styles[$deps] ??= [];
            const deps = styles[$deps];
            if (deps?.indexOf(intent.key) === -1) {
              deps.push(intent.key);
            }
          },
          property(
            property: string | Style.CustomProperty,
            value: string | (() => void) | undefined,
            {skipPlugin = false}: {skipPlugin?: boolean} = {},
          ) {
            const propertyName =
              typeof property === 'string'
                ? property
                : property.intent.toString();

            const isUndefined = typeof value === 'undefined';
            if (!isUndefined && typeof property !== 'string') {
              set.dependency(property.intent);
            }

            const plugin =
              !skipPlugin &&
              !activePlugins[propertyName] &&
              plugins[propertyName];

            const isBatchedStyleRoot = isBatchedStyles(activeStyleRoot);

            // If `value` is a function, execute it and generate batched styles
            // to be associated with the property.
            let processedValue = value as string | Style.BatchedStyles;
            const isFunction = typeof value === 'function';
            if (isFunction) {
              processedValue = makeBatchedStyles({});
              const initialStyleRoot = activeStyleRoot;
              activeStyleRoot = processedValue;
              setMediaAndSelector(
                isBatchedStyleRoot ? '' : activeMedia,
                activeSelector,
                value,
              );
              activeStyleRoot = initialStyleRoot;
            }

            if (!isUndefined && !isFunction && plugin) {
              activePlugins[propertyName] = true;
              plugin(value, set, propertyName);
              activePlugins[propertyName] = false;
            } else if (isBatchedStyleRoot) {
              activeStyles[propertyName] = processedValue;
            } else {
              assignStyleProperty(
                activeStyles,
                propertyName,
                processedValue,
                activeMedia,
              );
            }
          },
          media(media: string, callback: () => void) {
            setMediaAndSelector(media, activeSelector, callback);
          },
          reset(resetStyles: Record<string, string>) {
            set.dependency(
              createResetStyles(resetStyles, activeSelector, activeMedia),
            );
          },
          selector(
            selector: string,
            callback: () => void,
            mode: 'nest' | 'overwrite' = 'nest',
          ) {
            setMediaAndSelector(
              activeMedia,
              mode === 'nest'
                ? nestSelector(activeSelector, selector)
                : selector,
              callback,
            );
          },
          var(property: Style.CustomProperty, fallback?: string): string {
            set.dependency(property.intent);
            const propertyName = property.intent.toString() as `--${string}`;
            return typeof fallback === 'undefined'
              ? `var(${propertyName})`
              : `var(${propertyName}, ${fallback})`;
          },
        };

        function processInput(input: Input) {
          const keys = Object.keys(input);
          for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = input[key as keyof typeof input];
            if (isMedia(key)) {
              // The key is a media query.
              if (activeSelector !== '&') {
                throw new Error(
                  'Media queries cannot be declared within selector style objects. Place your selector style object within a media query instead.',
                );
              }
              if (activeMedia) {
                throw new Error('Nested media query objects are unsupported');
              }
              activeMedia = key;
              processInput(value as unknown as Input);
              activeMedia = '';
            } else if (
              isOutputBatched ? styles === activeStyles : isSelector(key)
            ) {
              // The key is a selector.
              if (activeSelector !== '&') {
                throw new Error('Nested selector objects are unsupported');
              }
              const ampersandIndex = key.lastIndexOf('&');
              const hasAmpersand = ampersandIndex !== -1;
              if (hasAmpersand) {
                if (mode === 'global') {
                  throw new Error(
                    `Invalid selector "${key}". Global selectors cannot contain ampersands.`,
                  );
                } else if (ampersandIndex !== key.length - 1) {
                  throw new Error(
                    `Invalid selector "${key}". Atomic selectors with ampersands must have an ampersand as the last character.`,
                  );
                }
              }
              let selector = key;
              if (!isOutputBatched) {
                if (key.indexOf(',') !== -1) {
                  throw new Error(
                    `Invalid selector "${key}". Atomic selectors cannot contain a list of selectors.`,
                  );
                } else if (key.indexOf('#') !== -1) {
                  throw new Error(
                    `Invalid selector "${key}". Atomic selectors cannot contain id selectors.`,
                  );
                } else if (!hasAmpersand) {
                  selector = `${key}&`;
                }
              }

              set.selector(
                selector,
                () => {
                  processInput(value as unknown as Input);
                },
                'overwrite',
              );
            } else {
              // The key is a property.
              const property = properties[key] || properties[PROPERTY_FALLBACK];

              if (property) {
                const result = property(value, set, key);
                if (result != null) {
                  set.property(key, result);
                }
              } else if (process.env.NODE_ENV !== 'production') {
                throw new Error(`Missing style property "${key}"`);
              }
            }
          }
        }

        processInput(input);

        return styles;
      };
    },
  );
}

/**
 * Creates a custom css utility with support for the provided css properties and plugins.
 *
 * @see https://sail.stripe.me/apis/configurestyle/
 */
export function configureStyle<Properties extends Style.Properties>(
  properties: Properties,
  plugins: Style.Plugins = {},
): Style.Create<Properties> {
  type Input = Style.Input<Style.InputShape<Properties>>;
  type GlobalInput = Style.GlobalInput<Style.InputShape<Properties>>;

  const id = hash(
    Object.keys(properties).toString() + Object.keys(plugins).toString(),
  );

  const createStyles = configureCreateStyle<Properties, Input>(
    id,
    properties,
    plugins,
    'atomic',
  ) as Style.Create<Properties>;

  createStyles.global = configureCreateStyle<Properties, GlobalInput>(
    id,
    properties,
    plugins,
    'global',
  );

  createStyles.properties = properties;
  createStyles.plugins = plugins;

  return createStyles;
}
