import {assignConditionalProperty, mergeConditionalProperty} from '../util';
import {isBatchedStyles, isSelector} from './util';
import type {Style} from './types';

function isMedia<T>(value: unknown): value is Record<string, T> {
  return typeof value === 'object' && !isBatchedStyles(value);
}

export function assignStyleProperty(
  styles: Style.AtomicStyles,
  property: string,
  value: string | Style.BatchedStyles,
  media = '',
): void {
  assignConditionalProperty(styles, property, value, media, isMedia);
}

export function assignStyle(
  target: Style.AtomicStyles,
  source: Style.AtomicStyles,
): Style.AtomicStyles {
  const keys = Object.keys(source);
  for (let i = 0; i < keys.length; i++) {
    const styles = target;
    const property = keys[i];
    const value = source[property];
    if (isSelector(property)) {
      const targetValue = styles[property];
      if (targetValue) {
        assignStyle(
          targetValue as Record<string, string | Record<string, string>>,
          value as Record<string, string | Record<string, string>>,
        );
      } else {
        styles[property] = {
          ...(value as Record<string, string | Record<string, string>>),
        };
      }
    } else {
      mergeConditionalProperty(styles, property, value, isMedia);
    }
  }
  return target;
}
