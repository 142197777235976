import * as React from 'react';
import {unstable_batchedUpdates as batchedUpdates} from 'react-dom';
import {useForceUpdate} from './useForceUpdate';
import {useIsomorphicLayoutEffect} from './useIsomorphicLayoutEffect';

const {
  createContext,
  forwardRef,
  useCallback,
  useContext,
  useImperativeHandle,
  useMemo,
} = React;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ContextTree {
  export type Context<T> = {
    staged: T;
    committed: T;
    parent?: Context<T>;
    status: 'staged' | 'committed';
    onUpdate: Set<() => void>;
    checkNeedsUpdate(): boolean;
  };

  export type ListenerMap = Record<string, Set<() => void>>;

  export type API<T, V> = {
    useContextValue(): T;
    useSelector<U>(selector: (t: T, subscribe: (key: string) => void) => U): U;
    Context: React.Context<V>;
  };
}

const Ref = forwardRef(function Ref(_props, ref) {
  useImperativeHandle(ref, () => ({}), []);
  return null;
});

const delimiter = '___';

function getKeys<T>(
  callback: (subscribe: (key: string) => void) => T,
): [T, string] {
  let keys = '';
  const result = callback((key: string) => {
    keys = keys ? `${keys}${delimiter}${key}` : key;
  });
  return [result, keys];
}

export function createContextTree<T, V = T>({
  assign,
  broadcast,
  create,
  keys: toKeys = Object.keys as (source: T) => string[],
  useValue = (v: V) => v as unknown as T,
}: {
  assign(target: T, source: T, keys: string[]): void;
  broadcast(target: T, source: T, keys: string[]): string[];
  create(source?: T): T;
  keys?: (source: T) => string[];
  useValue?: (value: V) => T;
}): ContextTree.API<T, V> {
  const ListenerContext = createContext<ContextTree.ListenerMap>({});
  const ValueContext = createContext<ContextTree.Context<T>>({
    status: 'committed',
    committed: create(),
    staged: create(),
    onUpdate: new Set(),
    checkNeedsUpdate() {
      return false;
    },
  });

  function useContextValue(): T {
    const context = useContext(ValueContext);
    const forceUpdate = useForceUpdate();

    useIsomorphicLayoutEffect(() => {
      context.onUpdate.add(forceUpdate);
      return () => {
        context.onUpdate.delete(forceUpdate);
      };
    }, []);

    const isStaged = context.status === 'staged';
    return isStaged ? context.staged : context.committed;
  }

  function useSelector<V>(
    selector: (t: T, subscribe: (key: string) => void) => V,
  ): V {
    const listenerMap = useContext(ListenerContext);
    const forceUpdate = useForceUpdate();

    const context = useContext(ValueContext);
    const isStaged = context.status === 'staged';

    const [value, keys] = getKeys((subscribe) =>
      isStaged
        ? selector(context.staged, subscribe)
        : selector(context.committed, subscribe),
    );

    useIsomorphicLayoutEffect(() => {
      if (context.checkNeedsUpdate()) {
        forceUpdate();
        return;
      }

      const callback = () => {
        const [committedValue, committedKeys] = getKeys((subscribe) =>
          selector(context.committed, subscribe),
        );
        if (!Object.is(value, committedValue) || keys !== committedKeys) {
          forceUpdate();
        }
      };

      const keyList = keys.split(delimiter);
      keyList.forEach((key) => {
        listenerMap[key] ??= new Set();
        listenerMap[key].add(callback);
      });
      return () => {
        keyList.forEach((key) => {
          listenerMap[key]?.delete(callback);
        });
      };
    }, [context, forceUpdate, keys, listenerMap, selector, value]);

    return value;
  }

  function Provider({
    children,
    value: valueProp,
  }: {
    children: React.ReactNode;
    value: V;
  }): JSX.Element {
    const value = useValue(valueProp);
    const forceUpdate = useForceUpdate();
    const listenerMap = useContext(ListenerContext);
    const inherited = useContext(ValueContext);
    const context = useMemo(
      () =>
        ({
          staged: create(inherited.staged),
          committed: create(inherited.committed),
          parent: inherited,
          status: inherited.status,
          onUpdate: new Set(),
          checkNeedsUpdate() {
            // eslint-disable-next-line react/no-this-in-sfc
            if (this.status === 'committed') {
              return false;
            }
            forceUpdate();
            return true;
          },
        } as ContextTree.Context<T>),
      [forceUpdate, inherited],
    );

    useIsomorphicLayoutEffect(() => {
      const propagate = () =>
        context.onUpdate.forEach((listener) => listener());
      inherited.onUpdate.add(propagate);
      return () => {
        inherited.onUpdate.delete(propagate);
      };
    }, [forceUpdate, inherited]);

    const keys = useMemo(
      () =>
        Array.from(new Set([...toKeys(context.committed), ...toKeys(value)])),
      [value, context],
    );

    useMemo(() => {
      assign(context.staged, value, keys);
      context.status = 'staged';
    }, [value, context, keys]);

    const commit = useCallback(
      (ref) => {
        // The callback will be called with an object when the component is
        // mounted and null when the component is unmounted.
        if (!ref) {
          return;
        }
        batchedUpdates(() => {
          if (context.parent?.checkNeedsUpdate()) {
            forceUpdate();
            return;
          }
          const changedKeys = broadcast(context.committed, value, keys);
          if (changedKeys.length) {
            context.onUpdate.forEach((listener) => listener());
          }

          assign(context.committed, value, keys);
          changedKeys.forEach((key) =>
            listenerMap[key]?.forEach((listener) => listener()),
          );

          context.status = 'committed';
        });
      },
      [value, context, keys, forceUpdate, listenerMap],
    );

    return (
      <ValueContext.Provider value={context}>
        <Ref ref={commit} />
        {children}
      </ValueContext.Provider>
    );
  }

  return {
    useContextValue,
    useSelector,
    Context: {Provider} as React.Context<V>,
  };
}
