import {
  MediaQueryObserver,
  type MediaQueryListener,
} from './MediaQueryObserver';

export class MediaQueryManager {
  private registry: Map<string, MediaQueryObserver>;

  constructor() {
    this.registry = new Map();
  }

  private getObserver(query: string): MediaQueryObserver {
    let observer = this.registry.get(query);

    if (!observer) {
      observer = new MediaQueryObserver(query);
      this.registry.set(query, observer);
    }

    return observer;
  }

  matchMedia(query: string): {matches: boolean} {
    const observer = this.getObserver(query);

    return {matches: observer.matches};
  }

  addQueryListener(query: string, listener: MediaQueryListener): () => void {
    const observer = this.getObserver(query);

    observer.subscribe(listener);

    return () => this.removeQueryListener(query, listener);
  }

  removeQueryListener(query: string, listener: MediaQueryListener): void {
    const observer = this.registry.get(query);

    if (observer?.unsubscribe(listener)) {
      this.registry.delete(query);
    }
  }
}

export const mediaQueryManager = new MediaQueryManager();
