import {$intent, $intents} from './constants';
import type {
  CreateIntent,
  DecorateIntent,
  Intent,
  IntentFn,
  IntentMap,
  IntentType,
} from './types';

export function isIntent(intent: unknown): intent is Intent {
  return intent == null ? false : !!(intent as Intent)[$intent];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const intentRegistry: Map<symbol, IntentType<any, any>> = new Map();

export function createIntentType<
  Input,
  State,
  Create extends IntentFn = <T>(input: Input) => Input & Intent<T>,
>(
  label: string,
  config: {
    add: (intent: Input, state?: State) => State;
    create?: CreateIntent<Create>;
    merge: (target: State | undefined, source: State) => State;
  },
): Create & IntentType<Input, State> {
  const symbol = Symbol(label);

  function decorate<T = unknown>(obj: Input) {
    const intent = obj as Input & Intent<T>;
    Object.defineProperty(intent, $intent, {
      value: symbol,
      enumerable: false,
      writable: false,
    });
    return intent;
  }

  const create = config.create;
  const intentType = (
    create ? create(decorate as DecorateIntent) : decorate
  ) as IntentType<Input, State> & Create;

  intentType.add = config.add;
  intentType.merge = config.merge;
  intentType.symbol = symbol;

  intentType.isIntent = (intent: unknown): intent is Input =>
    intent == null ? false : (intent as Intent)[$intent] === symbol;

  intentRegistry.set(symbol, intentType);

  return intentType;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toIntentType = createIntentType<Intent<any>[], null>('toIntent', {
  add() {
    return null;
  },
  merge() {
    return null;
  },
});

export function toIntent<T = unknown>(
  intents: Intent<T>[],
): Intent<T>[] & Intent<T> {
  return toIntentType<T>(intents);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function createIntentMap<T extends {}>(
  value: T,
  source?: IntentMap,
): T & IntentMap {
  const obj = value as T & IntentMap;

  // TODO(koop): Make this lazy; this will save a bunch of memory
  obj[$intents] = source?.[$intents] ?? new Map();
  return obj;
}

export function getIntents<I, S>(
  type: IntentType<I, S>,
  obj: IntentMap,
): S | undefined {
  const intents = obj[$intents];
  return (intents ? intents.get(type.symbol) : undefined) as S | undefined;
}

export function deleteIntents<I, S>(
  type: IntentType<I, S>,
  obj: IntentMap,
): void {
  const intents = obj[$intents];
  if (intents) {
    intents.delete(type.symbol);
  }
}

export function forEachIntent<Props>(
  intents: (Intent<Props> | void | null | undefined | false)[],
  callback: (intent: Intent<Props>) => void,
): void {
  for (let i = 0; i < intents.length; i++) {
    const intent = intents[i];
    if (toIntentType.isIntent(intent)) {
      forEachIntent(intent, callback);
    } else if (intent) {
      callback(intent);
    }
  }
}

export function addIntent(obj: IntentMap, intent: Intent<unknown>): void {
  if (toIntentType.isIntent(intent)) {
    forEachIntent(intent, (intent) => addIntent(obj, intent));
    return;
  }

  const intents = obj[$intents];
  const intentSymbol = intent[$intent];
  const intentType = intentRegistry.get(intentSymbol);
  if (intentType) {
    const state = intents.get(intentSymbol);
    const result = intentType.add(intent, state);
    if (state !== result) {
      intents.set(intentSymbol, result);
    }
  }
}

export function mergeIntents(
  target: IntentMap,
  source: IntentMap,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  shouldMergeIntentType?: (intentType: IntentType<any, any>) => boolean,
): void {
  const targetIntents = target[$intents];
  const sourceIntents = source[$intents];
  const intentTypes = Array.from(sourceIntents.keys());
  for (let i = 0; i < intentTypes.length; i++) {
    const key = intentTypes[i] as unknown as symbol;
    const intentType = intentRegistry.get(key);
    if (
      intentType &&
      (shouldMergeIntentType ? shouldMergeIntentType(intentType) : true)
    ) {
      const targetState = targetIntents.get(key);
      const sourceState = sourceIntents.get(key);
      const result = intentType.merge(targetState, sourceState);
      if (targetState !== result) {
        targetIntents.set(key, result);
      }
    }
  }
}
