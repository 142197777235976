import {createIntentType} from '../intent';
import type {Intent} from '../intent';
import type {View} from './types';

interface DynamicIntentFunction<Props> {
  (props: Props): (View.Intent<Props> | DynamicIntent<Props>)[] | void;
}

export interface DynamicIntent<Props>
  extends DynamicIntentFunction<Props>,
    Intent<Props> {}

/**
 * Adds dynamic behaviors that act upon the value of a component’s props.
 *
 * @see https://sail.stripe.me/apis/dynamic
 */
export const dynamic = createIntentType<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  DynamicIntentFunction<any>,
  null,
  <Props = never>(intent: DynamicIntentFunction<Props>) => DynamicIntent<Props>
>('dynamic', {
  add() {
    return null;
  },
  merge() {
    return null;
  },
});
