import {message} from '@sail/intl';
import type {ConnectElementConfig} from '../ConnectElementList';
import {ComponentOverrideFlag} from '../../../utils/flags/flags';

const messages = {
  errorText: message({
    id: 'connectEmbed.payouts.errorBoundary.text',
    defaultMessage:
      'Your money is secure, but your payouts information cannot be loaded here.',
    description: 'Message shown when an unhandled payouts error is caught',
  }),
};

export type PayoutsComponentHtmlParams = Partial<Record<string, string>>;

export const PayoutsConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "payoutsExperience" */ '../../../components/Payouts/PayoutsV2/PayoutsV2'
    ),
  messageOverrides: messages,
  releaseState: 'ga',
  enabledKey: 'payouts',
  enabledOverrideFlag: ComponentOverrideFlag.payouts,
  sentryTeam: 'accounts_ui',
  teamOwner: 'connected-account-experiences-eng',
  description:
    'Displays the list of payouts, allows for manual payouts, instant payouts, and management of payout schedule.',
  docSitePath: '/connect/supported-embedded-components/payouts',
  syntheticTestInfo: {
    testUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/sus-payouts-iframe',
    rcTestUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/sus-payouts-iframe-rc',
  },
  applyMobileUIOverrides: true,
};
