import type {ConnectElementConfig} from '../ConnectElementList';

export const DebugComponentList: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "componentList" */ '../../../components/ComponentList/DebugComponentList'
    ),
  enabledKey: 'internal',
  releaseState: 'internal',
  ignoreThemingValues: false,
  sentryTeam: 'accounts_ui',
  teamOwner: 'connected-account-experiences-eng',
  description:
    'Documents the list of components, along with their setter methods.',
};
