import * as _animate from './animate';

export {
  createArrayIntentType,
  createIntentType,
  createMapIntentType,
  createObjectIntentType,
  createSetIntentType,
  forEachIntent,
  getIntents,
  isIntent,
  toIntent,
  provider,
} from './intent';
export type {Intent, IntentMap, IntentType, NotIntent} from './intent';
export {VERSION} from './version';
export type {NodeAnimation, Timeline} from './animate';

export * from './stylesheets';
export * from './util';

export {
  configureStyle,
  createCustomProperty,
  createKeyframes,
  getAnimationNames,
  StyleCache,
  scheduleStyleIntent,
  VIEW_CLASS,
  media,
} from './style';
export {
  DARK_MODE_CONDITION,
  addressTokens,
  assignConditionalTokens,
  assignTokens,
  createTokens,
  enumerateTokens,
  forEachToken,
  getTokenPath,
  getTokenId,
  getTokenById,
  isToken,
  mergeOverrideIntents,
  resolveToken,
  setStaticToken,
  tokenKeys,
  toStyleDictionaryFormat,
  toW3CTokenFormat,
  useTokenValue,
  usePortalTokenStyles,
  walkTokens,
} from './token';

export * from './view';

export type {Style} from './style';
export type {Token} from './token';
export type {SafeLinkOptions} from '@stripe-internal/safe-links';

export const animate = _animate;

export {AnimatePresence, AnimatePresenceProvider} from './animate';

declare global {
  /**
   * An example build experiment.
   */
  // const SAIL_EXAMPLE_EXPERIMENT: boolean | void;
}
