const eventRegExp = /^on[A-Z]/;
const cache: Record<string, boolean> = {};

export function isEventProperty(property: string): boolean {
  if (property in cache) {
    return cache[property];
  }
  const result = !!property.match(eventRegExp);
  cache[property] = result;
  return result;
}
