import {createElement} from 'react';
import {getIntents, provider} from '../intent';
import type {IntentMap} from '../intent';

export function runProviders(
  intentMap: IntentMap,
  element: JSX.Element | null,
): JSX.Element | null {
  const providers = getIntents(provider, intentMap);
  if (providers) {
    let currentElement: JSX.Element | null = element;
    providers.forEach(([Context, value]) => {
      currentElement = createElement(Context.Provider, {value}, currentElement);
    });
    return currentElement;
  }
  return element;
}
