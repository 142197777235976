import {createSetIntentType} from '../../intent';
import type {Intent, IntentMap} from '../../intent';

type OnRender<Props> = (
  props: Props & IntentMap,
) => void | (Intent | Intent<Props> | Partial<Props>)[];

export const onRender = createSetIntentType(
  'onRender',
  (decorate) =>
    <Props = never>(
      render: OnRender<Props>,
    ): OnRender<Props> & Intent<Props> => {
      return decorate(render);
    },
);
