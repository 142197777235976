export function splitBalanced(input: string, delimiter = ' '): string[] {
  const pattern = '([\\s\\S]*?)(?:(open)|(close)|(split)|$)'
    .replace('split', delimiter)
    .replace('open', '[\\(\\{\\[]')
    .replace('close', '[\\)\\}\\]]');
  const r = new RegExp(pattern, 'gi');
  const stack: string[] = [];
  const buffer: string[] = [];
  const results: string[] = [];
  input.replace(r, (match, word, open, close, split) => {
    if (open) {
      stack.push(open);
    } else if (close) {
      stack.pop();
    } else if (split ? !stack.length : !word) {
      buffer.push(word);
      results.push(buffer.join(''));
      buffer.length = 0;
      return '';
    }
    buffer.push(match);
    return '';
  });
  return results;
}
