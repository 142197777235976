import {isDevEnvironment} from './isDevEnvironment';
import {getDevLogger} from './getLogger';

const devLogger = getDevLogger();

export type ServiceEnvironment =
  | 'devbox'
  | 'tunnel'
  | 'qa'
  | 'preprod'
  | 'prod';

export const calculateDevboxPrefix = (): string => {
  const devboxPrefix = process.env.DEVBOX_QUALIFIER || process.env.QUALIFIER;
  if (devboxPrefix) {
    // Regular devboxes have a qualifier now
    // https://trailhead.corp.stripe.com/docs/devbox/working-with-services/new-all-devboxes-must-have-a-unique-qualifier
    return `${process.env.USER}-${devboxPrefix}-`;
  } else {
    // Some older devboxes don't, so we still support this
    return `${process.env.USER}-`;
  }
};

export const getTunnelUrl = (service: string): string =>
  `${process.env.USER}-${service}.tunnel.stripe.me`;

export const getDevboxUrl = (service: string): string =>
  `${calculateDevboxPrefix()}-${service}-mydev.dev.stripe.me`;

export const getTargetEnvironment = (
  targetEnvironment: ServiceEnvironment,
  environmentOverride: string | undefined = process.env.SERVICE_ENVIRONMENT,
  isDevelopmentBuild: boolean = isDevEnvironment,
): ServiceEnvironment => {
  // We only support changing target environment in devbox
  if (
    !isDevelopmentBuild ||
    targetEnvironment !== 'devbox' ||
    !environmentOverride
  ) {
    return targetEnvironment;
  }

  const lowercaseOverride = environmentOverride.toLowerCase();
  switch (lowercaseOverride) {
    case 'tunnel':
    case 'devbox':
    case 'qa':
    case 'preprod':
    case 'prod':
      return lowercaseOverride;
    default:
      // Unexpected value encountered
      devLogger.error(
        'Devbox only target override is not a recognized value',
        environmentOverride,
      );
      return targetEnvironment;
  }
};
