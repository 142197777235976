import type {ConnectElementConfig} from '../ConnectElementList';

export const TaxThresholdMonitoringConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "taxThresholdMonitoring" */
      '../../../components/Tax/ThresholdMonitoring'
    ),
  enabledKey: 'tax_threshold_monitoring', // Flag that will determine if the custom element should be rendered
  releaseState: 'preview',
  sentryTeam: 'tax_connect_embedded_components', // Enter your team's name - this is used for SFX reporting and sentry. See docs on how to set up: https://confluence.corp.stripe.com/display/OBS/Setting+up+Sentry+Reporting#SettingupSentryReporting-SettingupaSentryproject
  teamOwner: 'tax-connect',
  description:
    'Renders the threshold monitoring information for the account. Allows users to track their sales by tax jurisdiction.',
  // syntheticTestInfo: {
  //   testUrl:
  //     'https://amp.corp.stripe.com/synthetics/us-west-2/sus-tax-threshold-monitoring-iframe',
  // },
  docSitePath:
    '/connect/supported-embedded-components/tax-threshold-monitoring',
  customHtmlElementMethods: {
    setDisplayCountries: {
      type: 'SupplementalObject',
      objectToSet: 'displayCountries',
      isInternal: false,
      description:
        'Sets the list of countries that will be visible for threshold monitoring.',
    },
  },
};
