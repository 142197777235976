import {MessageChannel, fromMessagePort} from '@sail/rpc';
import rpc from 'src/internal/rpc/rpc';

import type {RpcClient, RpcServer} from '@sail/rpc';
import type {ObservabilityRpcMethods} from 'src/internal/rpc/rpc';

let defaultRpcInstance: {
  client: RpcClient<ObservabilityRpcMethods>;
  server: RpcServer;
};

export function getDefaultRpcInstance() {
  if (defaultRpcInstance) {
    return defaultRpcInstance;
  }

  const {port1, port2} = new MessageChannel();

  port1.start();
  port2.start();

  defaultRpcInstance = {
    client: rpc.initClient({
      transport: fromMessagePort(port1),
    }),

    server: rpc.initServer({
      transport: fromMessagePort(port2),
    }),
  };

  return defaultRpcInstance;
}
