import type {
  CollectBankAccountTokenResult,
  FinancialConnectionsSessionResult,
} from '@stripe/stripe-js';

// This function enriches the result of the mobile financial connections flow with an error object if the result is
// errored. This is necessary because the rawInput may not include the error object, which is expected by callers of the
// financial connections flow. The promise id is also removed from the result object.
export const processMobileFinancialConnectionsResult = (
  rawResult: (
    | FinancialConnectionsSessionResult
    | CollectBankAccountTokenResult
  ) & {id?: string},
): FinancialConnectionsSessionResult | CollectBankAccountTokenResult => {
  if (!rawResult.financialConnectionsSession) {
    return {
      financialConnectionsSession: undefined,
      token: undefined,
      error: rawResult.error,
    };
  } else {
    const result = {...rawResult};
    delete result.id;

    // If financialConnectionsSession is not undefined, we can safely assume that the result is not errored
    return {...result, error: undefined};
  }
};
