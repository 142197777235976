import {hash} from './util/hash';

/**
 * VERSION can have one of three values:
 * - whatever `process.env.SAIL_VERSION` is - this environment variable is used
 *   to allow multiple copies of the same version of Sail to coexist.
 * - 'JEST_VERSION' - when running in a test
 * - 'workspace' - when installed in a workspace context, which is the only way
 *   Sail is published as of 3/14/2025. this is the default value
 */
export const VERSION = (function () {
  try {
    if (process.env.SAIL_VERSION) {
      return process.env.SAIL_VERSION;
    }
  } catch {}

  if (process.env.NODE_ENV === 'test') {
    return 'JEST_VERSION';
  }

  return 'workspace';
})();

const MAJOR_VERSION_OFFSET = -36;

export function getVersionHash(version: string): string {
  switch (version) {
    case 'workspace':
      return 's-';
    case 'JEST_VERSION':
      return 's';
    default:
  }

  const semver = version.match(/^(\d+)\.(\d+)\.(\d+)$/);
  if (!semver) {
    return `h${hash(version)}`;
  }

  const major = Number(semver[1]);
  const minor = Number(semver[2]);
  const patch = Number(semver[3]);

  let prefix = (major + MAJOR_VERSION_OFFSET).toString(36);
  if (minor > 0 || patch > 0) {
    prefix += minor.toString(36);
  }
  if (patch > 0) {
    prefix += patch.toString(36);
  }

  return `s${prefix}`;
}

export const VERSION_HASH = getVersionHash(VERSION);
