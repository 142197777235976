import {useEffect, useRef} from 'react';
import type * as React from 'react';

// https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
export function usePreviousRef<T, I = void>(
  callback: () => T,
  initial: I = undefined as unknown as I,
): React.MutableRefObject<T | I> {
  const ref = useRef<T | I>(initial);
  useEffect(() => {
    ref.current = callback();
  });
  return ref;
}

export function usePrevious<T>(value: T): T | void {
  return usePreviousRef(() => value).current;
}

const $initial = Symbol('initial');

export function usePreviousIfEqual<T>(
  value: T,
  compare: (a: T, b: T) => boolean = Object.is,
): T {
  let result = value;
  const ref = usePreviousRef(() => result, $initial);

  const previous = ref.current;
  if (previous !== $initial && compare(previous, value)) {
    result = previous;
  }
  return result;
}
