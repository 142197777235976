import * as React from 'react';

type AnimatePresenceContextValue = {
  emptyRender?: React.ReactNode;
};

const AnimatePresenceContext = React.createContext<AnimatePresenceContextValue>(
  {emptyRender: undefined},
);

export function AnimatePresenceProvider({
  emptyRender,
  children,
}: {
  emptyRender?: AnimatePresenceContextValue['emptyRender'];
  children?: React.ReactNode;
}) {
  return (
    <AnimatePresenceContext.Provider value={{emptyRender}}>
      {children}
    </AnimatePresenceContext.Provider>
  );
}

export function useAnimatePresenceContext() {
  return React.useContext(AnimatePresenceContext);
}
