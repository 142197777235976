import {createIntentType} from './intent';
import type {
  CreateIntent,
  ExtractReturnType,
  Intent,
  IntentType,
} from './types';

export function createObjectIntentType<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  F extends (...args: any[]) => Intent & object,
>(
  label: string,
  create: CreateIntent<F>,
  assign: (
    target: ExtractReturnType<F>,
    source: ExtractReturnType<F>,
  ) => void = Object.assign,
): F & IntentType<ExtractReturnType<F>, ExtractReturnType<F>> {
  return createIntentType<ExtractReturnType<F>, ExtractReturnType<F>, F>(
    label,
    {
      add(intent, state = {} as ExtractReturnType<F>) {
        assign(state, intent);
        return state;
      },
      create,
      merge(target, source) {
        const result = target ?? ({} as ExtractReturnType<F>);
        assign(result, source);
        return result;
      },
    },
  );
}
