import {getIntents} from '../intent';
import {onRender} from '../deprecated/intentTypes';
import {runStyleIntents} from '../style';
import {runTokenIntents} from '../token';
import {Priority} from './constants';
import {runElementTransforms} from './element';
import {addIntentsToRevision} from './ViewRevision';
import type {View} from './types';

export function runDomIntents<Props>(revision: View.ViewRevision<Props>): void {
  if (process.env.NODE_ENV !== 'production') {
    const onRenders = getIntents(onRender, revision);
    if (onRenders) {
      throw new Error(
        '`onRender` intents are not supported on this view. Use `dynamic` intents instead.',
      );
    }
  }

  addIntentsToRevision(revision, runTokenIntents(revision), Priority.Normal);
  runElementTransforms(revision);
  runStyleIntents(revision);
}
