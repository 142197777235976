export type Environment = 'test' | 'development' | 'production';

const DEFAULT_ENVIRONMENT = 'production';

type Env = Environment | (string & NonNullable<unknown>);

const environment = (): Env => {
  let env = DEFAULT_ENVIRONMENT;

  try {
    env = process.env.NODE_ENV || DEFAULT_ENVIRONMENT;
  } catch (err) {
    // Do nothing.
  }

  return env;
};

export function getEnvEnvironment(): Env {
  return environment();
}

// For SSR, we only based the environment in the process.env variable.
function getHostname(): string | undefined {
  if (typeof globalThis !== 'undefined') {
    return globalThis?.location?.hostname;
  }

  if (typeof self !== 'undefined') {
    return self?.location?.hostname;
  }

  if (typeof window !== 'undefined') {
    return window?.location?.hostname;
  }

  return undefined;
}

function isUserHostname(): boolean {
  const hostname = getHostname();

  return !!(
    process.env.USER &&
    hostname &&
    hostname.includes(String(process.env.USER))
  );
}

function isStripeInternalHostname(): boolean {
  const hostname = getHostname();

  if (!hostname) {
    return false;
  }

  return (
    /\.stripe$/.test(hostname) ||
    /\.stripe\.me$/.test(hostname) ||
    /\bcorp\.stripe\.com$\b/.test(hostname) ||
    /\bedge-\b.*\.stripe\.com$/.test(hostname) ||
    /\bpreprod\b.*\.stripe\.com$/.test(hostname) ||
    /\bqa\b.*\.stripe\.com$/.test(hostname) ||
    /\bqa\b.*\.stripecdn\.com$/.test(hostname) ||
    /\preprod\b.*\.stripecdn\.com$/.test(hostname) ||
    /^127(?:\.\d+){3}$/.test(hostname) ||
    /^localhost$/.test(hostname)
  );
}

function isDevEnvironment(): boolean {
  return (
    !['production', 'test'].includes(getEnvEnvironment()) ||
    isStripeInternalHostname() ||
    isUserHostname()
  );
}

function isTestEnvironment(): boolean {
  return getEnvEnvironment() === 'test';
}

export default function getEnvironment(): Env {
  if (isTestEnvironment()) {
    return 'test';
  }

  return isDevEnvironment() ? 'development' : getEnvEnvironment();
}
