import type * as React from 'react';

import type {Timeline} from './timeline';

// The types below are WIP and will be clarified further as we improve the
// public API

export type AnimationOptions = {
  delay?: number;
  duration?: number;
  easing?: string;
  disabled?: boolean;
};

export type Direction = 'normal' | 'reverse';

export type PropertyValue = string | number | undefined;
export type KeyframeStyles = Record<string, PropertyValue>;
export type KeyframeOptions = AnimationOptions & {
  reverse?: 'keyframes' | 'effects';
};

export type KeyframeDescription = Omit<
  React.CSSProperties,
  keyof AnimationOptions
> &
  KeyframeOptions;

// This global animation state provides us with detailed info about the
// currently executing animations

type NodeAnimationState = {
  activeAnimations: Map<string, Animation | null>;
  appliedStack: Array<{id: number; from: KeyframeStyles}>;
  locked: boolean;
};
const animationStates = new WeakMap<Element, NodeAnimationState>();

type AnimationData = {
  from: PropertyValue;
  to: PropertyValue;
  direction: Direction;
  playId: number;
};
const animationData = new WeakMap<Animation, AnimationData>();

function checkVisibility(node: Element) {
  const {body} = node.ownerDocument;
  if (typeof body.checkVisibility === 'function') {
    return body.checkVisibility();
  } else {
    const rect = body.getBoundingClientRect();
    return rect.width > 0 && rect.height > 0;
  }
}

function getAnimationState(node: Element) {
  let state = animationStates.get(node);
  if (state === undefined) {
    state = {
      activeAnimations: new Map(),
      appliedStack: [],
      locked: false,
    };
    animationStates.set(node, state);
  }
  return state;
}

function getCurrentAnimation(node: Element, prop: string): Animation | null {
  return getAnimationState(node).activeAnimations.get(prop) || null;
}

function getFinalProperty(node: Element, prop: string): PropertyValue | null {
  const state = getAnimationState(node);
  const animation = state.activeAnimations.get(prop);
  if (animation) {
    const data = animationData.get(animation);
    if (data) {
      return data.direction === 'reverse' ? data.from : data.to;
    }
  }
  return null;
}

function setLockState(node: Element, flag: boolean) {
  const state = getAnimationState(node);
  state.locked = flag;
}

function getLockState(node: Element) {
  return getAnimationState(node).locked;
}

function setCurrentAnimation(
  node: Element,
  prop: string,
  animation: Animation | null,
  data?: AnimationData,
) {
  const state = getAnimationState(node);
  state.activeAnimations.set(prop, animation);

  if (animation && data) {
    animationData.set(animation, data);
  }
}

function cancelCurrentAnimation(node: Element, prop: string) {
  const anim = getCurrentAnimation(node, prop);
  if (anim) {
    anim.cancel();
  }
  setCurrentAnimation(node, prop, null);
}

// The next two functions implement an animation stack. This is a critical piece
// in the system for transitions, and allows deriving the right style props to set
// when adding and removing states. Will document more later, for now you can
// see a bunch of the edge case here:
// https://excalidraw.com/#json=5692055233757184,dSIR11OVksqSlFMlKlQiEg
export function removeAnimation(
  node: Element,
  id: number,
): KeyframeStyles | null {
  const stack = getAnimationState(node).appliedStack;
  const index = stack.findIndex((anim) => anim.id === id);
  if (index === -1) {
    return null;
  } else {
    const [{from}] = stack.splice(index, 1);
    const props = Object.keys(from);

    for (let i = index; i < stack.length; i++) {
      const desc = stack[i].from;

      props.forEach((prop) => {
        if (prop in desc) {
          desc[prop] = from[prop];
          delete from[prop];
        }
      });
    }

    return from;
  }
}

export function pushAnimation(
  node: Element,
  keyframe: KeyframeStyles,
): () => KeyframeStyles | null {
  const stack = getAnimationState(node).appliedStack;
  const {...from} = keyframe;

  Object.keys(from).forEach((prop) => {
    from[prop] = getFinalProperty(node, prop) || from[prop];
  });

  const id = Math.random();
  stack.push({id, from});
  return () => removeAnimation(node, id);
}

// Get a promise that resolve when this animation finishes. There is an
// important difference between this and the `animation.finished` promised, read
// below
function getAnimationFinished(animation: Animation) {
  const {playId: previousPlayId} = animationData.get(animation) || {};
  return new Promise<Animation>((resolve, _) => {
    animation.addEventListener('finish', () => {
      const {playId} = animationData.get(animation) || {};
      // We ignore all `finish` events that come from ALL animations made after
      // the point in which `getAnimationFinished` was called. Animations can be
      // reused (reversed), and we consider that animation a new one so this
      // ignores `finish` events if animations have been reused.
      if (previousPlayId === playId) {
        resolve(animation);
      }
    });
  });
}

function filterStyle(
  style: CSSStyleDeclaration,
  properties: string[],
): KeyframeStyles {
  return properties.reduce((obj: Record<string, string>, property) => {
    obj[property as string] = style[
      property as keyof CSSStyleDeclaration
    ] as string;
    return obj;
  }, {} as Record<string, string>) as KeyframeStyles;
}

// `reverseAnimation` and `animateStyle` ended up being complementary functions.
// At a high-level, the animation system is always playing new animations. However,
// internally we try to reverse animations when possible for a seamless transition
// of states. `reverseAnimation` attempts this and if it fails (it's not always safe
// to do so), we end up calling `animateStyle` instead. Both these functions have
// similar semantics: the prop should end up on a specific value, and when
// the animation is finished they should update the style of the node.
function reverseAnimation(
  node: Element,
  prop: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
): Animation | null {
  const state = getAnimationState(node);
  const animation = getCurrentAnimation(node, prop);
  const data = animation && animationData.get(animation);
  if (state && animation && data) {
    const nextValue = data.direction === 'normal' ? data.from : data.to;

    // TODO: This does not work when one value is read from the DOM and one is
    // not. They might be formatted differently, for example `translateX(450px)`
    // will come back from the DOM as `matrix(1, 0, 0, 1, 450, 0)`.
    //
    // This usually doesn't impact in/out animations, but it will impact
    // transitions. For example: if animating `transform`, it will apply the
    // styles once its done. If you apply another transition and then remove it,
    // it wants to go back to the previously applied styles. But when it did the
    // second animation, it recorded the current styles from the DOM, so it will
    // compare the user-supplied value with the DOM-formatted style.
    //
    // The result is it won't reverse the animation when applying two
    // transitions in order, and then removing the second one. That causes
    // less-than-ideal motion because it resets the duration/easing curves.
    // Should be fixable though.

    if (value === nextValue) {
      // Reverse it
      animation.reverse();

      // Update global state
      data.direction = data.direction === 'normal' ? 'reverse' : 'normal';
      data.playId = Math.random();
      state.activeAnimations.set(prop, animation);

      // We need to do this here to! We're just reversing the animation,
      // which needs to do everything `animateStyle` does. The finished
      // event in there won't get fired because we've "taken over"
      // We should abstract this out better
      getAnimationFinished(animation).then(() => {
        // @ts-expect-error figure out keys
        node.style[prop] = value;

        // We need to cancel the animation or else the browser will keep it around
        // in the active animations list (i.e. document.getAnimations()) and the
        // "to" state styles will always persist.
        cancelCurrentAnimation(node, prop);
      });

      return animation;
    }
  }
  return null;
}

function animateStyle(
  node: Element,
  prop: string,
  keyframes: Array<string | number | undefined>,
  options: KeyframeAnimationOptions,
) {
  // Automatically cancel previous animation
  cancelCurrentAnimation(node, prop);

  const animation = node.animate(
    {[prop]: keyframes} as PropertyIndexedKeyframes,
    {
      delay: options.delay || 0,
      direction: options.direction,
      duration: options.duration || 0,
      easing: options.easing || 'ease-in-out',
      fill: 'backwards',
    },
  );

  const initialValue = keyframes[0];
  const finalValue = keyframes[keyframes.length - 1];
  setCurrentAnimation(node, prop, animation, {
    from: initialValue,
    to: finalValue,
    direction: options.direction as Direction,
    playId: Math.random(),
  });

  getAnimationFinished(animation).then(() => {
    // @ts-expect-error figure out keys
    node.style[prop] =
      options.direction === 'reverse' ? initialValue : finalValue;
    cancelCurrentAnimation(node, prop);
  });

  return animation;
}

export interface AnimationTask {
  animations: Animation[];
  removeFunctions: Array<() => AnimationTask>;
  removeAndPlay(): AnimationTask;
  cancel(): void;
  getFinished(): Promise<void>;
}

export function toAnimationTask(tasks: AnimationTask[]): AnimationTask {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return animationTask(
    Array.prototype.concat.apply(
      [],
      tasks.map((task: AnimationTask) => task.animations),
    ),
    Array.prototype.concat.apply(
      [],
      tasks.map((task: AnimationTask) => task.removeFunctions),
    ),
  );
}

function animationTask(
  animations: Animation[],
  removeFunctions: Array<() => AnimationTask>,
): AnimationTask {
  return {
    animations,
    removeFunctions,

    removeAndPlay(): AnimationTask {
      return toAnimationTask(removeFunctions.map((remove) => remove()));
    },

    cancel: (): void => {
      // TODO: This should go through `cancelCurrentAnimation` or something else
      // so that the active animation state is updated (necessary if this is
      // currently the active animation)
      animations.forEach((anim) => anim.cancel());
    },

    getFinished: (): Promise<void> => {
      return Promise.all(
        animations.map((anim) => getAnimationFinished(anim)),
      ).then(() => {
        // Ignore the returned value
      });
    },
  };
}

export function emptyAnimationTask(): AnimationTask {
  return animationTask([], []);
}

export class NodeAnimation {
  /* eslint-disable lines-between-class-members */
  timeline: Timeline;
  nodeRef: React.RefObject<Element>;
  styles: KeyframeStyles;
  options: KeyframeOptions;
  direction: Direction;
  latestTask: AnimationTask | undefined;
  /* eslint-enable lines-between-class-members */

  constructor(
    timeline: Timeline,
    nodeRef: React.RefObject<Element>,
    styles: KeyframeStyles,
    options: KeyframeOptions,
  ) {
    this.timeline = timeline;
    this.nodeRef = nodeRef;
    this.styles = styles;
    this.options = options;
    // TODO: Is this used for anything?
    this.direction = 'normal';
  }

  play({
    reverse,
    backTo,
    push,
  }: {
    reverse?: 'effects' | 'keyframes';
    backTo?: KeyframeStyles;
    push?: boolean;
  } = {}): AnimationTask {
    const node = this.nodeRef.current;

    // If the node's owning body is not visible, then we won't be able to run animations on it.
    // This can cause problems, for example AnimatePresence will retain its children indefinitely.
    // This is normally invisible, but it leaks memory, and in the case of iframes, it can cause
    // the iframe to continue to be visible even after it should have been removed.
    if (!node || !checkVisibility(node)) {
      return emptyAnimationTask();
    }
    const reverseMode = reverse || this.options.reverse;

    if (getLockState(node)) {
      return emptyAnimationTask();
    }

    let from = filterStyle(getComputedStyle(node), Object.keys(this.styles));
    let to = this.styles;

    if (backTo) {
      to = backTo;
    }

    let removeStyles: (() => KeyframeStyles | null) | null = null;
    if (push) {
      removeStyles = pushAnimation(
        node,
        reverseMode === 'keyframes' ? to : from,
      );
    }

    if (reverseMode === 'keyframes' || reverseMode === 'effects') {
      // We reverse these even for the "effects" option, because
      // that means to run from the current state to the final state
      // (keyframes are not reversed) but since the whole animation
      // is run reversed, we need to swap these
      const swapped = to;
      to = from;
      from = swapped;
    }

    const options = {...this.options} as KeyframeAnimationOptions;
    options.direction = reverseMode === 'effects' ? 'reverse' : 'normal';
    this.direction = options.direction as Direction;

    const animations = Object.keys(to).map((prop) => {
      // First, attempt to reverse the animation but if that fails, fall back to
      // creating a new one
      const reversed = reverseAnimation(
        node,
        prop,
        options.direction === 'normal' ? to[prop] : from[prop],
      );
      return (
        reversed || animateStyle(node, prop, [from[prop], to[prop]], options)
      );
    });

    this.latestTask = animationTask(animations, [
      () => {
        if (removeStyles) {
          const backTo = removeStyles();
          if (backTo) {
            return this.play({reverse: 'effects', backTo});
          }
        }
        return emptyAnimationTask();
      },
    ]);
    return this.latestTask;
  }

  removeAndPlay(): AnimationTask {
    return this.latestTask?.removeAndPlay() || emptyAnimationTask();
  }

  cancel(): void {
    this.latestTask?.cancel();
  }

  getFinished(): Promise<void> {
    return this.latestTask?.getFinished() || Promise.resolve();
  }

  lockNode(): void {
    const node = this.nodeRef.current;
    if (node) {
      setLockState(node, true);
    }
  }

  unlockNode(): void {
    const node = this.nodeRef.current;
    if (node) {
      setLockState(node, false);
    }
  }
}
