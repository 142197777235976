import {createIntentType} from './intent';
import type {
  CreateIntent,
  ExtractReturnType,
  IntentFn,
  IntentType,
} from './types';

export function createSetIntentType<F extends IntentFn>(
  label: string,
  create: CreateIntent<F>,
): F & IntentType<ExtractReturnType<F>, Set<ExtractReturnType<F>>> {
  return createIntentType<ExtractReturnType<F>, Set<ExtractReturnType<F>>, F>(
    label,
    {
      add(intent, state = new Set()) {
        state.add(intent);
        return state;
      },
      create,
      merge(target, source) {
        if (target) {
          source.forEach((i) => target.add(i));
          return target;
        } else {
          return new Set(source);
        }
      },
    },
  );
}
