import {StyleSheets} from '../stylesheets';
import {queryServerStyleTag, hash, isServer, HashMap, Tree} from '../util';
import {assignStyle} from './assignStyle';
import {$classes, $deps, $hasClassName, VIEW_CLASS} from './constants';
import {
  serializeBatchedStyles,
  serializeCacheKey,
  serializeDeclaration,
  serializeKey,
  serializeMedia,
  serializeRuleset,
} from './serialize';
import {isBatchedStyles, isSelector} from './util';
import {replaceStaticTokens} from '../token/token';
import {VERSION_HASH} from '../version';
import type {Style} from './types';

class LayerState {
  count = {} as Record<string, number>;

  tree = new Tree<string, number>({
    branchingFactor: 8,
    compare(a, b) {
      return a.localeCompare(b);
    },
    summarize: {
      map() {
        return 1;
      },
      reduce(values) {
        return values.reduce((acc, v) => acc + v, 0);
      },
    },
  });

  hashMap: HashMap;

  constructor(prefix: string) {
    this.hashMap = new HashMap({
      toString(id: number): string {
        return `${prefix}${VERSION_HASH}${id.toString(36)}`;
      },
    });
  }

  has(key: string): boolean {
    return this.hashMap.has(key);
  }

  get(key: string): string {
    return this.hashMap.get(key);
  }

  add(key: string): void {
    this.count[key] ??= 0;
    this.count[key]++;
    return this.tree.add(key);
  }

  remove(key: string): void {
    return this.tree.remove(key);
  }

  index(key: string): number {
    return this.tree.summary(key);
  }

  hydrate(key: string, count: number): void {
    this.get(key);
    for (let i = 0; i < count; i++) {
      this.add(key);
    }
  }

  order() {
    return (
      Object.keys(this.hashMap.map)
        // Only encode the count if it's greater than 1 to minimize the output
        .map((key) => (this.count[key] > 1 ? `${key}:${this.count[key]}` : key))
        .join(',')
    );
  }
}

function createLayerStates() {
  return {
    atomic: new LayerState('a'),
    global: new LayerState('g'),
    reset: new LayerState('r'),
  };
}

export const StyleCache = {
  classlessKeys: new Set(),

  registry: new Map<string, Style.Intent>(),

  layer: createLayerStates(),

  register(styles: Style.Intent): void {
    this.registry.set(styles.key, styles);
  },

  get(key: string): Style.Intent | void {
    return this.registry.get(key);
  },

  insert(keys: string[], isDependency = false): string {
    // Ensure stylesheets are frozen and hydrated before inserting any styles.
    StyleSheets.freeze();

    const classesKey = keys.length === 1 ? keys[0] : serializeKey(keys);
    const cachedClasses = StyleSheets.getClasses(classesKey);
    if (cachedClasses) {
      return cachedClasses;
    }

    const classes = isDependency ? [] : [VIEW_CLASS];

    let atomicCount = 0;
    let atomic: Style.AtomicStyles | void = undefined;
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const lazy = this.get(key);
      if (!lazy) {
        throw new Error(`Missing styles for key "${key}"`);
      }
      const layer = lazy.layer;

      // Track global keys on server
      if (isServer && !isDependency && layer === 'global') {
        this.classlessKeys.add(key);
      }

      const styles = lazy();
      if (Array.isArray(styles)) {
        styles.forEach((ruleset) => {
          const cacheKey = serializeKey(ruleset);
          const inserted = this.layer[layer].has(cacheKey);
          if (inserted) {
            return;
          }
          this.layer[layer].get(cacheKey);
          this.insertRuleset(layer, cacheKey, ruleset);
        });
        // eslint-disable-next-line no-continue
        continue;
      }
      this.insertDependencies(classes, styles);

      if (isBatchedStyles(styles)) {
        this.insertBatchedStyles(classes, styles, layer);
      } else {
        if (atomicCount === 0) {
          atomic = styles;
        } else if (atomic) {
          if (atomicCount === 1) {
            atomic = assignStyle({}, atomic);
          }
          assignStyle(atomic, styles);
        }
        atomicCount++;
      }
    }

    if (atomic) {
      this.insertAtomicStyles(classes, atomic);
    }

    if (!isDependency) {
      classes.push(classesKey);
    }
    const className = classes.join(' ');
    StyleSheets.setClasses(classesKey, className);
    return className;
  },

  insertAtomicStyles(
    classes: string[],
    styles: Style.AtomicStyles,
    selector = '',
  ): void {
    const keys = Object.keys(styles);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const value = styles[key];

      if (isSelector(key)) {
        this.insertAtomicStyles(classes, value as Style.AtomicStyles, key);
      } else if (isBatchedStyles(value)) {
        const cacheKey = serializeCacheKey(key, hash(JSON.stringify(value)));
        this.insertBatchedStyles(classes, value, 'atomic', cacheKey);
      } else if (typeof value === 'string') {
        const declaration = serializeDeclaration(key, value);
        const cacheKey = serializeCacheKey(key, hash(selector + declaration));
        this.insertAtomicRuleset(classes, cacheKey, declaration, selector);
      } else if (typeof value === 'object') {
        const conditions = Object.keys(value);
        let priority = 0;
        let lastClassName = '';

        for (let j = 0; j < conditions.length; j++) {
          let condition = conditions[j];
          const conditionValue = value[condition];
          if (condition === 'default') {
            condition = '';
          }

          let classHash: string;
          let declaration = '';

          if (isBatchedStyles(conditionValue)) {
            classHash = hash(JSON.stringify(conditionValue));
          } else {
            declaration = serializeDeclaration(key, conditionValue as string);
            classHash = hash(selector + condition + declaration);
          }

          let cacheKey = serializeCacheKey(key, classHash, priority);

          // If the next class won't be inserted after the last class, adjust
          // its priority.
          if (lastClassName && cacheKey.localeCompare(lastClassName) !== 1) {
            priority++;
            cacheKey = serializeCacheKey(key, classHash, priority);
          }

          if (isBatchedStyles(conditionValue)) {
            this.insertBatchedStyles(
              classes,
              conditionValue,
              'atomic',
              cacheKey,
            );
          } else {
            this.insertAtomicRuleset(
              classes,
              cacheKey,
              declaration,
              selector,
              condition,
            );
          }

          lastClassName = cacheKey;
        }
      }
    }
  },

  insertAtomicRuleset(
    classes: string[],
    cacheKey: string,
    declaration: string,
    selector: string,
    condition = '',
  ): void {
    if (!declaration) {
      return;
    }
    const inserted = this.layer.atomic.has(cacheKey);
    const className = this.layer.atomic.get(cacheKey);
    classes.push(className);
    if (inserted) {
      return;
    }
    let ruleset = serializeRuleset(className, declaration, selector);
    if (condition) {
      ruleset = serializeMedia(condition, ruleset);
    }
    this.insertRuleset('atomic', cacheKey, ruleset);
  },

  insertBatchedStyles(
    classes: string[],
    styles: Style.BatchedStyles,
    layer: 'atomic' | 'global' | 'reset',
    cacheKey = `_${hash(JSON.stringify(styles))}`,
  ): void {
    const inserted = this.layer[layer].has(cacheKey);
    const className = this.layer[layer].get(cacheKey);
    const hasClassName = styles[$hasClassName];
    if (hasClassName) {
      classes.push(className);
    }
    if (inserted) {
      return;
    }

    const rulesets = serializeBatchedStyles(
      styles,
      hasClassName ? `.${className}` : '',
    );
    for (let i = 0; i < rulesets.length; i++) {
      const ruleset = rulesets[i];
      // On atomic layers, this will insert multiple rulesets with the same
      // cache key. This is okay :)
      this.insertRuleset(layer, cacheKey, ruleset);
    }
  },

  insertDependencies(
    classes: string[],
    styles: Style.AtomicStyles | Style.BatchedStyles,
  ): void {
    const deps = styles[$deps];
    if (deps) {
      classes.push(this.insert(deps, true));
    }
    const presetClasses = styles[$classes];
    if (presetClasses) {
      classes.push(...presetClasses);
    }
  },

  insertRuleset(
    layer: 'atomic' | 'global' | 'reset',
    cacheKey: string,
    ruleset: string,
  ): void {
    StyleSheets.schedule(() => {
      this.layer[layer].add(cacheKey);
      const index = this.layer[layer].index(cacheKey);
      const failures = StyleSheets.insert(
        [replaceStaticTokens(ruleset)],
        layer,
        index,
      );
      if (failures) {
        this.layer[layer].remove(cacheKey);
      }
    });
  },

  hydrateClassName(className: string): void {
    const startIndex = className.indexOf(VIEW_CLASS);
    if (startIndex === -1) {
      return;
    }
    const keyIndex = className.indexOf(VIEW_CLASS, startIndex + 1);
    if (keyIndex === -1) {
      return;
    }
    let endIndex = className.indexOf(' ', keyIndex + 1);
    if (endIndex === -1) {
      endIndex = className.length;
    }

    const classes = className.slice(startIndex, endIndex);
    const key = className.slice(keyIndex, endIndex);
    StyleSheets.setClasses(key, classes);
  },
};

StyleSheets.onBeforeFlush(() => {
  Object.entries(StyleCache.layer).forEach(([layer, layerState]) => {
    const order = layerState.order();

    let keys = '';
    if (layer === 'global') {
      const str = Array.from(StyleCache.classlessKeys).join(',');
      keys = `--keys: '${str}'; `;
    }

    const ruleset = `.__sn-sheet-order { --order: '${order}';${keys} }`;
    StyleSheets.insert([ruleset], layer);
  });
});

StyleSheets.onFlush(() => {
  StyleCache.layer = createLayerStates();
  StyleCache.classlessKeys.clear();
});

const CSS_STRING_PATTERN = /^[\s"']*([^\s"']*)[\s"']*$/g;
function cssStringToArray(str: string): string[] {
  const cleaned = str.replace(CSS_STRING_PATTERN, '$1');
  return cleaned ? cleaned.split(',') : [];
}

StyleSheets.onFreeze(() => {
  // Only hydrate on the client when using <style> elements
  // TODO(jackl): this is breaking SSR + shadow DOM on our docs site
  if (isServer || StyleSheets.styleConfig.styleTarget !== 'element') {
    return;
  }

  (['reset', 'global', 'atomic'] as const).forEach((layer) => {
    const sheet = queryServerStyleTag(layer)?.sheet;
    if (sheet) {
      const rule = sheet.cssRules[sheet.cssRules.length - 1];
      if (rule && rule instanceof CSSStyleRule) {
        const orderStr = rule.style.getPropertyValue('--order') || '';
        cssStringToArray(orderStr).forEach((key) => {
          const [cacheKey, countStr] = key.split(':');
          let count = typeof countStr === 'string' ? parseInt(countStr, 10) : 1;
          if (Number.isNaN(count)) {
            count = 1;
          }
          StyleCache.layer[layer].hydrate(cacheKey, count);
        });

        const keysStr = rule.style.getPropertyValue('--keys') || '';
        cssStringToArray(keysStr).forEach((classesKey) => {
          StyleSheets.setClasses(classesKey, '');
        });
      }
    }
  });

  // Hydrate classes for any server-side rendered elements
  //
  // TODO(koop): Improve performance. This currently runs N times, where N is
  // the number of elements with the shared class. Ideally this would run M
  // times, where M is the number of unique keys.
  document.querySelectorAll(`.${VIEW_CLASS}`).forEach((element) => {
    // can't use element.className here as SVGElements are weird
    StyleCache.hydrateClassName(element.classList.value);
  });
});
