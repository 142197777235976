import React from 'react';
import {useInteractOutside} from '../components/layers/vendor/useInteractOutside';

export const useInteractAnywhere = (
  handler: (event: React.SyntheticEvent) => void,
  ownerDocument: Document | undefined | null,
) => {
  const [dummyDiv, setDummyDiv] = React.useState(() => ({
    current: ownerDocument ? ownerDocument.createElement('div') : null,
  }));

  React.useEffect(() => {
    if (ownerDocument && !dummyDiv.current) {
      setDummyDiv({
        current: ownerDocument.createElement('div'),
      });
    }
  }, [dummyDiv, ownerDocument]);

  useInteractOutside({
    ref: dummyDiv,
    onInteractOutside: handler,
    isDisabled: !ownerDocument,
  });
};
