import {SPECIFICITY_TOKEN} from '../stylesheets';
import {hash, toHyphenCase} from '../util';
import {VIEW_CLASS} from './constants';
import {isBatchedStyles, isMedia} from './util';
import type {Style} from './types';

const whitespaceRegExp = /\s+/g;

export function serializeDeclaration(property: string, value: string): string {
  if (typeof value === 'undefined') {
    return '';
  }
  return `${toHyphenCase(property)}: ${String(value).replace(
    whitespaceRegExp,
    ' ',
  )};`;
}

export function serializeKey(obj: unknown, salt = ''): string {
  return VIEW_CLASS + hash(JSON.stringify(obj) + salt);
}

const priorityOverrides = {
  // `all: 'unset'` has the lowest precedence, otherwise no styles would be able
  // to override it
  all: 0,
  '--stack': 6,
  '--display-inside': 7,
  '--display': 8,
  '--align-x': 9,
  '--align-y': 9,
  default: 10,
  '--font-metrics': 11,
} as Record<string, number>;

const propertyPriorityMap = {} as Record<string, string>;
function getPropertyPriority(property: string): string {
  let firstBit = priorityOverrides.default;
  if (property in priorityOverrides) {
    firstBit = priorityOverrides[property];
  }

  let secondBit = 0;
  if (!property.startsWith('--')) {
    secondBit = (property.match(/[A-Z]/g)?.length || 0) + 1;
  }
  return firstBit.toString(36) + secondBit.toString(36);
}

function serializePropertyPrefix(property: string): string {
  propertyPriorityMap[property] ??= getPropertyPriority(property);
  return propertyPriorityMap[property];
}

function checkPriority(priority: number) {
  if (priority > 35 || priority < 0) {
    throw new Error('Priority must be within [0, 35].');
  }
}

export function serializeCacheKey(
  property: string,
  hash: string,
  priority = 0,
): string {
  checkPriority(priority);
  const propertyStr = serializePropertyPrefix(property);
  const priorityStr = priority.toString(36);
  return `${propertyStr}${priorityStr}${hash}`;
}

const NEST_PATTERN = /^@nest /;
const AMPERSAND_PATTERN = /&/g;
const TYPE_SELECTOR_PATTERN = /^(?:[-\w]+|\*)/g;

function getTypeSelector(compoundSelector: string): string {
  return compoundSelector.match(TYPE_SELECTOR_PATTERN)?.[0] || '';
}

function mergeTypeSelectors(a: string, b: string): string {
  if (a === b) {
    return a;
  }
  if (!a || a === '*') {
    return b || '*';
  }
  if (!b || b === '*') {
    return a || '*';
  }
  throw new Error(`Cannot merge type selectors "${a}" and "${b}"`);
}

function matchCharacterPair(
  str: string,
  position = 0,
  open = '(',
  close = ')',
): string {
  let depth = 0;

  for (let i = position; i < str.length; i++) {
    if (str[i] === open) {
      depth++;
    } else if (str[i] === close) {
      depth--;
    }
    if (depth === 0) {
      return position === i ? '' : str.slice(position + 1, i);
    }
  }
  if (depth > 0) {
    throw new Error('Mismatched character pair');
  }
  return '';
}

const STRING_PATTERN = /(['"])((?:\\\1|.)+?)\1/g;
const STRING_MARKER = '⋯';
const STRING_MARKER_PATTERN = new RegExp(`([${STRING_MARKER}]+)(\\d+)`, 'g');
const PAREN_MARKER = '⊗';
const PAREN_MARKER_PATTERN = new RegExp(`([${PAREN_MARKER}]+)(\\d+)`, 'g');

let transformMarkerCount = 0;

function transformSelector(
  initialSelector: string,
  transform: (selector: string) => string,
  maxDepth = Infinity,
  currentDepth = 1,
): string {
  // Increment the transform marker so transformSelector calls can
  // be safely nested.
  transformMarkerCount++;

  const strings = [] as string[];
  let selector = initialSelector;

  // Replace any strings with a marker and corresponding index
  const stringMarker = ''.padStart(transformMarkerCount, STRING_MARKER);
  selector = selector.replace(STRING_PATTERN, (match) => {
    strings.push(match);
    return stringMarker + (strings.length - 1);
  });

  // Replace any parentheses with a marker and corresponding index
  const parenMarker = ''.padStart(transformMarkerCount, PAREN_MARKER);
  const parentheses = [] as string[];
  let i = 0;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    i = selector.indexOf('(', i);
    if (i === -1) {
      break;
    }

    const match = matchCharacterPair(selector, i);
    if (match) {
      selector =
        selector.slice(0, i) +
        parenMarker +
        parentheses.length +
        selector.slice(i + 2 + match.length);
      parentheses.push(match);
    }
  }

  // Transform the selector (without strings/parens)
  selector = transform(selector);

  // Restore parentheses and apply the transform to the selector
  // within the parentheses if we're below the max depth
  if (parentheses.length) {
    selector = selector.replace(
      PAREN_MARKER_PATTERN,
      (match, marker, index) => {
        if (marker.length !== transformMarkerCount) {
          return match;
        }
        const str = parentheses[index] || '';
        return `(${
          currentDepth < maxDepth ? transformSelector(str, transform) : str
        })`;
      },
    );
  }
  // Restore strings
  if (strings.length) {
    selector = selector.replace(STRING_MARKER_PATTERN, (match, marker, index) =>
      marker.length === transformMarkerCount ? strings[index] || '' : match,
    );
  }

  transformMarkerCount--;
  return selector;
}

function mergeCompoundSelectors(a: string, b: string): string {
  let aSubclass = a;
  let bSubclass = b;
  let aPseudo = '';
  let bPseudo = '';

  const aPseudoIndex = a.indexOf(':');
  if (aPseudoIndex !== -1) {
    aSubclass = a.slice(0, aPseudoIndex);
    aPseudo = a.slice(aPseudoIndex);
  }

  const bPseudoIndex = b.indexOf(':');
  if (bPseudoIndex !== -1) {
    bSubclass = b.slice(0, bPseudoIndex);
    bPseudo = b.slice(bPseudoIndex);
  }

  let typeSelector = '';
  const bTypeSelector = getTypeSelector(b);
  if (bTypeSelector) {
    const aTypeSelector = getTypeSelector(a);
    aSubclass = aSubclass.slice(aTypeSelector.length);
    bSubclass = bSubclass.slice(bTypeSelector.length);
    typeSelector = mergeTypeSelectors(aTypeSelector, bTypeSelector);
  }

  // https://www.w3.org/TR/selectors-4/#typedef-compound-selector
  return `${typeSelector}${aSubclass}${bSubclass}${aPseudo}${bPseudo}`;
}

function mapSelector(
  initialSelector: string,
  transform: (complexSelector: string) => string,
  maxDepth = 1,
): string {
  // https://www.w3.org/TR/selectors/#grammar
  // https://www.w3.org/TR/selectors-4/#typedef-compound-selector
  return transformSelector(
    initialSelector,
    (selector) =>
      selector
        .split(',')
        .map((complexSelector) => transform(complexSelector.trim()))
        .join(', '),
    maxDepth,
  );
}

const COMBINATOR_PATTERN = /([ >+]|~(?=[^=])|\|\|)/g;
export function nestSelector(parent: string, nested: string): string {
  if (!parent || (nested && nested.indexOf('&') === -1)) {
    return nested;
  }
  const selector = nested.replace(NEST_PATTERN, '').trim();
  if (selector === '' || selector === '&') {
    return parent;
  }

  return mapSelector(parent, (parentSelector) => {
    COMBINATOR_PATTERN.lastIndex = 0;
    let lastIndex = -1;
    while (COMBINATOR_PATTERN.test(parentSelector)) {
      lastIndex = COMBINATOR_PATTERN.lastIndex;
    }

    const parentCompoundSelector =
      lastIndex === -1 ? parentSelector : parentSelector.slice(lastIndex);
    const parentComplexSelectorPrefix =
      lastIndex === -1 ? '' : parentSelector.slice(0, lastIndex);

    return mapSelector(
      nested,
      (nestedSelector) => {
        if (nestedSelector.indexOf('&') === -1) {
          return nestedSelector;
        }

        return nestedSelector
          .split(COMBINATOR_PATTERN)
          .map((part) => {
            let nestCount = 0;
            const cleaned = part.replace(AMPERSAND_PATTERN, () => {
              nestCount++;
              return '';
            });
            let prefix = '';
            let compound = cleaned;
            while (nestCount) {
              nestCount--;
              prefix += parentComplexSelectorPrefix;
              compound = mergeCompoundSelectors(
                parentCompoundSelector,
                compound,
              );
            }
            return `${prefix}${compound}`;
          })
          .join('');
      },
      Infinity,
    );
  });
}

export function serializeMedia(condition: string, ruleset: string): string {
  return `${condition} { ${ruleset} }`;
}

/**
 * /(html|:root)(\s|\.|:|\[|$)/
 * \s -> match followed by whitespace, i.e. `html div`
 * \. -> match with class `html.foo`
 * :  -> match with pseudo-selector, i.e. `html:is(.foo)`
 * \[ -> match with attribute selector, i.e. `html[foo]`
 * $  -> match by its lonesome, i.e. `html`
 */
const ROOT_SELECTOR_PATTERN = /(html|:root|:host)(\s|\.|:|\[|$)/g;

function addSpecificityToken(selector: string): string {
  return mapSelector(selector, (part) => {
    const replaced = part.replace(
      ROOT_SELECTOR_PATTERN,
      (_, selector, after) => {
        switch (selector) {
          case 'html':
            return `${selector}${SPECIFICITY_TOKEN}${after}`;
          case ':host':
            return `${selector}${after} ${SPECIFICITY_TOKEN}`;
          default:
            return `${SPECIFICITY_TOKEN}${selector}${after}`;
        }
      },
    );
    return part === replaced ? `${SPECIFICITY_TOKEN} ${part}` : replaced;
  });
}

export function serializeRuleset(
  className: string,
  declaration: string,
  selector = '',
): string {
  const compiledSelector = addSpecificityToken(
    nestSelector(`.${className}`, selector),
  );
  return `${compiledSelector} { ${declaration} }`;
}

function serializeBatchedRuleset(
  rulesets: string[],
  selector: string,
  styles: Record<string, string>,
  parentSelector: string,
): void {
  if (!parentSelector && selector.indexOf('&') !== -1) {
    throw new Error(
      'Cannot use nested selectors without a valid parent selector',
    );
  }
  const currentSelector = nestSelector(parentSelector, selector);
  const compiledSelector = addSpecificityToken(currentSelector);
  const open = `${compiledSelector} {\n`;
  const close = `\n}`;
  let css = open;
  const keys = Object.keys(styles);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = styles[key];
    const isLast = i === keys.length - 1;
    if (isBatchedStyles(value)) {
      if (css && css !== open) {
        rulesets.push(css + close);
      }
      rulesets.push(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        ...serializeBatchedStyles(value, currentSelector, false),
      );
      css = isLast ? '' : open;
    } else {
      css += serializeDeclaration(key, styles[key]);
      if (isLast && css && css !== open) {
        rulesets.push(css + close);
      }
    }
  }
}

export function serializeBatchedStyles(
  styles: Style.BatchedStyles,
  parentSelector = '',
  supportsMedia = true,
): string[] {
  const rulesets = [] as string[];
  const keys = Object.keys(styles);
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = styles[key];

    if (isMedia(key)) {
      if (!supportsMedia) {
        throw new Error(
          'Cannot nest media queries within a selector or media query',
        );
      }
      const selectors = Object.keys(value);
      const mediaRulesets = [] as string[];
      for (let j = 0; j < selectors.length; j++) {
        const selector = selectors[j];
        serializeBatchedRuleset(
          mediaRulesets,
          selector,
          (value as Record<string, Record<string, string>>)[selector],
          parentSelector,
        );
      }
      if (mediaRulesets.length) {
        rulesets.push(`${key} {\n${mediaRulesets.join('\n')}\n}`);
      }
    } else {
      serializeBatchedRuleset(
        rulesets,
        key,
        value as Record<string, string>,
        parentSelector,
      );
    }
  }
  return rulesets;
}

export function serializeKeyframe(
  keyframeSelector: Style.KeyframeSelector,
  properties: Style.Keyframe,
): string {
  // Serialize the keyframe selector
  let keyframe = keyframeSelector as string;

  // For keys that are numbers, append a percent character
  if (!Number.isNaN(Number(keyframeSelector))) {
    keyframe += '%';
  }

  // Open the keyframe block
  keyframe += ` {`;

  // Serialize the keyframe declarations
  const propertyNames = Object.keys(properties) as (keyof Style.Keyframe)[];
  for (let i = 0; i < propertyNames.length; i++) {
    const property = propertyNames[i];
    keyframe += serializeDeclaration(property, `${properties[property]}`);
  }

  // Close the keyframe block
  keyframe += '}';
  return keyframe;
}
