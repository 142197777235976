import {createIntentType} from './intent';
import type {
  CreateIntent,
  ExtractReturnType,
  IntentFn,
  IntentType,
} from './types';

export function createArrayIntentType<F extends IntentFn>(
  label: string,
  create: CreateIntent<F>,
): F & IntentType<ExtractReturnType<F>, ExtractReturnType<F>[]> {
  return createIntentType<ExtractReturnType<F>, ExtractReturnType<F>[], F>(
    label,
    {
      add(intent, state = []) {
        state.push(intent);
        return state;
      },
      create,
      merge(target, source) {
        if (target) {
          target.push(...source);
          return target;
        } else {
          return source.slice();
        }
      },
    },
  );
}
