import {SPECIFICITY_TOKEN} from './constants';
import {queryServerStyleTag, isServer} from '../util';
import type {StyleSheetsConfig} from './StyleSheets';

const specificityRegExp = new RegExp(SPECIFICITY_TOKEN, 'g');

export type StyleSheetConstructor = new (
  getPreviousSheet?: () => StyleSheet,
  name?: string,
) => StyleSheet;

export abstract class StyleSheet {
  name: string;

  serverRulesets: string[];

  specificity: string;

  abstract readonly type: string;

  abstract sheet: CSSStyleSheet | void;

  constructor(public getPreviousSheet?: () => StyleSheet, name?: string) {
    this.specificity = '';
    this.name = name || '';
    this.serverRulesets = [];
  }

  /** Clean up after this stylesheet by un-applying it. */
  flush(): void {
    this.serverRulesets.length = 0;
  }

  /** Returns true if the rule was successfully inserted */
  insertRule(styles: string, index = -1): boolean {
    const processedStyles = styles.replace(specificityRegExp, this.specificity);

    if (isServer) {
      if (index === -1) {
        this.serverRulesets.push(processedStyles);
      } else {
        this.serverRulesets.splice(index, 0, processedStyles);
      }
      return true;
    }

    const sheet = this.sheet;
    if (sheet) {
      try {
        sheet.insertRule(
          processedStyles,
          index === -1 ? sheet.cssRules.length : index,
        );
      } catch (e) {
        // CSSStyleSheet.insertRule has several syntax restrictions and will
        // throw errors when it encounters certain types of unknown syntax
        // (in particular, unsupported pseudoselectors).
        //
        // We catch these errors for several reasons:
        // * We want to encourage progressive enhancement and leverage new
        //   browser features when possible.
        // * It’s difficult to know which pseudoselectors are supported in
        //   the current browser environment.
        // * Attempting to replace unknown syntax in each rule would penalize
        //   the many valid rules at the expense of the few invalid rules.
        //
        // We may want to consider logging here when in development, but it
        // could be noisy without some form of deduplication.
        //
        // TODO(koop): If a ruleset contains multiple selectors, can we try
        // inserting one ruleset per selector to preserve any working portions
        // of the ruleset?
        //
        // https://developer.mozilla.org/en-US/docs/Web/API/CSSStyleSheet/insertRule#restrictions
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.warn(e);
        }
        return false;
      }
    }
    return true;
  }
}

export function InjectableConstructableStyleSheet(
  cssStyleSheetConstructor: StyleSheetsConfig['cssStyleSheetConstructor'] = () =>
    new CSSStyleSheet(),
): new (getPreviousSheet?: () => StyleSheet, name?: string) => StyleSheet {
  /**
   * A StyleSheet that is represented by an in-memory CSSStyleSheet instance.
   *
   * Requires the CSSStyleSheet constructor to be present (not uniformly true across
   * major browsers yet).
   */
  return class ConstructableStyleSheet extends StyleSheet {
    sheet: CSSStyleSheet | void;

    type = 'constructable-sheet';

    constructor(getPreviousSheet?: () => StyleSheet, name?: string) {
      super(getPreviousSheet, name);
      this.sheet = cssStyleSheetConstructor(name);
    }

    flush(): void {
      if (this.sheet) {
        this.sheet.disabled = true;
      }
      this.sheet = undefined;
      super.flush();
    }
  };
}

function isElementStyleSheet(sheet: StyleSheet): sheet is ElementStyleSheet {
  return sheet?.type === 'element';
}

/** A StyleSheet that is mounted to a <style> element */
export class ElementStyleSheet extends StyleSheet {
  element: HTMLStyleElement | void = undefined;

  type = 'element';

  constructor(public getPreviousSheet?: () => StyleSheet, name?: string) {
    super(getPreviousSheet, name);
  }

  insertRule(styles: string, index = -1): boolean {
    if (!this.element) {
      this.initializeElement();
    }

    return super.insertRule(styles, index);
  }

  initializeElement(): void {
    if (isServer || this.element) {
      return;
    }

    let previousElement;
    const previousSheet = this.getPreviousSheet?.();
    if (previousSheet && isElementStyleSheet(previousSheet)) {
      previousSheet.initializeElement();
      previousElement = previousSheet.element;
    }

    // Attempt to hydrate the element if one already exists
    this.element = queryServerStyleTag(this.name);

    if (!this.element) {
      // we're not hydrating, and/or this sheet doesn't have an associated tag
      this.element = document.createElement('style');

      // WebKit does not permit allowlisting empty style elements within Content
      // Security Policy directives unless `unsafe-inline` is specified. Appending
      // a non-empty string allows the element to be allowlisted via a content hash.
      // https://bugs.webkit.org/show_bug.cgi?id=233071
      this.element.appendChild(document.createTextNode('/**/'));
      this.element.setAttribute('data-layer', this.name);

      // Insert the <style> node in the correct position.
      if (previousElement) {
        previousElement.after(this.element);
      } else {
        document.head.appendChild(this.element);
      }
    }
  }

  flush(): void {
    if (this.element) {
      this.element.remove();
      this.element = undefined;
    }
    super.flush();
  }

  get sheet(): CSSStyleSheet | void {
    return this.element?.sheet || undefined;
  }
}
