import {Priority} from './constants';

const $priority = Symbol('priority');

type WithPriority = {
  [$priority]: Priority;
};

/**
 * Changes the priority in which an intent is applied.
 *
 * [Intents](https://sail.corp.stripe.com/next/engine/views/#Intents) represent instructions for how an Engine
 * view should modify itself (whether that be styles, behavior, or more). Most
 * intents should run at "normal" priority, executing in sequence.
 *
 * In some situations, it can be necessary for an intent to be run earlier than
 * it would otherwise be scheduled to run. For example, some intents need to
 * set values of props, while other intents act upon prop values; setting the
 * value of a prop *after* it has been read may result in unexpected or
 * inconsistent behaviors. Internally, the [`setDefaults`](https://sail.stripe.me/apis/setdefaults)
 * intent is therefore set to a high priority as a reasonable default behavior.
 */
export function setPriority<T>(priority: Priority, value: T): T {
  (value as unknown as WithPriority)[$priority] = priority;
  return value;
}

export function getPriority(value: unknown): Priority {
  return (value as WithPriority)?.[$priority] ?? Priority.Normal;
}
