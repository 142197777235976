import type {ConnectElementConfig} from '../ConnectElementList';

export const ExportTaxTransactionsConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "exportTaxTransactions" */
      '../../../components/Tax/ExportTaxTransactions'
    ),
  enabledKey: 'export_tax_transactions', // Flag that will determine if the custom element should be rendered
  releaseState: 'internal',
  sentryTeam: 'tax_connect_embedded_components', // Enter your team's name - this is used for SFX reporting and sentry. See docs on how to set up: https://confluence.corp.stripe.com/display/OBS/Setting+up+Sentry+Reporting#SettingupSentryReporting-SettingupaSentryproject
  teamOwner: 'tax-connect',
  description: 'Renders the export tax transactions component for the account.',
};
