import {getEnvironment, once} from '@sail/utils';
import generateId from 'src/helpers/generateId';
import {MissingServiceObservabilityError} from 'src/internal/common/errors';
import logErrorInDev from 'src/internal/common/logErrorInDev';
import {QA_R_URL, R_URL} from 'src/internal/metrics/endpoints';

import type {MetricParams} from 'src/internal/metrics/types';
import type {ObservabilityConfig} from 'src/types';

const HEADERS = {
  'Content-Type': 'application/x-www-form-urlencoded',
};

const logMissingServiceOnce = once(() => {
  logErrorInDev(new MissingServiceObservabilityError());
});

export default function record(
  config: ObservabilityConfig,
  metric: string,
  params?: MetricParams,
): void {
  const service = config.service;

  if (!service) {
    logMissingServiceOnce();

    return;
  }

  // Parameters required by the analytics event logger service
  const requiredParams = {
    client_id: service,
    event_id: generateId(),
    event_name: metric,
    created: `${Math.floor(new Date().getTime() / 1000)}`,
  };

  const pageViewToken = config.pageViewToken;

  const url = getEnvironment() === 'production' ? R_URL : QA_R_URL;

  const body = new URLSearchParams({
    ...params,
    ...(pageViewToken ? {page_view_id: pageViewToken} : {}),
    ...requiredParams,
  } as Record<string, string>);

  if (
    typeof navigator !== 'undefined' &&
    typeof navigator.sendBeacon === 'function'
  ) {
    navigator.sendBeacon(url, body);
  } else {
    fetch(url, {
      method: 'POST',
      headers: HEADERS,
      mode: 'no-cors',
      keepalive: true,
      body: body.toString(),
    });
  }
}
