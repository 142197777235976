import {message} from '@sail/intl';
import type {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';
import {accountOnboardingDynamicImport} from '../../../components/Account/AccountOnboarding/AccountOnboardingDynamicImport';
import {ComponentOverrideFlag} from '../../../utils/flags/flags';

const messages = {
  errorText: message({
    id: 'connectEmbed.accountOnboarding.errorBoundary.text',

    defaultMessage:
      'Your money is secure, but your account information cannot be loaded here.',

    description: 'Message shown when an unhandled account error is caught',
  }),
};

// public attributes
export const fullTosUrlParameter = 'full-terms-of-service-url' as const;
export const recipientTosUrlParameter =
  'recipient-terms-of-service-url' as const;
export const privacyPolicyUrlParameter = 'privacy-policy-url' as const;
export const skipTermsOfServiceCollectionParameter =
  'skip-terms-of-service-collection' as const;

// private attributes
export const alignParameter = 'align' as const;
export const collectParameter = 'collect' as const;
export const intentParameter = 'intent' as const;
export const analyticsFlowParameter = 'analytics-flow' as const;
export const analyticsPrefixParameter = 'analytics-prefix' as const;
export const hideFooterLinksParameter = 'hide-footer-links' as const;
export const showSaveForLaterParameter = 'show-save-for-later' as const;
export const showIconsParameter = 'show-icons' as const;
export const enableAddressAutocompleteParameter =
  'enable-address-autocomplete' as const;
export const preselectedLegalEntityTokenParameter =
  'preselected-legal-entity-token' as const;
export const usePlatformBrandingSettingsParameter =
  'use-platform-branding-settings' as const;
export const debugParameter = 'debug' as const;

export const AccountOnboardingConfig: ConnectElementConfig = {
  Component: async () => {
    const defaultImport = accountOnboardingDynamicImport();
    return defaultImport;
  },
  htmlAttributes: {
    [fullTosUrlParameter]: {
      isInternal: false,
    },
    [recipientTosUrlParameter]: {
      isInternal: false,
    },
    [privacyPolicyUrlParameter]: {
      isInternal: false,
    },
    [skipTermsOfServiceCollectionParameter]: {
      isInternal: false,
    },

    // Internal - to enable these for platforms, we'll need API review
    [alignParameter]: {
      isInternal: true,
    },
    [collectParameter]: {
      isInternal: true,
    },
    [intentParameter]: {
      isInternal: true,
    },
    [analyticsFlowParameter]: {
      isInternal: true,
    },
    [analyticsPrefixParameter]: {
      isInternal: true,
    },
    [showIconsParameter]: {
      isInternal: true,
    },
    [showSaveForLaterParameter]: {
      isInternal: true,
    },
    [hideFooterLinksParameter]: {
      isInternal: true,
    },
    [enableAddressAutocompleteParameter]: {
      isInternal: true,
    },
    [preselectedLegalEntityTokenParameter]: {
      isInternal: true,
    },
    [debugParameter]: {
      isInternal: true,
    },
    [usePlatformBrandingSettingsParameter]: {
      isInternal: true,
    },
  },
  htmlEvents: ['exit', 'onboardingexited', 'stepchange'],
  customHtmlElementMethods: {
    setOnExit: {
      type: 'EventListenerMethod',
      event: 'exit',
      isInternal: false,
      description: 'Sets the event listener for when the user exits onboarding',
    },
    setFullTermsOfServiceUrl: {
      type: 'AttributeSettingMethod',
      attribute: fullTosUrlParameter,
      isInternal: false,
      description:
        'Only supported for custom. Sets the full terms of service URL',
    },
    setRecipientTermsOfServiceUrl: {
      type: 'AttributeSettingMethod',
      attribute: recipientTosUrlParameter,
      isInternal: false,
      description:
        'Only supported for custom. Sets the recipient terms of service URL',
    },
    setPrivacyPolicyUrl: {
      type: 'AttributeSettingMethod',
      attribute: privacyPolicyUrlParameter,
      isInternal: false,
      description: 'Only supported for custom. Sets the privacy policy URL',
    },
    setSkipTermsOfServiceCollection: {
      type: 'AttributeSettingMethod',
      attribute: skipTermsOfServiceCollectionParameter,
      isInternal: false,
      description:
        'Only supported for custom. Sets whether to skip terms of service collection. Note that if terms of service are not collected as part of onboarding, the platform will have to collect them elsewhere.',
    },
    setCollectionOptions: {
      type: 'SupplementalObject',
      objectToSet: 'collectionOptions',
      isInternal: false,
      description:
        'Sets the collection options for the account onboarding form. Includes future_requirements and eventually_due.',
    },
    setOnStepChange: {
      type: 'EventListenerMethod',
      event: 'stepchange',
      isInternal: false,
      description:
        'Sets the event listener for when the user changes onboarding step',
    },
    // Internal only
    setPreselectedLegalEntityToken: {
      type: 'AttributeSettingMethod',
      attribute: preselectedLegalEntityTokenParameter,
      isInternal: true,
      description:
        'Sets the preselected legal entity token for the account onboarding form. Used specifically for legal entity sharing only.',
    },
    setDebug: {
      type: 'AttributeSettingMethod',
      attribute: debugParameter,
      isInternal: true,
      description:
        'Sets the embedded onboarding component in debug mode, which allows for additional debugging tools to be shown.',
    },
    setPrefillOptions: {
      type: 'SupplementalObject',
      objectToSet: 'prefillOptions',
      isInternal: true,
      description:
        'Sets the prefill options for the account onboarding form. Mainly used to pipe through prefill options from OAuth.',
    },
    setStep: {
      type: 'SupplementalObject',
      objectToSet: 'step',
      isInternal: true,
      description:
        'Sets the current step of the onboarding form. Used to skip to a specific step.',
    },
  },
  messageOverrides: messages,
  enabledKey: 'account_onboarding',
  enabledOverrideFlag: ComponentOverrideFlag.account_onboarding,
  releaseState: 'ga',
  requiresAuthentication: true,
  sentryTeam: 'risk_compliance_experiences',
  teamOwner: 'risk-compliance-experiences',
  description:
    'Collects information to satisfy requirements on an account to enable its given capabilities. Will also perform the user claim step if the account is not claimed by a user.',
  docSitePath: '/connect/supported-embedded-components/account-onboarding',
  syntheticTestInfo: {
    testUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/gb_pss_indv_oauth_y_f',
    rcTestUrl:
      'https://amp.corp.stripe.com/synthetics/us-west-2/conon-us_pns_indv-rc',
  },
  applyMobileUIOverrides: true,
};
