import type {Token} from '../token';

type MediaInput = string | Token.Value<string>;
type MediaLiteral<TQuery extends string = 'query'> = `@media ${TQuery}`;

type StringifiedMediaInput<T extends MediaInput> = T extends string
  ? T
  : Token.Path<T>;

type TrimMediaQuery<T extends string> = T extends `@media ${infer U}` ? U : T;

type MediaUpQuery<TValue extends MediaInput> =
  `(min-width: ${StringifiedMediaInput<TValue>})`;

type MediaDownQuery<TValue extends MediaInput> =
  `(max-width: calc(${StringifiedMediaInput<TValue>} - 0.1px))`;

type MediaBetweenQuery<
  TMin extends MediaInput,
  TMax extends MediaInput,
> = `(${MediaUpQuery<TMin>} and ${MediaDownQuery<TMax>})`;

/**
 * A helper for constructing media queries using Sail's design tokens.
 *
 * @see https://sail.stripe.me/beta/apis/media
 */
export const media = {
  query<TSelector extends string>(
    value: TSelector,
  ): MediaLiteral<TrimMediaQuery<TSelector>> {
    return `@media ${value.replace(/@media /g, '')}` as MediaLiteral<
      TrimMediaQuery<TSelector>
    >;
  },
  up<TValue extends MediaInput>(
    value: TValue,
  ): MediaLiteral<MediaUpQuery<TValue>> {
    return media.query(`(min-width: ${value})`) as MediaLiteral<
      MediaUpQuery<TValue>
    >;
  },
  down<TValue extends MediaInput>(
    value: TValue,
  ): MediaLiteral<MediaDownQuery<TValue>> {
    return media.query(`(max-width: calc(${value} - 0.1px))`) as MediaLiteral<
      MediaDownQuery<TValue>
    >;
  },
  between<TMin extends MediaInput, TMax extends MediaInput>(
    min: TMin,
    max: TMax,
  ): MediaLiteral<MediaBetweenQuery<TMin, TMax>> {
    return media.query(
      `(${media.up(min)} and ${media.down(max)})`,
    ) as MediaLiteral<MediaBetweenQuery<TMin, TMax>>;
  },
};
