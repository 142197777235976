import {createArrayIntentType, getIntents} from '../intent';
import type {Intent, IntentType} from '../intent';
import type {View} from './types';

type ViewConfigIntentType<Props> = ((
  intents: View.Intent<Props>[],
) => View.Intent<Props>[] & Intent) &
  IntentType<View.Intent<Props>[]>;

const configIntentTypes = new WeakMap<
  View.Config,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ViewConfigIntentType<any>
>();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function withViewConfig<Config extends View.Config<any, any>>(
  config: Config,
  intents: View.ViewConfigIntent<Config>[],
): Intent {
  let intentType = configIntentTypes.get(config);
  if (!intentType) {
    intentType = createArrayIntentType(
      'withConfig',
      (decorate) => (intents) => decorate(intents),
    );
    configIntentTypes.set(config, intentType);
  }
  return intentType(intents);
}

export function isViewConfigIntent<Props>(
  revision: View.ViewRevision<Props>,
  intent: unknown,
): intent is View.Intent<Props>[] & Intent {
  const intentType = configIntentTypes.get(
    revision.instance.config as View.Config,
  );
  if (intentType) {
    return intentType.isIntent(intent);
  }
  return false;
}

export function getViewConfigIntents<Props>(
  forRevision: View.ViewRevision<Props>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fromRevision: View.ViewRevision<any> = forRevision,
): View.Intent<Props>[] | void {
  const intentType = configIntentTypes.get(
    forRevision.instance.config as View.Config,
  );
  if (intentType) {
    return getIntents(intentType, fromRevision);
  }
}

export function getViewConfigIntentType<Props>(
  revision: View.ViewRevision<Props>,
): ViewConfigIntentType<Props> | void {
  return configIntentTypes.get(revision.instance.config as View.Config);
}
