import {$instance, $shared} from './constants';
import type {View} from './types';

export function createViewInstance<Props, Options extends View.ConfigOptions>(
  config: View.Config<Props, Options>,
): View.ViewInstance<Props> {
  return {[$shared]: {}, config} as View.ViewInstance<Props>;
}

export function extendViewInstance<Props, Options extends View.ConfigOptions>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  instance: View.ViewInstance<any>,
  config: View.Config<Props, Options>,
): View.ViewInstance<Props> {
  const extendedInstance = {
    [$shared]: instance[$shared],
    config,
  } as View.ViewInstance<Props>;
  instance[$instance] ??= extendedInstance as View.ViewInstance<unknown>;
  return extendedInstance;
}
