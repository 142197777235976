import type {ServiceEnvironment} from '../utils/getTargetEnvironment';
import {
  getTargetEnvironment,
  getTunnelUrl,
  getDevboxUrl,
} from '../utils/getTargetEnvironment';

export type ServiceName =
  | 'api'
  | 'files'
  | 'site'
  | 'connect'
  | 'admin'
  | 'dashboard'
  | 'stripejs'
  | 'accounts';

export const STRIPE_API_VERSION = '2020-08-27';

export const serviceEndpoints: Record<
  ServiceName,
  Record<ServiceEnvironment, string>
> = {
  api: {
    tunnel: getTunnelUrl('api'),
    devbox: getDevboxUrl('api'),
    qa: 'qa-api.stripe.com',
    preprod: 'preprod-api.stripe.com',
    prod: 'api.stripe.com',
  },
  files: {
    tunnel: getTunnelUrl('upload'),
    devbox: getDevboxUrl('upload'),
    qa: 'qa-files.stripe.com',
    preprod: 'preprod-files.stripe.com',
    prod: 'files.stripe.com',
  },
  site: {
    tunnel: getTunnelUrl('site'),
    devbox: getDevboxUrl('site'),
    qa: 'qa.stripe.com',
    preprod: 'preprod.stripe.com',
    prod: 'stripe.com',
  },
  connect: {
    tunnel: getTunnelUrl('manage'),
    devbox: getDevboxUrl('manage'),
    qa: 'qa-connect.stripe.com',
    preprod: 'preprod-connect.stripe.com',
    prod: 'connect.stripe.com',
  },
  admin: {
    tunnel: getTunnelUrl('admin'),
    devbox: getDevboxUrl('admin'),
    qa: 'qa-dashboard.stripe.com',
    // Admin doesn't have preprod
    preprod: 'admin.qa.corp.stripe.com',
    prod: 'admin.corp.stripe.com',
  },
  dashboard: {
    tunnel: getTunnelUrl('manage'),
    devbox: getDevboxUrl('manage'),
    qa: 'qa-dashboard.stripe.com',
    preprod: 'preprod-dashboard.stripe.com',
    prod: 'dashboard.stripe.com',
  },
  stripejs: {
    tunnel: getTunnelUrl('stripejs'),
    devbox: getDevboxUrl('stripejs'),
    qa: 'qa-js.stripe.com/v3/',
    preprod: 'preprod-js.stripe.com/v3/',
    prod: 'js.stripe.com/v3/',
  },
  accounts: {
    tunnel: getTunnelUrl('recipient_payouts_hosted_onboarding'),
    devbox: getDevboxUrl('recipient_payouts_hosted_onboarding'),
    qa: 'qa-accounts.stripe.com',
    preprod: 'preprod-accounts.stripe.com',
    prod: 'accounts.stripe.com',
  },
};

const buildStripeHostOverride = (
  service: ServiceName,
  targetEnvironment: ServiceEnvironment,
): string => {
  const environmentToUse = getTargetEnvironment(targetEnvironment);

  return serviceEndpoints[service][environmentToUse];
};

export const getStripeApiHost = (
  targetEnvironment: ServiceEnvironment,
): string => {
  return buildStripeHostOverride('api', targetEnvironment);
};

export const getStripeFilesHost = (
  targetEnvironment: ServiceEnvironment,
): string => {
  return buildStripeHostOverride('files', targetEnvironment);
};

export const getStripeSiteHost = (
  targetEnvironment: ServiceEnvironment,
): string => {
  return buildStripeHostOverride('site', targetEnvironment);
};

export const getStripeConnectHost = (
  targetEnvironment: ServiceEnvironment,
): string => {
  return buildStripeHostOverride('connect', targetEnvironment);
};

export const getStripeAdminHost = (
  targetEnvironment: ServiceEnvironment,
): string => {
  return buildStripeHostOverride('admin', targetEnvironment);
};

export const getStripeDashboardHost = (
  targetEnvironment: ServiceEnvironment,
): string => {
  return buildStripeHostOverride('dashboard', targetEnvironment);
};

export const getStripeJsHost = (
  targetEnvironment: ServiceEnvironment,
): string => {
  return buildStripeHostOverride('stripejs', targetEnvironment);
};

export const getStripeAccountsHost = (
  targetEnvironment: ServiceEnvironment,
): string => {
  return buildStripeHostOverride('accounts', targetEnvironment);
};
