import type * as React from 'react';
import type {View} from './types';

export function isView<
  Props,
  T extends React.JSXElementConstructor<View.ViewProps<Props>> = (
    props: View.ViewProps<Props>,
  ) => JSX.Element | null,
>(value: unknown): value is View.ViewComponent<T> {
  type VC = View.ViewComponent<T>;
  return !!(
    value &&
    typeof value === 'object' &&
    (value as VC).useView &&
    (value as VC).config
  );
}

export function getElementRef(
  element: React.ReactElement,
): void | React.Ref<unknown> {
  return (element as unknown as {ref: React.Ref<unknown>}).ref;
}

export function getViewComponent<
  Props,
  T extends React.JSXElementConstructor<View.ViewProps<Props>>,
>(value: string | T): View.ViewComponent<T> | void {
  if (isView(value)) {
    return value as unknown as View.ViewComponent<T> | void;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let activeViewRevision: View.ViewRevision<any> | void;
export function getActiveViewRevision<
  Props,
>(): View.ViewRevision<Props> | void {
  return activeViewRevision;
}
export function expectActiveViewRevision<Props>(): View.ViewRevision<Props> {
  if (!activeViewRevision) {
    throw new Error('Expected an active view revision.');
  }
  return activeViewRevision;
}

export function setActiveViewRevision<Props>(
  revision: View.ViewRevision<Props> | void,
): void {
  activeViewRevision = revision;
}
