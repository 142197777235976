import type {Context} from 'react';
import {createMapIntentType} from './createMapIntentType';
import type {Intent} from './types';

export const provider = createMapIntentType(
  'provider',
  (decorate) =>
    <V>(
      context:
        | Context<V>
        | {
            Provider(props: {
              value: V;
              children?: React.ReactNode;
            }): JSX.Element;
          },
      value: V,
    ) => {
      return decorate([context, value] as [Context<V>, V] & Intent);
    },
  ([context]) => context,
);
