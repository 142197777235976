import type {ConnectElementConfig} from '../../../connect/ConnectJSInterface/ConnectElementList';

export const DebugUIPreviewConfig: ConnectElementConfig = {
  Component: () =>
    import(
      /* webpackChunkName: "debugUIPreview" */ '../../../components/Debug/UIPreview/UIPreview'
    ),
  enabledKey: 'internal',
  releaseState: 'internal',
  sentryTeam: 'accounts_ui',
  teamOwner: 'connected-account-experiences-eng',
  description:
    'A number of sample components (textboxes, buttons, badges, dropdowns, tabs, popovers, dialogs, text...) that allow for previewing the impact of theming API changes. Used in the Stripe doc site.',
};
