export function shallowEqualArray<T extends Array<unknown>>(
  a: T | void,
  b: T | void,
): boolean {
  if (Object.is(a, b)) {
    return true;
  }
  if (a == null || b == null || a.length !== b.length) {
    return false;
  }
  for (let i = 0; i < a.length; i++) {
    if (!Object.is(a[i], b[i])) {
      return false;
    }
  }
  return true;
}
