import {createStyleIntent} from './StyleIntent';
import type {Style} from './types';
import {VERSION_HASH} from '../version';

export const PROPERTY_PREFIX = `${VERSION_HASH}-`;

/**
 * `@property` cannot be detected by `@supports`, so we need to test for
 * features that match the `@property` support matrix.
 *
 * In Chromium-based browsers, we test for `content-visibility: auto;` and
 * `text-size-adjust: auto;`.
 * - `content-visibility` was added in the same Chrome version as `@property`
 *   (v85) but is also supported in Firefox v124 (which does not support
 *   `@property`).
 * - `text-size-adjust` is available in Chrome v85 but not Firefox.
 *
 * In Safari, we test for `margin-trim: none;`.
 * - `margin-trim` was added in the same Safari version as `@property` (v16.4).
 * - `margin-trim` is only supported in Safari.
 *
 * References:
 * - [`@supports` test case](https://jsfiddle.net/aewu624c/)
 * - [`@property` browser support](https://caniuse.com/mdn-css_at-rules_property)
 * - [Chrome 85 release notes](https://developer.chrome.com/blog/new-in-chrome-85/)
 * - [Safari 16.4 release notes](https://developer.apple.com/documentation/safari-release-notes/safari-16_4-release-notes#New-Features)
 * - [`content-visibility` browser support](https://caniuse.com/css-content-visibility)
 * - [`margin-trim` browser support](https://caniuse.com/mdn-css_properties_margin-trim)
 * - [`text-size-adjust` browser support](https://caniuse.com/mdn-css_properties_text-size-adjust_percentages)
 *
 * To update the feature detection query, compare features across browser
 * versions when `@property` support was added:
 * - https://caniuse.com/?compare=chrome+84,chrome+85,safari+16.3,safari+16.4,firefox+124,firefox+125,firefox+126&compareCats=CSS
 */
const AT_PROPERTY_SUPPORTS_QUERY =
  '(((content-visibility: auto) and (text-size-adjust: auto)) or (margin-trim: none))';

function serializePropertyName(
  name: `--${string}`,
  _options?: Style.CustomPropertyOptions,
): string {
  return `--${PROPERTY_PREFIX}${name.slice(2)}`;
}

/**
 * Creates a custom property.
 */
export const createCustomPropertyIntent = createStyleIntent(
  'global',
  serializePropertyName,
  (name: `--${string}`, options: Style.CustomPropertyOptions = {}) => {
    const {inherits = false, initialValue = 'initial', syntax = '*'} = options;
    const propertyName = serializePropertyName(name);

    if (process.env.NODE_ENV !== 'production') {
      if (initialValue.includes('var(')) {
        // https://www.w3.org/TR/css-properties-values-api-1/#initial-value-descriptor
        throw new Error(
          'Invalid @property rule: The initial value provided is not computationally independent.',
        );
      }
    }

    const lazy = () => {
      let def = `@supports ${AT_PROPERTY_SUPPORTS_QUERY} {`;
      def += `@property ${propertyName} {`;
      def += `inherits: ${inherits ? 'true' : 'false'};`;
      def += `syntax: "${syntax}";`;
      // Initial value must always be set if the syntax is not the universal
      // syntax descriptor `*`:
      // https://www.w3.org/TR/css-properties-values-api-1/#initial-value-descriptor
      if (syntax !== '*' || initialValue !== 'initial') {
        def += `initial-value: ${initialValue};`;
      }
      def += '}'; // close @property
      def += '}'; // close @supports

      const rulesets = [def];

      if (!inherits) {
        let browserSupportFallback = `@supports not ${AT_PROPERTY_SUPPORTS_QUERY} {`;
        browserSupportFallback += `*, ::before, ::after {${propertyName}: ${initialValue};}`;
        browserSupportFallback += '}'; // close @supports
        rulesets.push(browserSupportFallback);
      }
      return rulesets;
    };
    lazy.toString = () => propertyName;
    return lazy;
  },
);

export function createCustomProperty(
  name: `--${string}`,
  options: Style.CustomPropertyOptions = {},
): Style.CustomProperty {
  return {
    intent: createCustomPropertyIntent(name, options),
  };
}
