export type MediaQueryListener = (e: MediaQueryListEvent) => void;

export class MediaQueryObserver {
  query: string;

  matches: boolean;

  private subscribers: Set<MediaQueryListener>;

  private mediaQueryList: {matches: boolean} & Partial<
    Pick<
      MediaQueryList,
      | 'addEventListener'
      | 'removeEventListener'
      | 'addListener'
      | 'removeListener'
    >
  >;

  private supportsAddEventListener: boolean;

  private supportsAddListener: boolean;

  private isListening: boolean;

  constructor(query: string) {
    this.query = query;
    this.subscribers = new Set();
    this.isListening = false;

    const supportsMatchMedia =
      typeof window !== 'undefined' && typeof window?.matchMedia === 'function';

    this.mediaQueryList = supportsMatchMedia
      ? window.matchMedia(this.query)
      : {matches: false};
    this.matches = this.mediaQueryList.matches;

    this.supportsAddEventListener =
      typeof this.mediaQueryList.addEventListener === 'function';
    this.supportsAddListener =
      typeof this.mediaQueryList.addListener === 'function';
  }

  private handleChange = (e: MediaQueryListEvent) => {
    this.matches = e.matches;
    this.subscribers.forEach((listener) => listener(e));
  };

  private listen(): void {
    this.isListening = true;

    if (this.supportsAddEventListener) {
      this.mediaQueryList.addEventListener?.('change', this.handleChange);
    } else if (this.supportsAddListener) {
      this.mediaQueryList.addListener?.(this.handleChange);
    }
  }

  private unlisten(): void {
    this.isListening = false;

    if (this.supportsAddEventListener) {
      this.mediaQueryList.removeEventListener?.('change', this.handleChange);
    } else if (this.supportsAddListener) {
      this.mediaQueryList.removeListener?.(this.handleChange);
    }
  }

  subscribe(listener: MediaQueryListener): void {
    if (!this.isListening) {
      this.listen();
    }

    this.subscribers.add(listener);
  }

  unsubscribe(listener: MediaQueryListener): boolean {
    this.subscribers.delete(listener);

    if (this.subscribers.size === 0) {
      this.unlisten();
      return true;
    }

    return false;
  }
}
