import * as analytics from 'src/internal/analytics/analytics.client';
import {MissingSentryProjectObservabilityError} from 'src/internal/common/errors';
import logErrorInDev from 'src/internal/common/logErrorInDev';
import observabilityConfig from 'src/internal/config';
import buildErrorScope from 'src/internal/errors/utils/buildErrorScope';
import generateId from 'src/internal/errors/utils/generateId';
import withSdkSignature from 'src/internal/errors/utils/withSdkSignature';
import * as metrics from 'src/internal/metrics/metrics.client';
import {getDefaultRpcInstance} from 'src/internal/rpc/defaultRpc';

import type {Severity} from 'src/internal/errors/sentry/types';
import type {ErrorScope, IReports} from 'src/internal/errors/types';
import type {ErrorEventReport, ObservabilityConfig} from 'src/types';

const getInitConfig = (): ObservabilityConfig | undefined => {
  // If the config is not initialized, we don't want to log an error.
  return observabilityConfig.isInitialized()
    ? observabilityConfig.getAll()
    : undefined;
};

export class Reports implements IReports {
  #id;
  #config: ObservabilityConfig;

  constructor(config: ObservabilityConfig) {
    this.#config = {...getInitConfig(), ...config};
    this.#id = generateId(this.#config);

    if (
      config.errors?._internal_injected_methods_only ||
      config.errors?.disabled
    ) {
      return;
    }

    if (!this.#config.errors?.projects?.[this.#config.project]) {
      logErrorInDev(
        new MissingSentryProjectObservabilityError(this.#config.project),
      );
    }

    const rpcClient = config.rpc ?? getDefaultRpcInstance().client;

    rpcClient.call('initReports', this.#id, config);
  }

  public error = (error: any, scope?: ErrorScope): void => {
    this.report('error', error, scope);
  };

  public warning = (error: any, scope?: ErrorScope): void => {
    this.report('warning', error, scope);
  };

  private report(
    level: Exclude<Severity, 'info'>,
    error: any,
    scope?: ErrorScope,
  ): void {
    logErrorInDev(error, level, scope);

    if (this.#config.errors?.disabled) {
      return;
    }

    if (scope?.project && !this.#config.errors?.projects?.[scope.project]) {
      logErrorInDev(new MissingSentryProjectObservabilityError(scope.project));
    }

    const injectedMethod =
      this.#config._internal_observability?.errors?.[level];

    if (injectedMethod) {
      const errorScope = buildErrorScope(this.#config, error, scope);

      injectedMethod(error, withSdkSignature(errorScope));

      if (this.#config._tmp_dualReporting) {
        // If dual reporting is enabled, just send an analytics and metrics
        // event instead of reporting the error to sentry twice. Emitting
        // error telemetry while dual reporting allows teams to gradually
        // migrate their detectors/dashboards to @sail/observability before
        // removing the polyfills.
        this.sendErrorTelemetry(level, errorScope.tags);
      }

      return;
    }

    const rpcClient = this.#config.rpc ?? getDefaultRpcInstance().client;

    rpcClient.call(
      'report',
      level,
      this.#id,
      error,
      ...[scope].filter(Boolean),
    );
  }

  private sendErrorTelemetry(
    level: Exclude<Severity, 'info'>,
    tags: ErrorEventReport,
  ) {
    analytics.track(this.#config, `frontend.observability.${level}`, tags);

    metrics.increment(this.#config, `frontend.observability.${level}`, {
      page: tags.page,
      project: tags.project,
    });
  }
}
