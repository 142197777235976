import type * as React from 'react';
import {createView} from './createView';
import type {View} from './types';

type ElementProps<Instance = 'div'> =
  Instance extends keyof View.IntrinsicElements
    ? View.IntrinsicElements[Instance]
    : Instance extends React.ComponentType<infer Props>
    ? Props
    : never;

export type IntrinsicViews = {
  [K in keyof View.IntrinsicElements]: (
    props: View.ViewProps<ElementProps<K>>,
  ) => JSX.Element | null;
};

function createIntrinsicViews(): IntrinsicViews {
  return new Proxy({} as IntrinsicViews, {
    get(target: IntrinsicViews, tag: 'div'): IntrinsicViews['div'] {
      const cached = target[tag];
      if (cached) {
        return cached;
      }
      const component = createView(tag) as unknown as IntrinsicViews['div'];
      target[tag] = component;
      return component;
    },
  });
}

/**
 * Create intrinsic HTML elements supercharged with support for the css prop and engine intents.
 *
 * @see https://sail.stripe.me/apis/view-tag
 */
export const view = createIntrinsicViews();
